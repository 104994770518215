<button type="button" class="d-md-none btn-close btn-close-dark" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
<button type="button" class="d-none d-md-flex btn-close btn-close-light" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>

<div class="grid modal-body p-0 text-dark" tabindex="0" ngbAutoFocus>
    <div class="row g-0">
        <div class="login col-60 col-md-40 d-flex flex-column bg-white _py-4 _px-2">
            <h3 class="_fs-20 mb-5 title-line-bottom-red">
                <strong class="fw-bolder">
                    <span *ngIf="loginType === 'member'">Ingreso para socios de Teatrix</span>
                    <span *ngIf="loginType === 'provider'">Ingreso con proveedor</span>
                </strong>
            </h3>

            <ng-container *ngIf="storeS.authUser.value?.isLoggedIn(); else elseIsNotLoggedIn">
                <div class="_fs-16">
                    <p class="">¡Bienvenido <strong>{{ storeS.authUser.value.user.fullname }}</strong>!</p>
                    <a class="link-app-red _fs-14" (click)="logout()">Cerrar sesión</a>
                </div>
            </ng-container>

            <ng-template #elseIsNotLoggedIn>
                <ng-container *ngIf="loginType === 'member'; else loginWithProviderTpl">
                    <app-form [form]="form">
                        <div class="pb-4">
                            <div class="row">
                                <app-form-input class="col-60 mb-3" type="email" label="Email" controlName="email"></app-form-input>
                                <app-form-input class="col-60 mb-3" type="password" label="Contraseña" controlName="password"></app-form-input>
                                <app-form-checkbox class="col-60 mb-3" label="Recordarme en este dispositivo" controlName="remember_me"></app-form-checkbox>
                            </div>
                        </div>

                        <div class="d-md-flex align-items-center mt-auto mb-3 mb-md-3">
                            <app-button-form [request]="form.request" (click)="login()">
                                Ingresar
                            </app-button-form>

                            <a class="d-block d-md-inline link-gray-600 lh-md mt-3 mt-md-0 ms-md-3 _fs-14" (click)="authResetPasswordModalS.open()" (keyup.enter)="authResetPasswordModalS.open()" tabindex="0">¿Olvidaste tu contraseña?</a>
                        </div>

                        <div class="px-3 my-4"><hr class="dropdown-divider"></div>

                        <div class="mt-3">
                            <p class="text-gray-600 _fs-14">¿Tenés Teatrix a través de un proveedor de TV, telefonía movil o internet?</p>

                            <a class="btn-provider d-block btn btn-app-red rounded-2 _fs-16 px-4 px-sm-5" [routerLink]="navS.pages.LoginProviderPage.link()">
                                <strong>Ingresar con proveedor</strong>
                            </a>
                        </div>
                    </app-form>
                </ng-container>

                <ng-template #loginWithProviderTpl>
                    <app-form [form]="form">
                        <div class="pb-4">
                            <div class="row">
                                <app-form-input class="col-60 mb-3" type="text" label="Teléfono (incluir código de área)" controlName="phone_1"></app-form-input>
                                <app-form-input class="col-60 mb-3" type="password" label="Contraseña" controlName="password"></app-form-input>
                                <app-form-checkbox class="col-60 mb-3" label="Recordarme en este dispositivo" controlName="remember_me"></app-form-checkbox>
                            </div>
                        </div>

                        <div class="d-md-flex align-items-center mt-auto">
                            <app-button-form [request]="form.request" (click)="login()">
                                Ingresar
                            </app-button-form>

                            <a class="d-block d-md-inline link-gray-600 lh-md mt-3 mt-md-0 ms-md-3 _fs-14" (click)="changeLoginType()" (keyup.enter)="changeLoginType()" tabindex="0">Ingresar como socio</a>
                        </div>
                    </app-form>
                </ng-template>
            </ng-template>
        </div>

        <div class="subscribe col-60 col-md-20 d-flex flex-column bg-app-red text-white _py-4 _px-2 mt-md-0 mt-auto">
            <div class="pb-md-4">
                <h3 class="_fs-20 mb-md-5 title-line-bottom-dark"><strong class="fw-bolder">¿Aún no eres socio?</strong></h3>

                <div class="_fs-14">
                    <p class="d-none d-md-block">¿Qué esperás para disfrutar del mejor teatro sin importar donde estés?</p>
                    <p>Creá tu cuenta ahora por solo <strong>{{ info.subscriptionPrice }}</strong> por mes.</p>
                </div>
            </div>

            <div class="mt-auto">
                <button class="d-block w-100 btn btn-dark rounded-2 _fs-16" [routerLink]="navS.pages.SubscribePage.link()">
                    <strong>Suscribirme</strong>
                </button>
            </div>
        </div>
    </div>
</div>
