import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputModule } from './form-input/form-input.module';
import { FormSelectModule } from './form-select/form-select.module';
import { FormCheckboxModule } from './form-checkbox/form-checkbox.module';
import { FormDropzoneModule } from './form-dropzone/form-dropzone.module';
import { FormArrayModule } from './form-array/form-array.module';
import { FormDefaultModule } from './form-default/form-default.module';
import { FormTextareaModule } from './form-textarea/form-textarea.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    FormInputModule,
    FormSelectModule,
    FormCheckboxModule,
    FormDropzoneModule,
    FormArrayModule,
    FormDefaultModule,
    FormTextareaModule
  ]
})
export class FormFieldsModule { }
