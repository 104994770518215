import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appNumber'
})
export class NumberPipe implements PipeTransform {

  transform(value: number|string): any {
    if (typeof value === 'string') {
      value = parseInt(value);
    }

    if (!value) {
      return '0';
    }

    value = value.toLocaleString('es-AR');

    if (value.length === 4) {
      value = value.slice(0, 1) + '.' + value.slice(1);
    }

    return value;
  }

}
