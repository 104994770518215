<div class="position-relative">
    <div class="p-4 shadow-sm border rounded" [ngClass]="getFormFieldClass('', false)">
        <label class="position-absolute" style="top: 0.5rem; left: 0.5rem;">
            <app-form-error [formFieldC]="this" [startsWith]="false"></app-form-error>
        </label>

        <div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
