import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSelectComponent } from './form-select.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormElementsModule } from '../../form-elements/form-elements.module';
import { FormInputModule } from '../form-input/form-input.module';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [FormSelectComponent],
  imports: [
    CommonModule,
    FormElementsModule,
    FormInputModule,
    PipesModule,
    NgbDropdownModule
  ],
  exports: [FormSelectComponent]
})
export class FormSelectModule { }
