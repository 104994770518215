import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDropzoneComponent } from './form-dropzone.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormDropzoneValueComponent } from './form-dropzone-value/form-dropzone-value.component';
import { FormDropzoneUploadComponent } from './form-dropzone-upload/form-dropzone-upload.component';
import { FormElementsModule } from '../../form-elements/form-elements.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [FormDropzoneComponent, FormDropzoneValueComponent, FormDropzoneUploadComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormElementsModule,
    NgbDropdownModule,
  ],
  exports: [FormDropzoneComponent]
})
export class FormDropzoneModule { }
