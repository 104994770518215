<nav class="navbar navbar-expand navbar-dark fixed-top"
    [class.hide]="navbarS.hide"
    [class.has-bg]="navbarS.hasBg"
>
    <div class="container-fluid _fs-14">
        <a class="navbar-brand ms-3 ms-sm-0" [routerLink]="['/']" tabindex="0">
            <img src="assets/img/teatrix-logo.webp" alt="Teatrix" >
        </a>

        <ng-container *ngIf="!onlyLogo">
            <div class="collapse navbar-collapse order-first order-sm-0">
                <ul class="navbar-nav pt-1">
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:;" (click)="sidebar.open()" (keyup.enter)="sidebar.open()" tabindex="0">
                            <app-icon class="d-sm-none" name="menu-toggle-white" [size]="1.25"></app-icon>
                            <app-icon class="d-none d-sm-inline-flex" onleft-lg name="menu-toggle-white" [size]="1.25">Menú</app-icon>
                        </a>
                    </li>
                </ul>
            </div>

            <ul class="navbar-nav align-items-center justify-content-end w-100 pt-1">
                <li class="d-none d-lg-block nav-item w-100">
                    <span class="nav-link">
                        <app-play-searchbar class="ms-auto"></app-play-searchbar>
                    </span>
                </li>

                <!-- <li *ngIf="storeS.authUser.value?.user.hasSubscription()" class="nav-item" #dropdownNotifications="ngbDropdown" ngbDropdown display="dynamic" placement="bottom-right" (mouseleave)="dropdownNotifications.close()">
                    <a class="nav-link" ngbDropdownAnchor (mouseenter)="dropdownNotifications.open()">
                        <app-icon name="notification-white" [size]="1.25"></app-icon>
                    </a>

                    <span class="dropdown-arrow"></span>

                    <div ngbDropdownMenu class="notifications" data-simplebar>
                        <div class="p-4">
                            <app-title-strip class="_mb-2">Notificaciones</app-title-strip>

                            <div class="row g-4">
                                <ng-container *ngFor="let play of playsNotifications">
                                    <app-play-box type="detail" [play]="play" (click)="dropdownNotifications.close()"></app-play-box>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </li> -->

                <ng-container *ngIf="storeS.authUser.value?.isLoggedIn()">
                    <li class="nav-item" #dropdownMyAccount="ngbDropdown" ngbDropdown display="dynamic" placement="bottom-right" (mouseleave)="dropdownMyAccount.close()">
                        <a class="nav-link nowrap" ngbDropdownAnchor (mouseenter)="dropdownMyAccount.open()" (keyup.enter)="dropdownMyAccount.open()" tabindex="0">
                            <app-icon class="d-sm-none" name="user-avatar-white" [size]="1.25"></app-icon>
                            <app-icon class="d-none d-sm-inline-flex" onleft-lg name="user-avatar-white" [size]="1.25">{{ storeS.authUser.value?.user.fullname }}</app-icon>
                        </a>

                        <span class="dropdown-arrow"></span>

                        <div ngbDropdownMenu class="my-account _fs-14">
                            <a ngbDropdownItem [routerLink]="navS.pages.MyAccountPage.link()">Mi perfil y suscripción</a>
                            <a *ngIf="storeS.authUser.value?.user.permissions.play.list" ngbDropdownItem [routerLink]="navS.pages.MyListPage.link()">Mi lista de obras</a>

                            <div class="px-3"><hr class="dropdown-divider"></div>

                            <a ngbDropdownItem (click)="logout()" (keyup.enter)="logout()" tabindex="0">
                                <app-icon onleft-lg name="logout-red">Cerrar sesión</app-icon>
                            </a>
                        </div>
                    </li>
                </ng-container>

                <ng-container *ngIf="!storeS.authUser.value?.isLoggedIn()">
                    <li class="d-sm-none nav-item">
                        <a class="nav-link nowrap" href="javascript:;" (click)="authLoginModalS.open()" (keyup.enter)="authLoginModalS.open()" tabindex="0">
                            Ingresa <app-icon onright-lg name="user-avatar-white" [size]="1.25"></app-icon>
                        </a>
                    </li>

                    <li class="d-none d-sm-block nav-item">
                        <a class="nav-link nowrap" href="javascript:;" (click)="authLoginModalS.open()" (keyup.enter)="authLoginModalS.open()" tabindex="0">
                            <app-icon onleft-lg name="user-avatar-white" [size]="1.25">Inicia sesión</app-icon>
                        </a>
                    </li>
                    <li class="d-none d-sm-block nav-item nav-item-sep"></li>
                    <li class="d-none d-sm-block nav-item">
                        <a class="nav-link" [routerLink]="navS.pages.SubscribePage.link()" (keyup.enter)="navS.pages.SubscribePage.nav()" tabindex="0">
                            Regístrate
                        </a>
                    </li>
                </ng-container>
            </ul>
        </ng-container>

        <ng-container *ngIf="onlyLogo && withRegister">
            <ng-container *ngIf="!storeS.authUser.value?.isLoggedIn()">
                <a class="nav-link" [routerLink]="navS.pages.SubscribePage.link()" (keyup.enter)="navS.pages.SubscribePage.nav()" tabindex="0">
                    Regístrate
                </a>
            </ng-container>
        </ng-container>
    </div>
</nav>

<app-sidebar #sidebar [hidden]="onlyLogo"></app-sidebar>
