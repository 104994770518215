/**
 * Clase para almacenar y manipular subscripciones activas.
 *
 * @version 0.1.1
 */

import { Subscription } from 'rxjs';

export class SubscriptionManager {

  private _sa: Subscription[] = [];
  private _so: any = {};

  constructor() { }

  /* -------------------- */

  add(s: Subscription, name?: string | number) {
    if (name) {
      this.clean(name);
      this._so[name] = s;
    } else {
      this._sa.push(s);
    }
  }

  get(name: string | number) {
    if (typeof name === 'string') {
      return this._so[name];
    }

    return this._sa[name];
  }

  clean(name?: string | number) {
    if (name) {
      if (this._so[name] instanceof Subscription) {
        this._so[name].unsubscribe();
      }
    } else {
      this._sa.forEach(value => value.unsubscribe());

      for (const key of Object.keys(this._so)) {
        this._so[key].unsubscribe();
      }
    }
  }
}
