import { Component, OnInit } from '@angular/core';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  now: Date = new Date;

  constructor(
    public navS: NavService,
    public storeS: StoreService,
  ) { }

  ngOnInit(): void {
  }
}
