import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appHtmlStrip'
})

export class HtmlStripPipe implements PipeTransform {

  transform(html: string, keepParagraphs: boolean = false): any {
    html = html.replace(/&.*?;/g, '')
      .replace(/\r?\n|\r/g, ' ')
      .replace(/ +(?= )/g, '')
      ;

    html = keepParagraphs
      ? html.replace(/<\/?[^p\/p].*?>/g, '').replace(/<.*?> +<\/.*?>/g, '').replace(/<p><\/p>/g, '').replace(/<p> /g, '<p>').replace(/ <\/p>/g, '</p>')
      : html.replace(/<.*?>/g, '');

    return html;
  }
}
