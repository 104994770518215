import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import { StoreService } from '../store.service';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthUserPlayHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
    private _storeS: StoreService
  ) { }

  /* -------------------- */

  getSmall(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/users/playsInList?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getMedium(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/users/playsInList/medium?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getFavorites(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/users/playsInFavoriteList?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getWatching(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/plays/continueViewing?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  /* -------------------- */

  addToMyList(playId: number) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/add-to-list`), { play_id: playId }, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) })
    }), tokenType).pipe(
      map((res: any) => {
        this._storeS.emit('authUser', 'addToMyList');

        return res;
      })
    );
  }

  removeFromMyList(playId: number) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/delete-from-list`), { play_id: playId }, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) })
    }), tokenType).pipe(
      map((res: any) => {
        this._storeS.emit('authUser', 'removeFromMyList');

        return res;
      })
    );
  }

  /* -------------------- */

  addToMyFavoriteList(playId: number) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/add-to-favorite`), { play_id: playId }, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) })
    }), tokenType);
  }

  removeFromMyFavoriteList(playId: number) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/delete-from-favorite`), { play_id: playId }, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) })
    }), tokenType);
  }

  /* -------------------- */

  trackTime(playId: number, videoId: number, watchedSeconds: number) {
    const tokenType: TokenType = 'authUserToken';

    const input = {
      userID: this._storeS.authUser.value?.user.id,
      playID: playId,
      videoID: videoId,
      seconds: watchedSeconds,
    };

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/plays/set-time`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) })
    }), tokenType);
  }
}
