import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from './nav.service';
import { RouteService } from './route.service';
import { SsrService } from './ssr.service';
import { StoreService } from './store.service';

export type ModalInstanceType =
  | 'AuthLoginModal'
  | 'AuthResetPasswordModal'
  | 'AuthSubscribeModal'
  | 'PartnerModal'
  | 'PlayDetailModal'
  | 'ChangeVersionModal'
  | 'FreeTrialModal'
  | 'EmailVerificationModal'
  | 'NewsletterModal'
  | 'LiveModal'
  ;

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  openedOn: string;
  modalInstance: ModalInstanceType;

  constructor(
    public ssrS: SsrService,
    public storeS: StoreService,
    public ngbModal: NgbModal,
    protected _injector: Injector,
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _routeS: RouteService,
  ) { }

  get _navS(): NavService {
    return this._injector.get(NavService);
  }

  /* -------------------- */

  hasOpenModals(): boolean {
    return this.ngbModal.hasOpenModals();
  }

  dismissAll(): void {
    this.ngbModal.dismissAll();
  }

  dismiss(...modalInstances: ModalInstanceType[]): void {
    if (modalInstances.includes(this.modalInstance)) {
      this.ngbModal.dismissAll();
    }
  }

  /* -------------------- */

  open(content: any, options?: NgbModalOptions): void {
    this.beforeOpen();
    this.ngbModal.open(content, options);
  }

  beforeOpen(modalInstance?: ModalInstanceType): void {
    this.modalInstance = modalInstance;

    const snapshot = this._routeS.getSnapshotLastChild();
    this.openedOn = snapshot.data.name;

    this.dismissAll();
  }

  nav(path?: string) {
    // return this._router.navigate(path ? [path] : [], {
    //   queryParams: { [this.queryParamIndex]: 1 },
    //   queryParamsHandling: 'merge'
    // });
  }

  /* -------------------- */

  navOnDismiss(modalRef: NgbModalRef, on?: string): void {
    modalRef.dismissed.toPromise().then(() => {
      if (on) {
        this.openedOn = on;
      }

      const snapshot = this._routeS.getSnapshotLastChild();

      if (snapshot.data.name === this.openedOn && this._navS.pages.hasOwnProperty(this.openedOn)) {
        this._navS.pages[this.openedOn].nav();
      }
    });
  }
}
