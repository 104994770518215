import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDisabledModule } from './form-disabled/form-disabled.module';
import { FormErrorModule } from './form-error/form-error.module';
import { FormOptionsLoadingModule } from './form-options-loading/form-options-loading.module';
import { FormArrayButtonsModule } from './form-array-buttons/form-array-buttons.module';
import { FormArraySortModule } from './form-array-sort/form-array-sort.module';
import { FormStatusModule } from './form-status/form-status.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    FormDisabledModule,
    FormErrorModule,
    FormOptionsLoadingModule,
    FormArrayButtonsModule,
    FormArraySortModule,
    FormStatusModule,
  ]
})
export class FormElementsModule { }
