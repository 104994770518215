import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { LoDashStatic } from 'lodash';
import { finalize } from 'rxjs/operators';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { Form } from 'src/app/packages/forms/tools/form';
import { AuthUserHttpService } from 'src/app/services/http/auth-user-http.service';
import { AuthUserSubscriptionHttpService } from 'src/app/services/http/auth-user-subscription-http.service';
import { ApiErrorResponse } from 'src/app/services/http/core/http.service';
import { ToastService } from 'src/app/services/toast.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { environment } from 'src/environments/environment';
import { StripeService } from 'src/app/services/stripe.service';
import { AuthSubscribeModalContentComponent } from '../auth-subscribe-modal-content/auth-subscribe-modal-content.component';
@Component({
  selector: 'app-auth-subscribe-modal-checkout-form',
  templateUrl: './auth-subscribe-modal-checkout-form.component.html',
  styleUrls: ['./auth-subscribe-modal-checkout-form.component.scss']
})
export class AuthSubscribeModalCheckoutFormComponent implements OnInit {

  stepToGoBack: number;

  stripeError: string = null;

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    public parent: AuthSubscribeModalContentComponent,
    private _authUserHttpS: AuthUserHttpService,
    private _authUserSubscriptionHttpS: AuthUserSubscriptionHttpService,
    private _toastS: ToastService,
    private _analytic: AnalyticsService,
    private _stripeS: StripeService,
  ) { }

  ngOnInit(): void {
    if (!this.parent.skipStep2) {
      this.stepToGoBack = 2;
    } else if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
      this.stepToGoBack = 1
    }
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  get formRegister(): Form {
    return this.parent.formRegister;
  }

  get formSubscribe(): Form {
    return this.parent.formSubscribe;
  }

  get formCheckout(): Form {
    return this.parent.formCheckout;
  }

  get plan(): any {
    return this.formSubscribe.state.current.plan;
  }

  get _(): LoDashStatic {
    return _;
  }

  /* -------------------- */

 async checkout(): Promise<void> {
  if (this.formCheckout.group.invalid) return;
  if (this.formCheckout.request.isLoading()) return;

  this.formCheckout.resetMarks();

  let method: string;
  let input: any;


  if (!this.parent.isPpv) {
    input = {
      planCountryId: this.formSubscribe.state.current.plan?.plans_countries_id || null,
      currencyIso: this.formSubscribe.state.current.plan?.currency_iso || null,
      campaignCode: this.formSubscribe.state.current.benefit_Code.code,
      clarin: this.formSubscribe.state.current.clarin,
      nacion: this.formSubscribe.state.current.nacion,
      elpais: this.formSubscribe.state.current.pais,
      elcomercio: this.formSubscribe.state.current.comercio,
      /* -------------------- */
      value: this.parent.getPlanAmountWithDiscount(this.formSubscribe.state.current.plan),
    };

    if (this.formSubscribe.state.current.clarin) {
      input.card_membership = this.formSubscribe.state.current.benefit_365.card_number;
    } else if (this.formSubscribe.state.current.nacion) {
      input.card_membership = this.formSubscribe.state.current.benefit_LaNacion.card_number;
    } else if (this.formSubscribe.state.current.pais) {
      input.card_membership = this.formSubscribe.state.current.benefit_ElPais.card_number;
    } else if (this.formSubscribe.state.current.comercio) {
      input.card_membership = this.formSubscribe.state.current.benefit_ElComercio.card_number;
    }

    if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
      input.name = this.formRegister.state.current.name;
      input.email = this.formRegister.state.current.email;
      input.clientPassword = this.formRegister.state.current.password;
    } else {
      input.name = this.parent.storeS.authUser.value?.user.fullname;
      input.email = this.parent.storeS.authUser.value?.user.email;
      input.clientPassword = 123456;
    }

    if (this.parent.isPaymentMethodOnline) {
      method = this.parent.useMocks ? 'mockCheckoutOnline' : 'checkout';

      input.creditCardNumber = this.formCheckout.group.get('card_number')['valueMasked'];
      input.creditCardCVC = this.formCheckout.group.get('card_security_code').value;
      input.creditCardPersonName = this.formCheckout.group.get('card_titular_fullname').value;
      input.creditCardMonthExpiry = this.formCheckout.group.get('card_expiration_month').value?.value || null;
      input.creditCardYearExpiry = this.formCheckout.group.get('card_expiration_year').value?.value || null;
      // input.creditCardMonthExpiry = this.formCheckout.group.get('card_expiration')['valueMasked']?.split('/')[0] || null;
      // input.creditCardYearExpiry = this.formCheckout.group.get('card_expiration')['valueMasked']?.split('/')[1] || null;

      /*
      * Only For international transaction
      */
        switch (input.currencyIso) {
          case 'USD':
          case 'MXN':
          case 'EUR':
            await this.resolveStripe(input);
          break;
        }

      input.dni = this.formCheckout.group.get('card_titular_dni').value;
      input.paymentMethodName = this.formCheckout.group.get('card_type').value?.value || null;

      if (input.creditCardYearExpiry?.length === 2) {
        input.creditCardYearExpiry = `20${input.creditCardYearExpiry}`;
      }
    }
    else if (this.parent.isPaymentMethodOffline) {
      method = this.parent.useMocks ? 'mockCheckoutOffline' : 'checkout';

      input.paymentMethodName = this.formCheckout.group.get('payment_method_offline').value?.value || null;
    }
  }
  else {
    method = this.parent.useMocks ? 'mockCheckoutForPpvOnline' : 'checkoutForPpv';

    input = {
      campaignCode: this.formSubscribe.state.current.benefit_Code.code,
      /* -------------------- */
      value: this.parent.getPlanAmountWithDiscount(this.formSubscribe.state.current.plan),
      playID: this.parent.play.id,
    };

    if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
      input.name = this.formRegister.state.current.name;
      input.email = this.formRegister.state.current.email;
      input.password = this.formRegister.state.current.password;
    } else {
      input.name = this.parent.storeS.authUser.value?.user.fullname;
      input.email = this.parent.storeS.authUser.value?.user.email;
      input.clientPassword = 123456;
    }

    if (this.parent.isPaymentMethodOnline) {
      input.creditCardNumber = this.formCheckout.group.get('card_number')['valueMasked'];
      input.creditCardCVC = this.formCheckout.group.get('card_security_code').value;
      input.creditCardPersonName = this.formCheckout.group.get('card_titular_fullname').value;
      input.creditCardMonthExpiry = this.formCheckout.group.get('card_expiration_month').value?.value || null;
      input.creditCardYearExpiry = this.formCheckout.group.get('card_expiration_year').value?.value || null;
      // input.creditCardMonthExpiry = this.formCheckout.group.get('card_expiration')['valueMasked']?.split('/')[0] || null;
      // input.creditCardYearExpiry = this.formCheckout.group.get('card_expiration')['valueMasked']?.split('/')[1] || null;
      input.dni = this.formCheckout.group.get('card_titular_dni').value;
      input.methodPayment = this.formCheckout.group.get('card_type').value?.value || null;

      /*
      * Only For international transaction
      */
      switch (input.currencyIso) {
        case 'USD':
        case 'MXN':
        case 'EUR':
          await this.resolveStripe(input);
        break;
      }

      if (input.creditCardYearExpiry?.length === 2) {
        input.creditCardYearExpiry = `20${input.creditCardYearExpiry}`;
      }
    }
    else if (this.parent.isPaymentMethodOffline) {
      input.methodPayment = this.formCheckout.group.get('payment_method_offline').value?.value || null;
    }
  }

  this._sm.add(
    this.formCheckout.request.send<any>(this._authUserSubscriptionHttpS[method](input)).subscribe(res => {
      const err: ApiErrorResponse = this.formCheckout.request.error;

      if (err) {
        if (err.status !== 422) {
          this._toastS.showHttpError(err);
        }
      } else {
        this.formCheckout.persist();

        if (this.parent.isPaymentMethodOffline) {
          this.parent.paymentMethodOfflineDownloadLink = res.data.linkOffline;
          window.location.href = this.parent.paymentMethodOfflineDownloadLink;

          if (this.parent.storeS.authUser.value?.isLoggedIn()) {
            this.parent.storeS.authUser.value.user.payment_coupon_url = res.data.linkOffline;
          }
        }

        if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
          const inputLogin = {
            email: this.formRegister.state.current.email,
            password: this.formRegister.state.current.password,
            remember_me: true,
          };

          this._sm.clean('checkout');

          this._analytic.Purchase(this.formSubscribe,this.parent.getPlanAmountWithDiscount(this.formSubscribe.group.value?.plan,this.parent.discount), this.parent.isPpv ? 'PPV' : 'Subscription')

          this._sm.add(
            this.formCheckout.request.send(this._authUserHttpS.login(inputLogin)).pipe(
              finalize(() => {
                this.parent.storeS.emit('authUser', this.parent.isPpv ? 'checkoutPpv' : 'checkoutSubscription');
                this.parent.step = 4;
              })
            ).subscribe()
          , 'checkout');
        } else {
          this._sm.clean('checkout');

          this._sm.add(
            this.formCheckout.request.send(this._authUserHttpS.getSubscription()).subscribe(res => {
              this.parent.storeS.authUser.value.user.set(res.data);

              this.parent.storeS.update('authUser', this.parent.storeS.authUser.value);
              this.parent.storeS.emit('authUser', this.parent.isPpv ? 'checkoutPpv' : 'checkoutSubscription');
              this.parent.step = 4;
            })
          , 'checkout');
        }
      }
    }, (err: ApiErrorResponse) => {
      if (err.status !== 422) {
        this._toastS.showHttpError(err);
      }
    })
    , 'checkout');
  }

  /* -------------------- */

  async resolveStripe(input) {
    let stripeInput = {
      number: input.creditCardNumber,
      cvc: input.creditCardCVC,
      exp_month: input.creditCardMonthExpiry,
      exp_year: input.creditCardYearExpiry,
    }

    await this._stripeS.createToken(stripeInput).then((res) => {
      this.stripeError = null;
      input.stripeToken = res;
    }).catch(() => {
      this.stripeError = 'Card declined';
    });
  }
}
