<ng-container *ngIf="request.isLoading(isUnfiredToo)">
    <div class="d-flex align-items-center justify-content-center my-3">
        <div class="spinner-border text-app-red" style="width: 2.5rem; height: 2.5rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="request.isError()">
    <div class="d-flex align-items-center justify-content-center text-marginless my-3">
        <p class="_fs-20 text-gray-600">Ha ocurrido un error.</p>
    </div>
</ng-container>

<ng-container *ngIf="request.isSuccess()">
    <ng-content></ng-content>
</ng-container>
