<a class="link-gray-100 text-decoration-none actionable" href="javascript:;" (click)="toggle()"
    [class._fs-14]="size === 'xs'"
    [class._fs-16]="size === 'sm'"
    [class._fs-18]="size === 'md'"
    [tabindex]="tabIndex"
    data-toggle="tooltip"
    data-placement="bottom"
    [title]="tooltipTitle()"
>
    <ng-container *ngIf="!play.in_my_list">
        <app-icon [attr.left-lg]="!onlyIcon || null" name="plus-white" [size]="iconSize">
            <strong *ngIf="!onlyIcon">Agregar a Mi Lista</strong>
        </app-icon>
    </ng-container>

    <ng-container *ngIf="play.in_my_list">
        <app-icon [attr.left-lg]="!onlyIcon || null" name="check-white" [size]="iconSize">
            <strong *ngIf="!onlyIcon">Quitar de Mi Lista</strong>
        </app-icon>
    </ng-container>
</a>
