import { Component, OnInit, Input } from '@angular/core';
import { Form } from '../../../tools/form';

@Component({
  selector: 'app-form-options-loading',
  templateUrl: './form-options-loading.component.html',
  styleUrls: ['./form-options-loading.component.scss']
})
export class FormOptionsLoadingComponent implements OnInit {

  @Input() form: Form;
  @Input() options: string;

  constructor() { }

  ngOnInit(): void {
  }

}
