<ng-container *ngIf="type === 'default'">
    <a class="d-xl-none text-reset text-decoration-none"
        [routerLink]="viewportS.up('sm') ? navS.pages.HomePage_PlayDetailModal.link(play) : navS.pages.PlayDetailPage.link(play)"
        (click)="viewportS.up('sm') ? navS.pages.HomePage_PlayDetailModal.before(play) : null"
    >
        <app-play-box-cover [play]="play"></app-play-box-cover>
    </a>

    <app-play-box-cover class="d-none d-xl-block" [play]="play" [hoverPreview]="playBoxPreviewTpl" [scale]="0.3" [minWidth]="380" [maxWidth]="420" [delay]="300"></app-play-box-cover>

    <ng-template #playBoxPreviewTpl>
        <a class="text-reset text-decoration-none"
            [routerLink]="navS.pages.HomePage_PlayDetailModal.link(play)"
            (click)="navS.pages.HomePage_PlayDetailModal.before(play)"
        >
            <div class="play-box-preview shadow">
                <div class="_bg-cover ratio ratio-16x9" [style.background-image]="'url(\'' + play.img_box_url + '\')'"></div>

                <div class="hover-preview-opacity">
                    <div class="separator"></div>

                    <div class="play-box-preview-content">
                        <div class="px-3 py-4">
                            <app-play-title [play]="play" [withSubtitle]="true" [withFeatures]="true"></app-play-title>

                            <div class="row g-1 align-items-center _mt-1-7">
                                <ng-container *ngIf="storeS.authUser.value?.user.permissions.play.view; else elseHasNotPermissionsToPlay">
                                    <div class="col-auto m-0">
                                        <app-button-play class="me-2">Ver trailer</app-button-play>
                                    </div>

                                    <div class="col-auto">
                                        <app-button-play-more-info [play]="play" size="sm" (click)="$event.stopPropagation()"></app-button-play-more-info>
                                    </div>

                                    <div class="col-auto ms-auto">
                                        <app-button-add-to-my-list size="sm" [play]="play" [onlyIcon]="true" (click)="$event.stopPropagation()"></app-button-add-to-my-list>
                                    </div>
                                </ng-container>

                                <ng-template #elseHasNotPermissionsToPlay>
                                    <app-button-play class="col-auto">Ver trailer</app-button-play>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </ng-template>
</ng-container>

<ng-template #ppvDate>
    <ng-container *ngIf="play.isPpv()">
        <p *ngIf="play.ppvCanPlay()" class="text-app-red"><strong>Disponible hasta el {{ play.ppv.ends_at | date:'d' }} de {{ play.ppv.ends_at | date:'MMMM' }}</strong></p>
        <p *ngIf="!play.ppvCanPlay()" class="text-app-red"><strong>Se estrena el {{ play.ppv.starts_at | date:'d' }} de {{ play.ppv.starts_at | date:'MMMM' }}</strong></p>
    </ng-container>
</ng-template>

<ng-container *ngIf="type === 'detail'">
    <a class="text-decoration-none text-reset" [routerLink]="navS.pages.PlayDetailPage.link(play)">
        <div class="row g-4">
            <div class="col-25">
                <app-play-box-cover [play]="play"></app-play-box-cover>
            </div>

            <div class="col-35 text-marginless">
                <h3 class="_fs-22 text-truncate text-white" [title]="play.title"><strong>{{ play.title }}</strong></h3>
                <ng-container [ngTemplateOutlet]="ppvDate"></ng-container>

                <div class="ff-opensans-r _fs-14 text-gray-500 pr-sm-4">
                    <div class="description" [appClamp]="3" [title]="play.summary | appHtmlStrip">
                        {{ play.summary | appHtmlStrip }}
                    </div>

                    <ng-container *ngIf="play.members_by_role_as_string">
                        <p class="text-truncate"><span class="text-white">Autor</span> {{ play.members_by_role_as_string.authorship }}</p>
                        <p *ngIf="play.members_by_role_as_string.direction" class="text-truncate"><span class="text-white">Dirección</span> {{ play.members_by_role_as_string.direction }}</p>
                    </ng-container>
                </div>
            </div>
        </div>
    </a>
</ng-container>

<ng-container *ngIf="type === 'menu-mobile'">
    <a class="text-decoration-none text-reset" [routerLink]="navS.pages.PlayDetailPage.link(play)">
        <div class="row g-4">
            <div class="col-25">
                <app-play-box-cover [play]="play"></app-play-box-cover>
            </div>

            <div class="col-35 text-marginless">
                <h3 class="_fs-22 text-white" [title]="play.title"><strong>{{ play.title }}</strong></h3>
                <ng-container [ngTemplateOutlet]="ppvDate"></ng-container>

                <div class="ff-opensans-r _fs-14 text-gray-500 pr-sm-4">
                    <ng-container *ngIf="play.members_by_role_as_string">
                        <p class="text-truncate"><span class="text-white">Autor</span> {{ play.members_by_role_as_string.authorship }}</p>
                        <p *ngIf="play.members_by_role_as_string.direction" class="text-truncate"><span class="text-white">Dirección</span> {{ play.members_by_role_as_string.direction }}</p>
                    </ng-container>
                </div>
            </div>
        </div>
    </a>
</ng-container>

<ng-container *ngIf="type === 'result'">
    <a class="text-decoration-none text-reset" [routerLink]="navS.pages.PlayDetailPage.link(play)">
        <div class="row g-4">
            <div class="col-12">
                <app-play-box-cover type="vertical" [play]="play"></app-play-box-cover>
            </div>

            <div class="col-48 text-marginless">
                <h3 class="_fs-18 mb-2 text-truncate text-white" [title]="play.title"><strong>{{ play.title }}</strong></h3>
                <ng-container [ngTemplateOutlet]="ppvDate"></ng-container>

                <div class="ff-opensans-r _fs-14 text-gray-500 _pr-2">
                    <div class="description" [appClamp]="3" [title]="play.summary | appHtmlStrip">
                        {{ play.summary | appHtmlStrip }}
                    </div>
                </div>
            </div>
        </div>
    </a>
</ng-container>
