import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PlayDetailModalService } from "../components/core/play/play-detail-modal/play-detail-modal.service";
import { News } from "../core/news/news.model";
import { PlayCollection } from "../core/play-collection/play-collection.model";
import { IPlayVideo, Play } from "../core/play/play.model";
import { RouteService } from "./route.service";

interface NavPage {
  link: (...params: any) => string,
  params: (...params: any) => { [key: string]: any },
  before: (...params: any) => void,
  nav: (...params: any) => Promise<boolean>,
  [key: string]: (...params: any) => any,
}

interface NavPages {
  HomePage: NavPage,
  HomePage_PlayDetailModal: NavPage,
  PlayCollectionsListPage: NavPage,
  PlayCollectionDetailPage: NavPage,
  PlayDetailPage: NavPage,
  PlayViewPage: NavPage,
  PlayViewExtraPage: NavPage,
  MyAccountPage: NavPage,
  MyListPage: NavPage,
  BenefitsPage: NavPage,
  ContactPage: NavPage,
  TermsPage: NavPage,
  PrivacyPolicyPage: NavPage,
  CookiesPolicyPage: NavPage,
  ConsumerProtectionPage: NavPage,
  HelpCenterPage: NavPage,
  RegisterPage: NavPage,
  AboutUsPage: NavPage,
  SubscriptionPlansPage: NavPage,
  CorporativePage: NavPage,
  PromoPage: NavPage,
  PromoInternationalPage: NavPage,
  NewsListPage: NavPage,
  NewsDetailPage: NavPage,
  NotAvailableRegionPage: NavPage,
  CancelSubscriptionPage: NavPage,
  InvoicesPage: NavPage,
  ActivationPage: NavPage,
  SubscribePage: NavPage,
  GiftCardBuyPage: NavPage,
  GiftCardsPage: NavPage,
  PPVBuyPage: NavPage,
  LoginProviderPage: NavPage,
}

@Injectable({
  providedIn: 'root'
})
export class NavService {

  pages: NavPages = {
    HomePage: {
      link: () => `/teatro-online`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.HomePage.link()]),
    },
    HomePage_PlayDetailModal: {
      link: (play: Play) => this._routeS.getSnapshotLastChild().data.name === 'HomePage' ? `/preview/${play?.slug}/${play?.id}` : null,
      params: () => null,
      before: (play: Play) => {
        this._routeS.getSnapshotLastChild().data.name === 'HomePage'
          ? this._playDetailModalS.set(play)
          : this._playDetailModalS.open(play);
      },
      nav: (play: Play) => {
        this.pages.HomePage_PlayDetailModal.before(play);

        return this._routeS.getSnapshotLastChild().data.name === 'HomePage'
          ? this._router.navigate([this.pages.HomePage_PlayDetailModal.link(play)])
          : null;
      },
    },
    PlayCollectionsListPage: {
      link: () => `/colecciones`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.PlayCollectionsListPage.link()]),
    },
    PlayCollectionDetailPage: {
      link: (playCollection: PlayCollection) => `/colecciones/${playCollection?.slug}/${playCollection?.id}`,
      params: () => null,
      before: () => null,
      nav: (playCollection: PlayCollection) => this._router.navigate([this.pages.PlayCollectionDetailPage.link(playCollection)]),
    },
    PlayDetailPage: {
      link: (play: Play) => `/obras/${play?.slug}/${play?.id}`,
      linkByIdAndSlug: (id: number, slug: string) => `/obras/${slug}/${id}`,
      params: () => null,
      before: () => null,
      nav: (play: Play) => this._router.navigate([this.pages.PlayDetailPage.link(play)]),
      navByIdAndSlug: (id: number, slug: string) => this._router.navigate([this.pages.PlayDetailPage.linkByIdAndSlug(id, slug)]),
    },
    PlayViewPage: {
      link: (play: Play) => `/obras/${play?.slug}/${play?.id}/videos/obra`,
      linkByIdAndSlug: (id: number, slug: string) => `/obras/${slug}/${id}/videos/obra`,
      params: () => null,
      before: () => null,
      nav: (play: Play) => this._router.navigate([this.pages.PlayViewPage.link(play)]),
      navByIdAndSlug: (id: number, slug: string) => this._router.navigate([this.pages.PlayViewPage.linkByIdAndSlug(id, slug)]),
    },
    PlayViewExtraPage: {
      link: (play: Play, playVideoExtra: IPlayVideo) => `/obras/${play?.slug}/${play?.id}/videos/extras/${playVideoExtra?.id}`,
      params: () => null,
      before: () => null,
      nav: (play: Play) => this._router.navigate([this.pages.PlayViewPage.link(play)]),
    },
    MyAccountPage: {
      link: () => `/mi-cuenta`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.MyAccountPage.link()]),
    },
    CancelSubscriptionPage: {
      link: () => `/mi-cuenta/baja`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.CancelSubscriptionPage.link()]),
    },
    InvoicesPage: {
      link: () => `/mi-cuenta/facturacion`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.InvoicesPage.link()]),
    },
    MyListPage: {
      link: () => `/mi-lista`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.MyListPage.link()]),
    },
    BenefitsPage: {
      link: () => `/beneficios`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.BenefitsPage.link()]),
    },
    ContactPage: {
      link: () => `/contacto`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.ContactPage.link()]),
    },
    TermsPage: {
      link: () => `/terminos-y-condiciones`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.TermsPage.link()]),
    },
    PrivacyPolicyPage: {
      link: () => `/politica-de-privacidad`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.PrivacyPolicyPage.link()]),
    },
    CookiesPolicyPage: {
      link: () => `/politica-de-cookies`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.CookiesPolicyPage.link()]),
    },
    ConsumerProtectionPage: {
      link: () => `/defensa-al-consumidor`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.ConsumerProtectionPage.link()]),
    },
    HelpCenterPage: {
      link: () => `/centro-de-ayuda`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.HelpCenterPage.link()]),
    },
    RegisterPage: {
      link: () => `/suscribite`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.RegisterPage.link()]),
    },
    AboutUsPage: {
      link: () => `/quienes-somos`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.AboutUsPage.link()]),
    },
    SubscriptionPlansPage: {
      link: () => `/planes-de-suscripcion`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.SubscriptionPlansPage.link()]),
    },
    CorporativePage: {
      link: () => `/corporativo`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.CorporativePage.link()]),
    },
    PromoPage: {
      link: () => `/promo`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.PromoPage.link()]),
    },
    PromoInternationalPage: {
      link: () => `/promo-inter`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.PromoInternationalPage.link()]),
    },
    NewsListPage: {
      link: () => `/noticias`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.NewsListPage.link()]),
    },
    NewsDetailPage: {
      link: (newsDetail: News) => `/noticias/${newsDetail?.slug}/${newsDetail?.id}`,
      params: () => null,
      before: () => null,
      nav: (newsDetail: News) => this._router.navigate([this.pages.NewsDetailPage.link(newsDetail)]),
    },
    NotAvailableRegionPage: {
      link: () => `/not-available-region`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.NotAvailableRegionPage.link()]),
    },
    ActivationPage: {
      link: () => `/activar`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.ActivationPage.link()]),
    },
    SubscribePage: {
      link: () => `/suscribite-hoy`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.SubscribePage.link()]),
    },
    GiftCardBuyPage: {
      link: (giftCardId: number) => `/comprar-gift-card/${giftCardId}`,
      params: () => null,
      before: () => null,
      nav: (giftCardId: number) => this._router.navigate([this.pages.GiftCardBuyPage.link(giftCardId)]),
    },
    GiftCardsPage: {
      link: () => `/regala-teatrix`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.GiftCardsPage.link()]),
    },
    PPVBuyPage: {
      link: (play: Play) => `/comprar-ppv/${play?.slug}/${play?.id}`,
      params: () => null,
      before: () => null,
      nav: (play: Play) => this._router.navigate([this.pages.PPVBuyPage.link(play)]),
    },
    LoginProviderPage: {
      link: () => `/login-provider`,
      params: () => null,
      before: () => null,
      nav: () => this._router.navigate([this.pages.LoginProviderPage.link()]),
    },
  };

  constructor(
    private _location: Location,
    private _router: Router,
    private _routeS: RouteService,
    private _playDetailModalS: PlayDetailModalService,
  ) { }

  back(): void {
    setTimeout(() => {
      this._location.back();
    });
  }
}
