import { Params } from '@angular/router';
import { Form } from './form';
import { FormExtra } from '../types/form-extras';
import * as _ from 'lodash';

export class FormQueryParams {

  constructor(private _form: Form) { }

  /* -------------------- */

  setValuesFromUrl(queryParams: Params, options: { withInit?: boolean, emitEvent?: boolean } = {}) {
    let params: any = {};

    _.forEach(this._form.getExtras(), (extra: FormExtra, key: string) => {
      if (!extra.queryParam) return;

      const queryParamName: string = extra.queryParam.name;
      const queryParamValue: any = this._resolveQueryParamValue(extra, queryParams[queryParamName]);

      if (_.has(queryParams, queryParamName)) {
        _.set(params, key, queryParamValue);
      }
    });

    this._form.state.setAndReset(params, { withInit: options.withInit, emitEvent: options.emitEvent });
  }

  getValuesForUrl() {
    const state = this._form.group.getRawValue();
    let params: any = {};

    _.forEach(this._form.getExtras(), (extra: FormExtra, key: string) => {
      if (!extra.queryParam) return;

      const stateValue: any = this._resolveStateValue(extra, _.get(state, key));
      _.set(params, extra.queryParam.name, stateValue);
    });

    return params;
  }

  /* -------------------- */

  private _resolveQueryParamValue(extra: FormExtra, value: any): any {
    if (!value) return '';

    if (_.isString(value) && value.startsWith('(') && value.endsWith(')')) {
      value = value.replace(/\(|\)/g, '').split(',');
      value = _.without(value.map((item: any) => this._resolveQueryParamValue(extra, item)), '');

    } else if (!!extra.queryParam.field) {
      if (extra.queryParam.field === 'id') {
        extra.queryParam.isNumber = true;
      }

      value = { [extra.queryParam.field]: this._parseQueryParamValue(extra, value) };

    } else {
      value = this._parseQueryParamValue(extra, value);
    }

    return value;
  }

  private _parseQueryParamValue(extra: FormExtra, value: any): any {
    if (extra.queryParam.isNumber) {
      value = parseInt(value);

    } else if (extra.queryParam.isBoolean) {
      value = value === '1' ? true: false;
    }

    return value;
  }

  private _resolveStateValue(extra: FormExtra, value: any): any {
    if (_.isArray(value)) {
      value = value.map((item: any) => this._resolveStateValue(extra, item));
      value = '(' + value.join(',') + ')';

    } else if (!!extra.queryParam.field) {
      value = value[extra.queryParam.field];
    }

    return value;
  }
}
