import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestErrorComponent } from './request-error.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [RequestErrorComponent],
  imports: [
    CommonModule,
    NgbAlertModule,
  ],
  exports: [
    RequestErrorComponent
  ]
})
export class RequestErrorModule { }
