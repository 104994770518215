import { Component, Input, OnInit } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';

@Component({
  selector: 'app-auth-subscribe-modal',
  templateUrl: './auth-subscribe-modal.component.html',
  styleUrls: ['./auth-subscribe-modal.component.scss']
})
export class AuthSubscribeModalComponent implements OnInit {

  @Input() play: Play;
  @Input() ppvCode: boolean;

  constructor() { }

  ngOnInit(): void {
    //
  }
}
