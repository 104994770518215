import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import { delay, map } from 'rxjs/operators';
import { factory } from 'src/app/commons/factory';
import { Play } from 'src/app/core/play/play.model';
import { forkJoin, Observable, of } from 'rxjs';
import { playHttpServiceMock } from './mocks/mocks';
import { cloneDeep } from 'lodash';
import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root'
})
export class PlayHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
    private _cacheS: CacheService,
  ) { }

  /* -------------------- */

  get(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`cache:/plays/all?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getPpv(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`cache:/plays/getAllPPV?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getFeatured(params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`cache:/plays/featured-plays?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getBySearch(input: string, params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/plays/search?${paramsParsed}`), { search: input }, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getByCategoryMedium(categoryId: number, params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);
    const endpoint = `cache:/categories/medium/${categoryId}?${paramsParsed}`;

    if (this._httpS.useMocks) {
      return of(cloneDeep(playHttpServiceMock.getByCategoryMedium_22)).pipe(delay(200));
    }

    if (this._cacheS.get(endpoint)) {
      return of(this._cacheS.get(endpoint).data);
    }

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(endpoint), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }).pipe(
      map((res: any) => {
        this._cacheS.set(endpoint, res);
        return res;
      })
    ), tokenType);
  }

  getByCountry(countryId: number, params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`cache:/plays/getByCountry/${countryId}?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getOneLarge(playId: number, params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<Play>(this._httpClient.get(this._httpS.getEndpoint(`cache:/plays/large/${playId}?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }).pipe(
      map((res: any) => {
        if (res.hasOwnProperty('geo_available')) {
          return res;
        }

        return factory.create<Play>(res.data, Play);
      })
    ), tokenType);
  }

  getOneLargeWithSeoData(playId: number, params?: any) {
    let observables = forkJoin({
      resGetOneLarge: this.getOneLarge(playId, params),
      resGetSeoData: this.getSeoData(playId, params),
    });

    return observables.pipe(
      map(res => {
        if (res.resGetSeoData.data.meta_title) {
          res.resGetOneLarge['seo'] = res.resGetSeoData.data;
        }

        return res.resGetOneLarge;
      })
    );
  }

  getFor404(categoryIds: number[], params?: any) {
    const tokenType: TokenType = 'authUserToken';
    const paramsParsed = this._httpS.parseParams(params);

    const input = {
      categories: categoryIds
    };

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/plays/infoFor404?${paramsParsed}`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  /* -------------------- */

  getFreeTrialStatus(params?: any) {
    const tokenType: TokenType = 'authGuestToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/plays/open-platform?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  /* -------------------- */

  getSeoData(playId: number, params?: any) {
    const tokenType: TokenType = 'authGuestToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/plays/get-seo-data/${playId}?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }
}
