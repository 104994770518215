import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';
import { factory } from 'src/app/commons/factory';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';
import { AuthLoginModalService } from '../../core/auth/auth-login-modal/auth-login-modal.service';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { PlayCategory } from 'src/app/core/play-category/play-category.model';
import { List } from 'src/app/commons/list';
import { PlayHttpService } from 'src/app/services/http/play-http.service';
import { PlaySearchbarComponent } from '../../core/play/play-searchbar/play-searchbar.component';
import { ViewportService } from 'src/app/services/viewport.service';
import { SsrService } from 'src/app/services/ssr.service';
import { Router } from "@angular/router";
import { ActivatedRoute, Params } from '@angular/router';
import { PlayCategoryHttpService } from 'src/app/services/http/play-category-http.service';
import { CacheService } from 'src/app/services/cache.service';

type MenuSecondaryType = 'catalog' | 'internationals' | 'ppvs' | 'collections';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidebarMenuContentElem') sidebarMenuContentElem: ElementRef<HTMLDivElement>;

  categories: PlayCategory[];
  categoriesInternational: PlayCategory[];
  categoryPpv: PlayCategory;

  categorySelected: PlayCategory = null;

  isOpen: boolean = false;
  isMenuSecondaryOpen: boolean = false;
  isResultsOpen: boolean = false;
  isResultsForSearchOpen: boolean = false;

  menuSecondaryType: MenuSecondaryType = null;

  resultsL: List<Play> = new List<Play>();
  resultsTitle: string;

  private _sm: SubscriptionManager = new SubscriptionManager

  constructor(
    public storeS: StoreService,
    public authLoginModalS: AuthLoginModalService,
    public navS: NavService,
    private _ssrS: SsrService,
    private _viewportS: ViewportService,
    private _playHttpS: PlayHttpService,
    private _elem: ElementRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _playCategoryHttpS: PlayCategoryHttpService,
    private _cacheS: CacheService,
  ) { }

  ngOnInit(): void {
    this._sm.add(
      this.storeS.playCategories.subscribe(value => {
        if (value) {
          this.categories = PlayCategory.getForSidebar(value);
          this.categoriesInternational = PlayCategory.getInternational(value);
          this.categoryPpv = PlayCategory.getPpv(value);
        }
      })
    );

    if (this._ssrS.isBrowser()) {
      this._sm.add(this._route.queryParams.subscribe((queryParams: Params) => {
        if (queryParams['menu'] === 'open') {
          this.openInner();

          if (!!queryParams['menuSecondaryType']) {
            this.openMenuSecondaryInner(queryParams['menuSecondaryType']);

            if (!!queryParams['menuCategoryId']) {
              let categories: any;

              switch (queryParams['menuSecondaryType']) {
                case 'catalog': categories = this.categories; break;
                case 'internationals': categories = this.categoriesInternational; break;

                default: null;
              }

              const category = categories?.filter(category => category.id == queryParams['menuCategoryId'])[0];

              this.openResultsInner(category);
            }
            else {
              this.closeResultsInner();
            }
          }
          else if (queryParams['menuCategoryId'] == 9001) {
            this.openResultsInner(this.categoryPpv);
          }
          else if (queryParams['menuSearch'] === 'open') {
            this.openResultsForSearchInner();
          }
          else {
            this.closeResultsInner();
            this.closeMenuSecondaryInner();
          }
        }
        else {
          this.closeInner();
        }
      }));

      this._sm.add(
        this._viewportS.windowWidth.subscribe(() => {
          if (this._viewportS.up('lg') && this.isResultsForSearchOpen) {
            this.closeResults();
          }
        })
      );
    }
  }

  ngAfterViewInit(): void {
    if (this._ssrS.isBrowser()) {
      this.sidebarMenuContentElem.nativeElement.addEventListener('transitionend', (event: TransitionEvent) => {
        if (!this.isMenuSecondaryOpen) {
          this.menuSecondaryType = null;
        }
      });

      window.addEventListener('keyup', (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.close();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._sm.clean();

    if (this._ssrS.isBrowser()) {
      this.sidebarMenuContentElem.nativeElement.removeEventListener('transitionend', null);
      window.removeEventListener('keyup', null);
    }
  }

  /* -------------------- */

  open(): void {
    this._router.navigate([], {
      queryParams: { menu: 'open' },
    });
  }

  close(event?: MouseEvent): void {
    if (event) {
      if (event.target === event.currentTarget) {
        this._router.navigate([]);
      }
    } else {
      this._router.navigate([]);
    }
  }

  openInner(): void {
    setTimeout(() => this.isOpen = true);

    document.querySelector('body').classList.add('sidebar-open');

    setTimeout(() => {
      this._elem.nativeElement.querySelector('.menu-main').focus();
    }, 500);
  }

  closeInner(): void {
    this.isOpen = false;

    this.closeResultsInner();
    this.closeMenuSecondaryInner();

    document.querySelector('body').classList.remove('sidebar-open');
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  /* -------------------- */

  openMenuSecondary(type: MenuSecondaryType): void {
    this._router.navigate([], {
      queryParams: { menuSecondaryType: type, menuCategoryId: null, menuSearch: null },
      queryParamsHandling: 'merge'
    });
  }

  closeMenuSecondary(): void {
    this._router.navigate([], {
      queryParams: { menuSecondaryType: null, menuCategoryId: null },
      queryParamsHandling: 'merge'
    });
  }

  openMenuSecondaryInner(type: MenuSecondaryType): void {
    this.menuSecondaryType = type;
    this.isMenuSecondaryOpen = true;

    setTimeout(() => {
      this._elem.nativeElement.querySelector('.' + type).focus();
    }, 500);
  }

  closeMenuSecondaryInner(): void {
    this.isMenuSecondaryOpen = false;
  }

  /* -------------------- */

  openResults(category: PlayCategory): void {
    this._router.navigate([], {
      queryParams: { menuCategoryId: category.id },
      queryParamsHandling: "merge"
    });
  }

  closeResults(): void {
    this._router.navigate([], {
      queryParams: { menuCategoryId: null, menuSearch: null },
      queryParamsHandling: 'merge'
    });
  }

  openResultsForSearch(): void {
    this._router.navigate([], {
      queryParams: { menuSearch: 'open'},
      queryParamsHandling: "merge"
    });
  }

  openResultsInner(category: PlayCategory): void {
    this.categorySelected = category;
    this.resultsTitle = this.categorySelected.name;
    this.isResultsOpen = true;
    this.isResultsForSearchOpen = false;

    this._sm.clean('getResults');
    this.getResults(1, true);

    setTimeout(() => {
      this._elem.nativeElement.querySelector('.results').focus();
    }, 500);
  }

  openResultsForSearchInner(): void {
    if (!this.isResultsForSearchOpen) {
      this.resultsL.reset();
    }

    this.categorySelected = null;
    this.resultsTitle = 'Buscar';
    this.isResultsOpen = true;
    this.isResultsForSearchOpen = true;

    this._sm.clean('getResults');
  }

  closeResultsInner(): void {
    this._sm.clean('getResults');

    this.categorySelected = null;
    this.isResultsOpen = false;
    this.isResultsForSearchOpen = false;
  }

  /* -------------------- */

  getResults(page: number = 1, reset?: boolean) {
    if (this.resultsL.request.isLoading()) return;
    if (reset) this.resultsL.reset();

    let method: string = 'getByCategoryMedium';

    if (this.categorySelected.is_international) {
      method = 'getByCountry';
    } else if (this.categorySelected.id === this.categoryPpv.id) {
      method = 'getPpv';
    }

    this._sm.add(
      this.resultsL.request.send<any>(this._playHttpS[method](this.categorySelected.id)).subscribe(res => {
        this.resultsL.add(res, Play);
      })
    , 'getResults');
  }

  /* -------------------- */

  logout(): void {
    this.storeS.authUser.value?.logout(this.storeS, this._cacheS);

    this._playCategoryHttpS.get().toPromise();
  }
}
