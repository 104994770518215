import { Injectable, OnDestroy } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';

@Injectable({
  providedIn: 'root'
})
export class NavbarService implements OnDestroy {

  hasBg: boolean = false;
  hide: boolean = false;

  private _lastScrollTop: number = 0;
  private _sm: SubscriptionManager = new SubscriptionManager;

  constructor(
    private _scrollS: ScrollService
  ) {

    this._sm.add(this._scrollS.event.subscribe((event: any) => {
      this.scrollHandler(event);
    }));
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  /* -------------------- */

  scrollHandler(event: any): void {
    if (event.target.scrollingElement.scrollTop > 20) {
      this.hasBg = true;
    } else {
      this.hasBg = false;
    }

    if (event.target.scrollingElement.scrollTop >= this._lastScrollTop && event.target.scrollingElement.scrollTop >= 100) {
      this.hide = true;
    } else {
      this.hide = false;
    }

    this._lastScrollTop = event.target.scrollingElement.scrollTop;
  }
}
