import { UntypedFormBuilder, UntypedFormArray, FormGroup, AbstractControl } from '@angular/forms';
import { Form } from './form';
import { FormExtraArray } from '../types/form-extras';
import * as _ from 'lodash';

export class FormArrays {

  private _fb: UntypedFormBuilder = new UntypedFormBuilder;

  constructor(private _form: Form) { }

  /* -------------------- */

  get(name: string): FormExtraArray {
    return this._form.get([name, 'array']);
  }

  /* -------------------- */

  reset(withInit?: boolean): void {
    _.forEach(this._form.getExtras(), (extra: any, name: string) => {
      if (!!this.get(name) && !name.includes('*')) {
        this.clear(name);

        let state: any;

        withInit ?
          state = this._form.state.init:
          state = this._form.state.current;

        let count: number = (_.get(state, name, []) as any[]).length;

        if (!!this.get(name).min && count < this.get(name).min) {
          count = this.get(name).min;
        }

        this.add(name, count);

        if (!!this.get(name).children) {
          this.resetChildren(this.get(name).children, name, state);
        }
      }
    });
  }

  resetChildren(children: any, name: string, state: any): void {
    _.forEach(children, (extraName: string, fieldName: string) => {
      const extraArray: FormExtraArray = this.get(extraName);

      _.forEach(this._form.getControlArray(name).controls, (group: AbstractControl, groupKey: number) => {
        let count: number = (_.get(state, `${name}.${groupKey}.${fieldName}`, []) as any[]).length;

        if (!!extraArray.min && count < extraArray.min) {
          count = extraArray.min;
        }

        this.add(extraName, count, group.get(fieldName) as UntypedFormArray);

        if (!!extraArray.children) {
          this.resetChildren(extraArray.children, `${name}.${groupKey}.${fieldName}`, state);
        }
      });
    });
  }

  /* -------------------- */

  add(name: string, count: number = 1, groupArray?: UntypedFormArray): void {
    if (!groupArray) groupArray = this._form.getControlArray(name);

    if (!!groupArray) {
      this._form.removeError(this._form.getControlPath(groupArray));

      for (let i = 0; i < count; i++) {
        groupArray.push(_.cloneDeep(this.get(this._form.getExtraName(name)).group));

        groupArray.markAsDirty();
        groupArray.markAsTouched();

        this._form.removeError('asd');
      }
    }
  }

  addWith(name: string, value: any, count: number = 1, groupArray?: AbstractControl|UntypedFormArray): void {
    if (!groupArray) groupArray = _.cloneDeep(this._form.getControlArray(name));

    if (!!groupArray) {
      this._form.removeError(this._form.getControlPath(groupArray));

      for (let i = 0; i < count; i++) {
        const group = _.cloneDeep(this.get(this._form.getExtraName(name)).group);
        group.patchValue(value);

        (groupArray as UntypedFormArray).push(group);

        groupArray.markAsDirty();
        groupArray.markAsTouched();

        this._form.removeError('asd');
      }
    }
  }

  remove(name: string, index: number, groupArray?: AbstractControl|UntypedFormArray): void {
    if (!groupArray) groupArray = this._form.getControlArray(name);

    if (!!groupArray) {
      this._form.removeError(this._form.getControlPath(groupArray));

     (groupArray as UntypedFormArray).removeAt(index);

      groupArray.markAsDirty();
      groupArray.markAsTouched();
    }
  }

  clear(name: string, groupArray?: AbstractControl|UntypedFormArray): void {
    if (!groupArray) groupArray = this._form.getControlArray(name);

    if (!!groupArray) {
      this._form.removeError(this._form.getControlPath(groupArray));

      (groupArray as UntypedFormArray).clear();

      groupArray.markAsDirty();
      groupArray.markAsTouched();
    }
  }
}
