import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Request } from 'src/app/commons/request';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { AuthUser } from 'src/app/core/auth-user/auth-user.model';
import { Play } from 'src/app/core/play/play.model';
import { AuthUserPlayHttpService } from 'src/app/services/http/auth-user-play-http.service';
import { StoreService } from 'src/app/services/store.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-button-add-to-my-list',
  templateUrl: './button-add-to-my-list.component.html',
  styleUrls: ['./button-add-to-my-list.component.scss']
})
export class ButtonAddToMyListComponent implements OnInit, OnDestroy {

  @Input() play: Play;
  @Input() size: 'xs' | 'sm' | 'md' = 'sm';
  @Input() onlyIcon: boolean = false;
  @Input() tabIndex: string = '0';

  toggleRequest: Request = new Request();

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    public storeS: StoreService,
    private _authUserPlayHttpS: AuthUserPlayHttpService,
    private _toastS: ToastService,
  ) { }

  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  get iconSize(): number {
    switch (this.size) {
      case 'xs': return 1;
      case 'sm': return 1.25;

      default: return 1.50;
    }
  }

  /* -------------------- */

  toggle(): void {
    if (this.toggleRequest.isLoading()) return;
    if (!this.storeS.authUser.value?.user.hasSubscription()) return;

    let method: string;

    this.play.in_my_list
      ? method = 'removeFromMyList'
      : method = 'addToMyList';

    this.play.in_my_list = !this.play.in_my_list;

    this.toggleRequest.send(this._authUserPlayHttpS[method](this.play.id)).subscribe((res: any) => {
      //
    }, (err: HttpErrorResponse) => {
      this.play.in_my_list = !this.play.in_my_list;
      this._toastS.showError('No se ha podido llevar a cabo la acción solicitada.');
    });
  }

  tooltipTitle(): string {
    if (!this.onlyIcon) return '';

    return !this.play.in_my_list ? 'Agregar a mi lista' : 'Quitar de mi lista';
  }
}
