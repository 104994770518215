<div *ngIf="loading" class="spinner-border spinner-border-sm text-app-red ms-2" role="status">
    <span class="visually-hidden">Loading...</span>
</div>

<!-- <span *ngIf="error" class="fa fa-fw fa-times text-danger"></span>
<span *ngIf="success" class="fa fa-fw fa-check text-success"></span> -->

<ng-container *ngIf="error || success">
    <div class="border border-gray-400 rounded text-uppercase px-2 py-1 ms-2 _fs-10">
        <ng-container *ngIf="error">
            <strong class="text-danger">no válido</strong>
        </ng-container>

        <ng-container *ngIf="success">
            <strong class="text-success">válido</strong>
        </ng-container>
    </div>
</ng-container>
