<i class="fa-fw fas fa-ban"
    [ngbPopover]="tpl"
    triggers="mouseenter:mouseleave"
    placement="bottom-left"
    popoverClass="app-popover-form"
></i>

<ng-template #tpl>
    <ul class="list-unstyled m-0">
        <li>This field is disabled.</li>
    </ul>
</ng-template>
