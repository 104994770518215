import { Component, OnInit, Optional, Output, EventEmitter } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { FormComponent } from '../../form/form.component';
import { SortableEvent } from 'sortablejs';

@Component({
  selector: 'app-form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.scss']
})
export class FormArrayComponent extends AbstractFormFieldComponent implements OnInit {

  @Output() sorted$: EventEmitter<any> = new EventEmitter;

  constructor(
    @Optional() public formC: FormComponent,
    @Optional() protected _formGroupParent: FormGroupDirective,
  ) {
    super();

    this._formFieldClassName = 'FormArrayComponent';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}
