import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-status',
  templateUrl: './form-status.component.html',
  styleUrls: ['./form-status.component.scss']
})
export class FormStatusComponent implements OnInit {

  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() success: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
