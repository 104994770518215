export interface IAuthGuest {
  token: string;
}

export class AuthGuest implements IAuthGuest {

  token: string;

  /* -------------------- */

  constructor(value: IAuthGuest) {
    Object.assign(this, value);
  }

  /* -------------------- */

  isLoggedIn(): boolean {
    return !!this.token;
  }
}
