import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'app-form-textarea',
  templateUrl: './form-textarea.component.html',
})
export class FormTextareaComponent extends AbstractFormFieldComponent implements OnInit {

  @Input() rows: number = 4;

  constructor(
    @Optional() public formC: FormComponent,
    @Optional() protected _formGroupParent: FormGroupDirective,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
