import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'app-form-default',
  templateUrl: './form-default.component.html',
  styleUrls: ['./form-default.component.scss']
})
export class FormDefaultComponent extends AbstractFormFieldComponent implements OnInit {

  @Input() innerClass: string;

  constructor(
    @Optional() public formC: FormComponent,
    @Optional() protected _formGroupParent: FormGroupDirective,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
