import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { HoverPreviewComponent } from './hover-preview.component';
import { HoverPreviewDirective } from './hover-preview.directive';



@NgModule({
  declarations: [
    HoverPreviewComponent,
    HoverPreviewDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HoverPreviewComponent,
    HoverPreviewDirective
  ]
})
export class HoverPreviewModule { }
