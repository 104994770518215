import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-header-with-register-template',
  templateUrl: './simple-header-with-register-template.component.html',
  styleUrls: ['./simple-header-with-register-template.component.scss']
})
export class SimpleHeaderWithRegisterTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
