import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

export const helper = {

  lastId: 0,
  lastDate: new Date,

  getNewId(): number {
    return ++this.lastId;
  },

  printTimestamp(message: string = '', reset: boolean = false): void {
    if (environment.production === false && environment.debug === true) {
      if (reset) {
        this.lastDate = new Date;
      }

      const now = new Date;
      console.log(message, now, (now.getTime() - this.lastDate.getTime()) / 1000);
    }
  },

  consoleLog(type: 'Routes' | 'Info' | 'Error' | 'Request' | 'RequestResponse' | 'RequestError' | 'Guard' | 'Navigation', values: any[]): void {
    if (environment.production === false && environment.debug === true) {
      let textColor: string;

      switch (type) {
        case 'Routes': textColor = 'orange'; break;
        case 'Info': textColor = 'gold'; break;
        case 'Error': textColor = 'red'; break;
        case 'Request': textColor = 'blue'; break;
        case 'RequestResponse': textColor = 'green'; break;
        case 'RequestError': textColor = 'red'; break;
        case 'Guard': textColor = 'purple'; break;
        case 'Navigation': textColor = 'pink'; break;
        default: return;
      }

      console.log(
        '%c-- [DEBUG]' + '%c[' + type + ']', 'color: darkgray', 'color: ' + textColor,
        ...values,
        '(' + Date.now() + ')');
    }
  },

  getGrid(columns: number, source: any[]) {
    return {
      columns,
      columnsToFill: new Array(columns - (source.length % columns || columns))
    };
  },

  fullscreenEnter() {
    const elem: any = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }

    if ('onorientationchange' in window) {
      screen.orientation.lock('landscape-primary');
    }
  },

  fullscreenExit() {
    const elem: any = document;

    if (elem.exitFullscreen) {
      elem.exitFullscreen();
    } else if (elem.webkitExitFullscreen) { /* Safari */
      elem.webkitExitFullscreen();
    } else if (elem.msExitFullscreen) { /* IE11 */
      elem.msExitFullscreen();
    }

    if ('onorientationchange' in window) {
      screen.orientation.lock('portrait');
    }
  },

  fullscreenToggle() {
    if (!document.fullscreenElement) {
      this.fullscreenEnter();
    } else {
      this.fullscreenExit();
    }
  },

  copyToClipboard(value: string, toast?) {
    let elem = document.createElement('textarea');
    elem.style.position = 'fixed';
    elem.style.left = '0';
    elem.style.top = '0';
    elem.style.opacity = '0';
    elem.value = value;
    document.body.appendChild(elem);
    elem.focus();
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    if (toast) {
      toast.show('Success', { body: 'Copiado al portapapeles', delay: 2000 });
    }
  },

  showSwal(title: string, text: string, type: any, callback: any = undefined) {
    Swal.fire({
      title: title,
      text: text,
      icon: type,
      confirmButtonText: 'Entendido',
      buttonsStyling: false,
      customClass: {
          confirmButton: 'btn btn-app-red'
      },
    }).then(callback);
  }
};

export const onViewportIntersection = (elem: Element, callbackIn: () => any, callbackOut?: () => any) => {
  if (typeof window === 'undefined' || !('IntersectionObserver' in window)) {
    return callbackIn();
  }

  // elem = elem.querySelector('.intersection-observer-target') || elem;

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!callbackOut) observer.unobserve(elem);
        callbackIn();
      } else if (callbackOut) {
        callbackOut();
      }
    });
  });

  observer.observe(elem);

  return observer;
}
