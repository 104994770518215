<ng-container *ngIf="usePopover">
    <i class="fa-fw fas fa-exclamation-circle"
        [ngbPopover]="tpl"
        triggers="mouseenter:mouseleave"
        placement="bottom-left"
        popoverClass="app-popover-form"
    ></i>
</ng-container>

<ng-container *ngIf="!usePopover" [ngTemplateOutlet]="tpl"></ng-container>

<ng-template #tpl>
    <ul class="ps-3 m-0 text-danger _fs-14">
        <ng-container *ngIf="formFieldC.control.errors">
            <ng-container *ngIf="customErrorIndexes">
                <li *ngFor="let validatorName of customErrorIndexes">
                    {{ formFieldC.control.errors[validatorName] }}
                </li>
            </ng-container>

            <li *ngIf="formFieldC.control.errors?.mask?.requiredMask">
                Este campo no tiene un formato válido.
            </li>

            <li *ngIf="formFieldC.control.errors?.required">
                Este campo es requerido.
            </li>

            <li *ngIf="formFieldC.control.errors?.email">
                Este campo debe ser un email.
            </li>

            <li *ngIf="formFieldC.control.errors?.minlength">
                Este campo debe contener al menos {{ formFieldC.control.errors.minlength.requiredLength }} {{ formFieldC.control.errors.minlength.requiredLength === 1 ? 'caracter' : 'caracteres' }}.
            </li>
        </ng-container>

        <ng-container *ngIf="!!formFieldC.hasError(index, startsWith)">
            <ng-container *ngFor="let error of formFieldC.getError(index, startsWith)">
                <li>{{ error }}</li>
            </ng-container>
        </ng-container>
    </ul>
</ng-template>
