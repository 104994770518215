<button [appLoadingBtn]="request?.isLoading()" class="btn rounded-2 px-4 px-sm-5" [class.w-100]="fullWidth" [ngClass]="color" [disabled]="disabled">
    <ng-container *ngIf="icon">
        <app-icon onleft-lg [name]="icon" [hasLoadingBtn]="true">
            <strong><ng-content select="[content]"></ng-content></strong>
        </app-icon>
    </ng-container>

    <ng-container *ngIf="!icon">
        <strong><ng-content></ng-content></strong>
    </ng-container>
</button>
