import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appDays'
})
export class DaysPipe implements PipeTransform {

  transform(value: number): any {
    let seconds = value * 3600;

    let days = Math.floor(seconds / (3600*24));
    let hours = Math.floor(seconds % (3600*24) / 3600);

    if (days > 0 && hours === 0) {
      return `${days} días`;
    } else if (days > 0 && hours > 0) {
      return `${days} días y ${hours} horas`;
    } else if (days === 0 && hours > 0) {
      return `${hours} horas`;
    }
  }
}
