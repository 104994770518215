import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormOptionsLoadingComponent } from './form-options-loading.component';



@NgModule({
  declarations: [FormOptionsLoadingComponent],
  imports: [
    CommonModule
  ],
  exports: [FormOptionsLoadingComponent]
})
export class FormOptionsLoadingModule { }
