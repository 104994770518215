<div class="sticky-bars-container">
    <app-subscribe-bar></app-subscribe-bar>
</div>

<div class="grid grid-page">
    <div header>
        <app-navbar [onlyLogo]="true"></app-navbar>
    </div>

    <div body>
        <router-outlet></router-outlet>
    </div>

    <div footer>
        <app-footer></app-footer>
    </div>
</div>

