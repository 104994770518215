import { Component, OnInit, Optional, Input } from '@angular/core';
import { FormArrayComponent } from '../../form-fields/form-array/form-array.component';
import * as _ from 'lodash';
import { UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'app-form-array-buttons',
  templateUrl: './form-array-buttons.component.html',
  styleUrls: ['./form-array-buttons.component.scss']
})
export class FormArrayButtonsComponent implements OnInit {

  @Input() index: number;
  @Input() last: boolean;

  constructor(
    @Optional() public formArrayC: FormArrayComponent
  ) { }

  ngOnInit(): void {
  }

  get _(): any {
    return _;
  }

  get controlArray(): UntypedFormArray {
    return this.formArrayC.control as UntypedFormArray;
  }

}
