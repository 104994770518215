import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { info } from 'src/app/info';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';


@Component({
  selector: 'app-change-version-modal',
  templateUrl: './change-version-modal.component.html',
  styleUrls: ['./change-version-modal.component.scss']
})
export class ChangeVersionModalComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
    private _analytic:AnalyticsService
  ) { }
  GoBack(){
    
    document.location.href='https://www.teatrix.com';
  }
  ngOnInit(): void {
    //
  }

  get info() {
    return info;
  }

}
