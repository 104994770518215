import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './core/http.service';
import { StoreService } from '../store.service';
import { AuthGuest } from 'src/app/core/auth-guest/auth-guest.model';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { authGuestHttpServiceMock } from './mocks/mocks';

@Injectable({
  providedIn: 'root'
})
export class AuthGuestHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
    private _storeS: StoreService
  ) { }

  /* -------------------- */

  login() {
    if (this._httpS.useMocks) {
      return of(authGuestHttpServiceMock.login).pipe(
        map((res: any) => {
          this._storeS.set('authGuest', new AuthGuest(res), true, 60 * 24);

          return res;
        })
      );
    }

    return this._httpS.send(this._httpClient.post(this._httpS.getEndpoint(`/elogin/authenticate`), null, {
      headers: this._httpS.getHeaders()
    })).pipe(
      map((res: any) => {
        this._storeS.set('authGuest', new AuthGuest(res), true, 60 * 24);

        return res;
      })
    );
  }
}
