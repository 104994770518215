import { Form } from './form';
import * as _ from 'lodash';

export class FormState {

  init: any = null;
  current: any = null;
  previous: any = null;

  constructor(private _form: Form) { }

  /* -------------------- */

  set(value: any, withInit?: boolean) {
    if (_.isEmpty(this.init) || withInit) {
      this.setInit(value);
    }

    this._form.group.patchValue(value, { emitEvent: false });
    const valueTmp: any = this._form.group.getRawValue();

    this.previous = _.cloneDeep(this.current);
    this.current = _.cloneDeep(valueTmp);
    // this.current = _.cloneDeep(value);
  }

  setAndReset(value: any, options: { withInit?: boolean, emitEvent?: boolean } = {}) {
    this.set(value, options.withInit);
    this._form.reset(options);
  }

  setControl(name: string, value: any, withInit?: boolean) {
    if (withInit) {
      this.setInitControl(name, value);
    }

    _.set(this.previous, name, _.get(this.current, name));
    _.set(this.current, name, value);
  }

  /* -------------------- */

  setInit(value: any) {
    this.init = _.cloneDeep(value);
  }

  setInitControl(name: string, value: any) {
    _.set(this.init, name, value);
  }

  /* -------------------- */

  hasChanged(): boolean {
    return JSON.stringify(this.previous) !== JSON.stringify(this.current);
  }
}
