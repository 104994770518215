import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestLoadingComponent } from './request-loading.component';
import { RequestErrorModule } from '../request-error/request-error.module';



@NgModule({
  declarations: [RequestLoadingComponent],
  imports: [
    CommonModule,
    RequestErrorModule,
  ],
  exports: [RequestLoadingComponent]
})
export class RequestLoadingModule { }
