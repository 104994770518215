import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { EmailVerificationModalComponent } from './email-verification-modal.component';
import { RouterModule } from '@angular/router';
import { ButtonFormModule } from '../../buttons/button-form/button-form.module';



@NgModule({
  declarations: [
    EmailVerificationModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ButtonFormModule,
  ]
})
export class EmailVerificationModalModule { }
