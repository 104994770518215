<div class="step-counter" [ngClass]="innerClass">
    <span class="number" [class.active]="step === 1" [class.complete]="step > 1">
        <span class="_fs-20">1</span>
        <span><i class="fas fw-fa fa-check text-white"></i></span>
    </span>

    <span class="sep" [class.active]="step === 2"></span>

    <span class="number" [class.active]="step === 2" [class.complete]="step > 2">
        <span class="_fs-20">2</span>
        <span><i class="fas fw-fa fa-check text-white"></i></span>
    </span>

    <span class="sep" [class.active]="step === 3"></span>
    <span class="number _fs-20" [class.active]="step === 3">3</span>
</div>
