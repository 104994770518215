import { Component, OnInit, Input, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { FormComponent } from '../../form/form.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
})
export class FormSelectComponent extends AbstractFormFieldComponent implements OnInit {

  @Input() searchable: string;

  constructor(
    @Optional() public formC: FormComponent,
    @Optional() protected _formGroupParent: FormGroupDirective
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get isSearchableUndefined(): boolean {
    return typeof this.searchable === 'undefined';
  }
}
