import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Form } from '../../tools/form';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  @Input() form: Form;
  @Input() themes: string[] = _.get(environment, 'formTheme', []);
  @Input() debug: boolean = false;

  @Output() submitted$: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }
}
