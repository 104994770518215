import { groupBy, isArray, join, map } from "lodash";
import slugify from "slugify";
import { browserSupports } from "src/app/browser-supports";
import { PlayVideoTypeEnum } from "./enums/play-video-type.enum";
import { PlayVideoMapper } from "./mappers/play-video.mapper";

export interface IPlayPpv {
  primary_color: string;
  img_title_url: string;
  img_banner_url: string;
  price: string;
  currency_symbol: string;
  discount: number;
  duration: number;
  can_buy: boolean;
  can_redeem_code: boolean;
  bought: boolean;
  starts_at: Date;
  ends_at: Date;
}

export interface IPlayCategory {
  id: number;
  name: string;
}

export interface IPlayCountry {
  id: number;
  name: string;
  currency_iso: string;
  code_iso: string;
  image: string;
}

export interface IPlayMember {
  name: string;
  role_id: number;
  role_name: string;
}

export interface IPlayMembersByRole {
  direction: IPlayMember[],
  authorship: IPlayMember[],
  cast: IPlayMember[],
  others: {
    [key: string]: IPlayMember[],
  },
}

export interface IPlayMembersByRoleAsString {
  direction: string,
  authorship: string,
  cast: string,
}

export interface IPlayFeature {
  id: number;
  name: string;
}

export interface IPlayVideo {
  id: number;
  name: string;
  duration: number;
  watched_seconds: number;
  type_id: number;
  img_cover_url: string;
  url: string;
  subtitles_url: string;
}

export interface IPlayVideoByType {
  play: IPlayVideo,
  trailer: IPlayVideo,
  extras: IPlayVideo[],
}

export interface IPlayImage {
  type_id: number;
  url: string;
}

export interface IPlaySeo {
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
}

/* -------------------- */

export interface IPlay {
  id: number;
  parsed: boolean;
  title: string;
  slug: string;
  author: string;
  summary: string;
  description: string;
  categories: IPlayCategory[];
  categories_as_string: string;
  country: IPlayCountry;
  duration: number;
  watched_seconds: number;
  watched_percentage?: number;
  year: number;
  in_my_list: boolean;
  in_my_favorites: boolean;
  members: IPlayMember[];
  members_by_role: IPlayMembersByRole;
  members_by_role_as_string: IPlayMembersByRoleAsString;
  features: IPlayFeature[];
  videos: IPlayVideo[];
  videos_by_type: IPlayVideoByType;
  images: IPlayImage[];
  is_fullhd: boolean;
  has_subtitles: boolean;
  subtitles_url: string;
  is_premiere: boolean;
  is_subtitled: boolean;
  is_from_broadway: boolean;
  is_from_mexico: boolean;
  is_from_espana: boolean;
  is_recovered_play: boolean;
  is_atp: boolean;
  is_plus_13: boolean;
  is_plus_16: boolean;
  is_plus_18: boolean;
  img_box_url: string;
  img_box_vertical_url: string;
  img_box_square_url: string;
  img_hero_url: string;
  img_hero_mobile_url: string;
  img_video_cover_url: string;
  is_active: boolean;
  is_free_content: boolean;
  has_view_date_range: boolean;
  available_from?: Date;
  available_to?: Date;
  ppv?: IPlayPpv;
  seo?: IPlaySeo;
}

export class Play implements IPlay {

  id: number;
  parsed: boolean;
  title: string;
  slug: string;
  author: string;
  summary: string;
  description: string;
  categories: IPlayCategory[];
  categories_as_string: string;
  country: IPlayCountry;
  duration: number;
  watched_seconds: number;
  watched_percentage?: number;
  year: number;
  in_my_list: boolean;
  in_my_favorites: boolean;
  members: IPlayMember[];
  members_by_role: IPlayMembersByRole;
  members_by_role_as_string: IPlayMembersByRoleAsString;
  features: IPlayFeature[];
  videos: IPlayVideo[];
  videos_by_type: IPlayVideoByType;
  images: IPlayImage[];
  is_fullhd: boolean;
  has_subtitles: boolean;
  subtitles_url: string;
  is_premiere: boolean;
  is_original: boolean;
  is_subtitled: boolean;
  is_from_broadway: boolean;
  is_from_mexico: boolean;
  is_from_espana: boolean;
  is_recovered_play: boolean;
  is_atp: boolean;
  is_plus_13: boolean;
  is_plus_16: boolean;
  is_plus_18: boolean;
  img_box_url: string;
  img_box_vertical_url: string;
  img_box_square_url: string;
  img_hero_url: string;
  img_hero_mobile_url: string;
  img_video_cover_url: string;
  is_active: boolean;
  is_free_content: boolean;
  has_view_date_range: boolean;
  available_from?: Date;
  available_to?: Date;
  ppv?: IPlayPpv;
  seo?: IPlaySeo;

  /* -------------------- */

  constructor(value: any) {
    this.set(value);
  }

  /* -------------------- */

  set(value: any): void {
    Object.assign(this, value);
    if (this.parsed) return;

    value?.play_id ? this.id = value?.play_id : null;
    value?.play_name ? this.title = value?.play_name : null;
    value?.copete ? this.summary = value?.copete : null;
    value?.play_seconds ? this.duration = value?.play_seconds : null;
    value?.seconds_watched ? this.watched_seconds = value?.seconds_watched : null;
    value?.subtitles ? this.subtitles_url = value?.subtitles : null;

    this.in_my_list = value?.listed ? true : false;
    this.in_my_favorites = value?.favorited ? true : false;
    this.is_free_content = value?.status === 2 ? true : false;

    this.available_from = value?.start_date ? new Date(value?.start_date) : null;
    this.available_to = value?.end_date ? new Date(value?.end_date) : null;
    this.has_view_date_range = this.available_from && this.available_to ? true : false;

    this.is_active = true;
    this.has_subtitles = !!this.subtitles_url;

    if (this.duration && this.watched_seconds) {
      this.watched_percentage = this.watched_seconds * 100 / this.duration;
    }

    this.slug = value?.url_friendly || slugify(this.title || '', {
      lower: true,
      strict: true,
    });

    if (value?.categories) {
      this.categories_as_string = join(map(this.categories, category => category.name.trim()), ', ');
    }

    if (value?.country && isArray(value?.country)) {
      this.country = value?.country[0];
    }

    if (value?.persons) {
      this.members = [];

      for (const person of value?.persons) {
        const personNew: any = [];

        person.person ? personNew.name = person.person : null;
        person.role_type ? personNew.role_id = person.role_type : null;
        person.role_name ? personNew.role_name = person.role_name : null;

        this.members.push(personNew);
      }

      this.members_by_role = {
        direction: this.members.filter(item => item.role_id === 1),
        authorship: this.members.filter(item => item.role_id === 2),
        cast: this.members.filter(item => item.role_id === 5),
        others: groupBy(this.members.filter(item => ![1,2,5].includes(item.role_id)), 'role_name')
      };

      this.members_by_role_as_string = {
        direction: join(map(this.members_by_role.direction, 'name'), ', '),
        authorship: join(map(this.members_by_role.authorship, 'name'), ', '),
        cast: join(map(this.members_by_role.cast, 'name'), ', '),
      };
    }

    if (value?.features) {
      this.features = [];

      for (const feature of value?.features) {
        const featureNew: any = {};

        feature.id_feature ? featureNew.id = feature.id_feature : null;
        feature.feature_name ? featureNew.name = feature.feature_name : null;

        this.features.push(featureNew);
      }

      this.is_fullhd = this.features.some(feature => feature.id === 1);
      this.is_premiere = this.features.some(feature => feature.id === 3);
      this.is_original = this.features.some(feature => feature.id === 5);
      this.is_from_broadway = this.features.some(feature => feature.id === 6);
      this.is_from_mexico = this.features.some(feature => feature.id === 8);
      this.is_from_espana = this.features.some(feature => feature.id === 9);
      this.is_recovered_play = this.features.some(feature => feature.id === 10);
      this.is_atp = this.features.some(feature => feature.id === 12);
      this.is_plus_13 = this.features.some(feature => feature.id === 13);
      this.is_plus_16 = this.features.some(feature => feature.id === 14);
      this.is_plus_18 = this.features.some(feature => feature.id === 15);

      if (!this.has_subtitles) {
        this.is_subtitled = this.features.some(feature => feature.id === 2);
      }
    }

    if (value?.videos) {
      this.videos = PlayVideoMapper.mapMany(value.videos);

      this.videos_by_type = {
        play: this.videos.find(video => video.type_id === PlayVideoTypeEnum.Play),
        trailer: this.videos.find(video => video.type_id === PlayVideoTypeEnum.Trailer),
        extras: this.videos.filter(item => [PlayVideoTypeEnum.Trailer, PlayVideoTypeEnum.Extra].includes(item.type_id)),
      };
    }

    if (value?.ppv && value?.ppv_detail) {
      this.ppv = {
        primary_color: '',
        img_title_url: '',
        img_banner_url: '',
        price: value.ppv_detail.amount,
        currency_symbol: value.ppv_detail.currency_symbol || '$',
        discount: value.ppv_detail.discount,
        duration: value.ppv_detail.duration,
        can_buy: false,
        can_redeem_code: false,
        bought: value.ppv_detail.bought,
        starts_at: new Date(value.ppv_detail.begin),
        ends_at: new Date(value.ppv_detail.end),
      }

      // this.ppv.starts_at = new Date('2021-10-25T00:00:00.000000Z');
      // this.ppv.ends_at = new Date('2021-10-28T00:00:00.000000Z');

      const now = new Date();

      if (now > this.ppv.ends_at) {
        this.is_active = false;
      }

      if (!this.ppv.bought && this.is_active) {
        switch (value.ppv_detail.buttons_id) {
          case 1: this.ppv.can_buy = true; this.ppv.can_redeem_code = true; break;
          case 2: this.ppv.can_buy = true; this.ppv.can_redeem_code = false; break;
          case 3: this.ppv.can_buy = false; this.ppv.can_redeem_code = true; break;

          default: break;
        }

        // this.ppv.can_buy = true;
        // this.ppv.can_redeem_code = true;
      }
    } else {
      this.ppv = null;
    }

    if (value?.images) {
      this.images = [];

      for (const image of value?.images) {
        const imageNew: any = {};

        image.type ? imageNew.type_id = image.type : null;
        image.url ? imageNew.url = image.url : null;

        if (browserSupports.webp) {
          imageNew.url = imageNew.url.replace('images/resize', 'imageswp/resize');
        }

        this.images.push(imageNew);
      }

      this.img_box_url = this.images.find(image => [1,9].includes(image.type_id))?.url;
      this.img_box_vertical_url = this.images.find(image => image.type_id === 10)?.url;
      this.img_box_square_url = this.images.find(image => image.type_id === 16)?.url;
      this.img_hero_url = this.getImgUrlSize(this.images.find(image => image.type_id === 4)?.url, { h: 1080 });
      this.img_hero_mobile_url = this.images.find(image => image.type_id === 14)?.url;
      this.img_video_cover_url = this.images.find(image => image.type_id === 11)?.url;

      !this.img_box_vertical_url ? this.img_box_vertical_url = this.img_box_url : null;
      !this.img_box_square_url ? this.img_box_square_url = this.img_box_url : null;
      !this.img_hero_mobile_url ? this.img_hero_mobile_url = this.img_hero_url : null;
    }

    this.parsed = true;
  }

  /* -------------------- */

  isPpv(): boolean {
    return !!this.ppv;
  }

  hasMainVideo(): boolean {
    return !!this.videos_by_type?.play;
  }

  ppvCanPlay(): boolean {
    const now = new Date();
    return now >= this.ppv.starts_at;
  }

  canPlay(): boolean {
    if (this.has_view_date_range) {
      const now = new Date();
      return now >= this.available_from && now <= this.available_to;
    }

    return true;
  }

  /* -------------------- */

  getImgUrlSize(url: string, size: string | { w?: number, h?: number }): string {
    const regex = /resize\/(.*?)\//;
    const match = url?.match(regex);

    if (!match) {
      return url;
    }

    let ratio: string | string[] = match[1] || null;

    if (ratio && typeof size === 'object') {
      ratio = ratio.split('x');
      let width: number = size.w;
      let height: number = size.h;

      if (!width || !height) {
        if (width) height = parseInt(ratio[1]) / parseInt(ratio[0]) * width;
        if (height) width = parseInt(ratio[0]) / parseInt(ratio[1]) * height;
      }

      size = `${width}x${height}`;
    }

    return url?.replace(regex, `resize/${size}/`);
  }
}
