import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormComponent } from './form.component';
import { RequestLoadingModule } from '../request-loading/request-loading.module';
import { RequestErrorModule } from '../request-error/request-error.module';



@NgModule({
  declarations: [FormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RequestLoadingModule,
    RequestErrorModule
  ],
  exports: [FormComponent]
})
export class FormModule { }
