import { Component, Input, OnInit } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';

@Component({
  selector: 'app-play-box-cover',
  templateUrl: './play-box-cover.component.html',
  styleUrls: ['./play-box-cover.component.scss']
})
export class PlayBoxCoverComponent implements OnInit {

  @Input() play: Play;
  @Input() loading: boolean = false;
  @Input() type: 'default' | 'vertical' = 'default';

  constructor() { }

  ngOnInit(): void {
  }

}
