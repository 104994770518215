import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthUserResetPasswordHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
  ) { }

  /* -------------------- */

  /* -------------------- */

  sendEmail(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/forgot-password`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
      // headers: this._httpS.getHeaders({ token: null }),
    }), tokenType);
  }

  updatePassword(hash: string, input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/users/recovery-password/${hash}`), input, {
      // headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
      headers: this._httpS.getHeaders({ token: null }),
    }), tokenType);
  }
}
