import { version } from "version.js";

export const common = {
  production: false,
  debug: true,
  useHash: false,
  url: '',
  apiUrl: '',
  apiSlug: '/api',
  apiMock: false,
  helpCenterContentUrl: '/assets/help-center-content.json',
  colecctionsCategoryName: 'Modulo-Colecciones',
  // formTheme: ['magic-label', 'one-line'],
  // formTheme: ['magic-label'],
  formTheme: ['one-line'],
  // formTheme: [],
  version: `${version}`,
  storage: '6',
};
