<ng-container *ngIf="name" >
    <ng-container *ngIf="!hasLoadingBtn">
        <img class="icon-img" [src]="path"
            [style.width]="size + unit"
            [style.height]="size + unit"
            alt="icono"
        >
    </ng-container>

    <ng-container *ngIf="hasLoadingBtn">
        <span class="icon-img _bg-contain" [style.background-image]="'url(\'' + path + '\')'"
            [style.width]="(width ? width : size) + unit"
            [style.height]="(height ? height : size) + unit"
        ></span>
    </ng-container>

    <!-- <span [ngClass]="'teatrix-icon-' + name"
        [style.font-size]="size + unit"
    ></span> -->

    <span class="content">
        <ng-content></ng-content>
    </span>
</ng-container>
