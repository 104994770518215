import { Component, OnInit, Input } from '@angular/core';
import { AbstractFormFieldComponent } from '../../form-fields/abstract-form-field.component';

@Component({
  selector: 'app-form-disabled',
  templateUrl: './form-disabled.component.html',
  styleUrls: ['./form-disabled.component.scss']
})
export class FormDisabledComponent implements OnInit {

  @Input() formFieldC: AbstractFormFieldComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
