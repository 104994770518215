/**
 * PackageForms@version 0.6.0
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormModule } from './components/form/form.module';
import { FormElementsModule } from './components/form-elements/form-elements.module';
import { FormFieldsModule } from './components/form-fields/form-fields.module';
import { DirectivesModule } from 'src/app/directives/directives.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    ReactiveFormsModule,
    DirectivesModule,
    FormModule,
    FormElementsModule,
    FormFieldsModule,
  ]
})
export class FormsModule { }
