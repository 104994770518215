<button type="button" class="d-md-none btn-close btn-close-dark" aria-label="Close" (click)="close('Cross click')"></button>
<button type="button" class="d-none d-md-flex btn-close btn-close-light" aria-label="Close" (click)="close('Cross click')"></button>

<div class="grid modal-body p-0 text-dark" tabindex="0" ngbAutoFocus>
    <div class="row g-0">
        <div class="subscribe col-60 col-md-40 d-flex flex-column bg-white _py-4 _px-2">
            <h3 class="_fs-20 mb-5 title-line-bottom-red"><strong class="fw-bolder">¡Suscribite a nuestro newsletter!</strong></h3>

            <ng-container *ngIf="step === 1">
                <app-form [form]="form">
                    <div class="pb-4">
                        <div class="row">
                            <app-form-input class="col-60 mb-3" type="name" label="Nombre y apellido" controlName="name"></app-form-input>
                            <app-form-input class="col-60 mb-3" type="email" label="Email" controlName="email"></app-form-input>
                        </div>
                    </div>

                    <div class="d-md-flex align-items-center mt-auto">
                        <app-button-form [request]="form.request" (click)="save()">
                            Aceptar
                        </app-button-form>

                        <a class="d-block d-md-inline link-gray-600 lh-md mt-3 mt-md-0 ms-md-3 _fs-14" (click)="close()" (keyup.enter)="modal.dismiss()" tabindex="0">Cancelar</a>
                    </div>
                </app-form>
            </ng-container>

            <ng-container *ngIf="step === 2">
                <div class="_fs-16">
                    <p class="">¡Te suscribiste al newsletter de forma exitosa!</p>
                </div>
            </ng-container>
        </div>

        <div class="info col-60 col-md-20 d-flex flex-column bg-app-red text-white _py-4 _px-2 mt-md-0 mt-auto">
            <div class="pb-md-4">
                <h3 class="_fs-20 mb-md-5 title-line-bottom-dark"><strong class="fw-bolder">¡No te pierdas todas nuestras novedades!</strong></h3>

                <div class="_fs-14">
                    <p>Suscribite a nuestro servicio de newsletter para enterarte de todas las novedades de TEATRIX antes que nadie.</p>
                </div>
            </div>
        </div>
    </div>
</div>
