import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { AuthLoginModalComponent } from './auth-login-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginModalService {

  constructor(
    private _modalS: ModalService,
  ) { }

  /* -------------------- */

  open(loginType?: string): void {
    if (!this._modalS.storeS.authUser.value?.isLoggedIn()) {
      this._modalS.beforeOpen('AuthLoginModal');

      const modalRef = this._modalS.ngbModal.open(AuthLoginModalComponent, {
        windowClass: 'auth-login-modal-window fullscreen-md',
        centered: true,
        size: 'xl',
      });

      if (loginType) {
        modalRef.componentInstance.loginType = loginType;
      }

      this._modalS.navOnDismiss(modalRef, 'HomePage');
    }
  }
}
