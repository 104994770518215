import { Component, Input, OnInit } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';

@Component({
  selector: 'app-play-title',
  templateUrl: './play-title.component.html',
  styleUrls: ['./play-title.component.scss']
})
export class PlayTitleComponent implements OnInit {

  @Input() play: Play;
  @Input() withSubtitle = false;
  @Input() withFeatures = false;
  @Input() size: 'md' | 'lg' = 'md';

  constructor() { }

  ngOnInit(): void {
  }

}
