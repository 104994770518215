import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { info } from 'src/app/info';
import { NavService } from 'src/app/services/nav.service';
import { RouteService } from 'src/app/services/route.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-subscribe-bar',
  templateUrl: './subscribe-bar.component.html',
  styleUrls: ['./subscribe-bar.component.scss']
})
export class SubscribeBarComponent implements OnInit, OnDestroy {

  isPromoPage: boolean = false;
  isPromoInterPage: boolean = false;

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    public storeS: StoreService,
    public navS: NavService,
    private _routeS: RouteService,
  ) { }

  ngOnInit(): void {
    this._sm.add(this._routeS.onNavigationEnd$().subscribe((event: NavigationEnd) => {
      this.isPromoPage = event.url === '/promo' ? true : false;
      this.isPromoInterPage = event.url === '/promo-inter' ? true : false;
    }));
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  get info() {
    return info;
  }

  /* -------------------- */

  mustShow(): boolean {
    if (
      [
        'ActivationPage',
        'SubscribePage',
      ].includes(this._routeS.currentPage.value.name)) {
      return false;
    }

    return !this.storeS.authUser.value?.isLoggedIn()
      || this.storeS.authUser.value.user.status.is_active_unsubscribed
      || this.storeS.authUser.value.user.status.is_pending
      || this.storeS.authUser.value.user.status.is_cancel
      || this.storeS.authUser.value.user.status.is_no_plan
      || this.storeS.authUser.value.user.status.is_free
  }
}
