import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appDecimals'
})
export class DecimalsPipe implements PipeTransform {

  transform(value: number): any {
    let values = String(value.toFixed(2)).split('.');

    if (values.length <= 1) {
      return;
    }

    if (values[1] === '00') {
      return;
    }

    return values[1].slice(0, 2);
  }

}
