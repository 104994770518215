export enum UserStatusEnum {
  Active = 'ACTIVO',
  Cancel = 'CANCELADO',
  NoPlan = 'SIN PLAN',
  Admin = 'ADMIN',
  Pending = 'PENDIENTE',
  Processing = 'EN PROCESO',
  Free = 'FREE',
  Courtesy = 'CORTESIA',
  ActiveUnsubscribed = 'ACTIVO CON BAJA'
}
