export interface IHomeBanner {
  readonly id: number;
  img_url_desktop: string;
  img_url_mobile: string;
  epigraph: {
    text: string,
    color: string
  };
  title: {
    text: string,
    color: string,
    background_color: string,
    img_url: string,
  };
  subtitle: {
    text: string,
    color: string
  };
  description: {
    text: string,
    color: string
  };
  clarification: {
    text: string
    color: string
  };
  button_main: {
    href: string
  };
  button_info: {
    href: string
  };
}

export class HomeBanner implements IHomeBanner {

  readonly id: number;
  img_url_desktop: string;
  img_url_mobile: string;
  epigraph: {
    text: string,
    color: string
  };
  title: {
    text: string,
    color: string,
    background_color: string,
    img_url: string,
  };
  subtitle: {
    text: string,
    color: string
  };
  description: {
    text: string,
    color: string
  };
  clarification: {
    text: string
    color: string
  };
  button_main: {
    href: string,
    text: string
  };
  button_info: {
    href: string
  };

  /* -------------------- */

  constructor(value: any) {
    Object.assign(this, value);

    value?.image_desktop ? this.img_url_desktop = value?.image_desktop : null;
    value?.image_mobile ? this.img_url_mobile = value?.image_mobile : null;

    !this.img_url_mobile ? this.img_url_mobile = this.img_url_desktop : null;

    if (value?.epigraph) {
      this.epigraph.text = value.epigraph.title;
    }

    if (value?.title) {
      this.title.text = value.title.title;
      this.title.background_color = value.title.background;
      this.title.img_url = value.title.title_image;
    }

    if (value?.subtitle) {
      this.subtitle.text = value.subtitle.title;
    }

    if (value?.description) {
      this.description.text = value.description.title;
    }

    if (value?.clarification) {
      this.clarification.text = value.clarification.title;
    }

    if (value?.main_button) {
      this.button_main = {
        href: value.main_button.link,
        text: value.main_button.title,
      };
    }

    if (value?.info_button) {
      this.button_info = {
        href: value.info_button.link,
      };
    }
  }
}
