import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';


@Component({
  selector: 'app-email-verification-modal',
  templateUrl: './email-verification-modal.component.html',
  styleUrls: ['./email-verification-modal.component.scss']
})
export class EmailVerificationModalComponent implements OnInit {

  constructor(
    public modal: NgbActiveModal,
    public storeS: StoreService,
    public navS: NavService,
  ) { }

  ngOnInit(): void {
    //
  }

}
