import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], field: string, operation: string, value: any): any {
    return items.filter((item: any) => {
      switch (operation) {
        case '=': {
          return item[field] === value
        }

        case '!=': {
          return item[field] !== value
        }

        case '<': {
          return item[field] < value
        }

        case '<=': {
          return item[field] <= value
        }

        case '>': {
          return item[field] > value
        }

        case '>=': {
          return item[field] >= value
        }

        default: return item;
      }
    });
  }

}
