import { Component, OnInit, Input } from '@angular/core';
import { Request } from 'src/app/commons/request';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-request-error',
  templateUrl: './request-error.component.html',
  styleUrls: ['./request-error.component.scss']
})
export class RequestErrorComponent implements OnInit {

  @Input() request: Request;
  @Input() class: string;

  showMore: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  get e(): any {
    return environment;
  }

  get _(): any {
    return _;
  }

}
