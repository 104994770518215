<ng-template #benefitCodeTpl let-label>
    <app-form-input #benefitCode class="col mb-2" [label]="label" controlName="benefit_Code.code" [formGroup]="formSubscribe.group"></app-form-input>

    <div class="col-auto ps-0 mb-2 align-self-end text-end">
        <app-form-status class=""
            [loading]="parent.discountRequest.isLoading()"
            [error]="!parent.discountRequest.isLoading() && !!benefitCode.control.value && !isCodeValid(benefitCode.control)"
            [success]="!parent.discountRequest.isLoading() && isCodeValid(benefitCode.control)"
        ></app-form-status>
    </div>

    <p class="mb-3" *ngIf="parent.discountDuration" class="_fs-12 text-gray-500"><em>* Descuento válido durante {{ parent.discountDuration }} días</em></p>
</ng-template>

<div class="grid">
    <div>
        <ng-container *ngIf="!parent.isPpv">
            <h3 class="_fs-36 mb-0"><strong class="fw-bolderest">Seleccioná tu plan</strong></h3>
            <p class="ff-opensans-r">Elegí el plan que mejor se ajuste a tus necesidades.</p>
        </ng-container>

        <ng-container *ngIf="parent.isPpv">
            <h3 class="_fs-36 mb-0"><strong class="fw-bolderest">Pay Per View</strong></h3>
            <ng-container *ngIf="parent.canPlayPpv">
                <p class="ff-opensans-b text-app-red">Disponible hasta el {{ parent.play.ppv.ends_at | date:'longDate' }} a las {{ parent.play.ppv.ends_at | date:'H:mm' }} hs</p>
            </ng-container>

            <ng-container *ngIf="!parent.canPlayPpv">
                <p class="ff-opensans-b text-app-red mb-0">Se estrena el {{ parent.play.ppv.starts_at | date:'longDate' }} a las {{ parent.play.ppv.starts_at | date:'H:mm' }} hs</p>
                <p class="ff-opensans-b text-gray-600">Disponible por {{ parent.play.ppv.duration | appDays }}</p>
            </ng-container>
        </ng-container>

        <app-form [ngClass]="parent.formClass" [form]="formSubscribe" (submitted$)="continue()">
            <div class="pb-4">
                <ng-container *ngIf="!parent.ppvCode">
                    <div class="row">
                        <ng-container *ngIf="!formSubscribe.options.get('plan').length">
                            <app-request-handler [request]="parent.planL.request"></app-request-handler>
                        </ng-container>

                        <ng-container *ngIf="formSubscribe.options.get('plan').length">
                            <ng-template #planTpl let-plan>
                                <div class="col-60 mb-1">
                                    <app-form-checkbox controlName="plan"
                                        [value]="plan"
                                        [class.active]="plan.plan_id === formSubscribe.group.get('plan').value?.plan_id"
                                        [disabled]="
                                            (paymentMethod.control.value?.id === 2 && !plan.offline && !plan.prepagos)
                                            || (paymentMethod.control.value?.id === 1 && !plan.online && !plan.prepagos)
                                        "
                                    >
                                        <div class="d-flex align-items-center">
                                            <span class="lh-sm me-3">
                                                <span [innerHTML]="plan.name"></span>
                                                <ng-container *ngIf="plan.discount && parent.isPpv && !parent.hasSubscription">
                                                    <br>
                                                    <span class="small">({{ plan.discount }}% OFF para suscriptores)</span>
                                                </ng-container>

                                                <ng-container *ngIf="parent.discount">
                                                    <br>
                                                    <strong class="description small">
                                                        <ng-container *ngIf="!parent.discountPlan && !plan.allow_discount">
                                                            <span class="text-gray-500">{{ plan.not_allow_discount_description }}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="parent.discountPlan || plan.allow_discount">{{ parent.discountDescription }}</ng-container>
                                                    </strong>
                                                </ng-container>

                                                <ng-container *ngIf="!parent.discount">
                                                    <br>
                                                    <strong class="description small">
                                                        <ng-container>{{ plan.description | appHtmlStrip }}</ng-container>
                                                    </strong>
                                                </ng-container>
                                            </span>

                                            <span class="plan-amount ms-auto">
                                                <ng-container *ngIf="plan.discount || parent.discount || plan.discount_trial">
                                                    <ng-container *ngIf="plan.allow_discount">
                                                        <span *ngIf="!parent.isPpv" class="_fs-12">(-{{ parent.discount || plan.discount || plan.discount_trial }}%)</span>
                                                        <span *ngIf="parent.isPpv && parent.hasSubscription" class="_fs-12">(-{{ parent.discount || plan.discount || plan.discount_trial}}%)</span>
                                                    </ng-container>

                                                    <ng-container *ngIf="!plan.allow_discount && plan.discount_trial">
                                                        <span *ngIf="!parent.isPpv" class="_fs-12">(-{{ plan.discount_trial }}%)</span>
                                                        <span *ngIf="parent.isPpv && parent.hasSubscription" class="_fs-12">(-{{ plan.discount_trial }}%)</span>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="(!parent.discount || !plan.allow_discount) && !plan.discount_trial">
                                                    <strong class="fw-bolder _fs-18 ms-2">
                                                        <ng-container *ngIf="!plan.amount">GRATIS</ng-container>
                                                        <ng-container *ngIf="plan.amount">{{ plan.currency_symbol }} {{ plan.amount | number:'1.2-2' }}</ng-container>
                                                    </strong>
                                                </ng-container>

                                                <ng-container *ngIf="(parent.discount && plan.allow_discount) || plan.discount_trial">
                                                    <strong class="fw-bolder text-decoration-line-through _fs-18 ms-2">
                                                        <ng-container *ngIf="!plan.amount">GRATIS</ng-container>
                                                        <ng-container *ngIf="plan.amount">{{ plan.currency_symbol }} {{ plan.amount | number:'1.2-2' }}</ng-container>
                                                    </strong>

                                                    <strong class="fw-bolder _fs-18 ms-2">
                                                        <ng-container *ngIf="!parent.getPlanAmountWithDiscount(plan)">GRATIS</ng-container>
                                                        <ng-container *ngIf="parent.getPlanAmountWithDiscount(plan)">{{ plan.currency_symbol }} {{ parent.getPlanAmountWithDiscount(plan) | number:'1.2-2' }}</ng-container>
                                                    </strong>
                                                </ng-container>
                                            </span>
                                        </div>
                                    </app-form-checkbox>
                                </div>
                            </ng-template>

                            <ng-container *ngIf="parent.discountPlan">
                                <ng-container [ngTemplateOutlet]="planTpl" [ngTemplateOutletContext]="{ $implicit: parent.discountPlan }"></ng-container>
                            </ng-container>

                            <ng-container *ngIf="!parent.discountPlan">
                                <ng-container *ngFor="let plan of formSubscribe.options.get('plan')">
                                    <ng-container [ngTemplateOutlet]="planTpl" [ngTemplateOutletContext]="{ $implicit: plan }"></ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <app-form-select [hidden]="formSubscribe.group.get('plan').value?.prepagos" #paymentMethod class="col-60 mb-3" label="Método de pago" controlName="payment_method" [optionsRequired]="true"></app-form-select>
                        <app-form-select [hidden]="parent.isPpv && !parent.ppvAllowDiscount" #benefit class="col-60 mb-3" label="¿Tenés un código de descuento o beneficio?" controlName="benefit"></app-form-select>
                    </div>

                    <!-- 365 -->
                    <div class="row g-0">
                        <ng-container *ngIf="benefit.control.value?.id === 1">
                            <app-form-input #benefit365CardNumber mask="0000000000000000000" class="col mb-3" label="Número de tarjeta" controlName="benefit_365.card_number"></app-form-input>

                            <div class="col-auto ps-0 mb-3 align-self-end text-end">
                                <app-form-status class=""
                                    [loading]="parent.discountRequest.isLoading()"
                                    [error]="!parent.discountRequest.isLoading() && !!benefit365CardNumber.control.value && !parent.discount"
                                    [success]="!parent.discountRequest.isLoading() && !!benefit365CardNumber.control.value && !!parent.discount"
                                ></app-form-status>
                            </div>

                            <p class="mb-3" *ngIf="parent.discountDuration" class="_fs-12 text-gray-500"><em>* Descuento válido durante {{ parent.discountDuration }} días</em></p>
                        </ng-container>
                    </div>

                    <!-- La Nación -->
                    <div class="row g-0">
                        <ng-container *ngIf="benefit.control.value?.id === 2">
                            <app-form-input #benefitLaNacionCardNumber mask="0000000000000000" class="col mb-3" label="Número de tarjeta" controlName="benefit_LaNacion.card_number"></app-form-input>
                           <!--<app-form-input #benefitLaNacionDni class="col mb-3" label="DNI" controlName="benefit_LaNacion.dni"></app-form-input>-->

                            <div class="col-auto ps-0 mb-3 align-self-end text-end">
                                <app-form-status class=""
                                    [loading]="parent.discountRequest.isLoading()"
                                    [error]="!parent.discountRequest.isLoading() && !!benefitLaNacionCardNumber.control.value && !parent.discount"
                                    [success]="!parent.discountRequest.isLoading() && !!benefitLaNacionCardNumber.control.value && !!parent.discount"
                                ></app-form-status>
                            </div>

                            <p class="mb-3" *ngIf="parent.discountDuration" class="_fs-12 text-gray-500"><em>* Descuento válido durante {{ parent.discountDuration }} días</em></p>
                        </ng-container>
                    </div>

                    <!-- El País -->
                    <div class="row g-0">
                        <ng-container *ngIf="benefit.control.value?.id === 4">
                            <app-form-input #benefitElPaisCardNumber mask="0000000000000000" class="col mb-3" label="Número de tarjeta" controlName="benefit_ElPais.card_number"></app-form-input>

                            <div class="col-auto ps-0 mb-3 align-self-end text-end">
                                <app-form-status class=""
                                    [loading]="parent.discountRequest.isLoading()"
                                    [error]="!parent.discountRequest.isLoading() && !!benefitElPaisCardNumber.control.value && !parent.discount"
                                    [success]="!parent.discountRequest.isLoading() && !!benefitElPaisCardNumber.control.value && !!parent.discount"
                                ></app-form-status>
                            </div>

                            <p class="mb-3" *ngIf="parent.discountDuration" class="_fs-12 text-gray-500"><em>* Descuento válido durante {{ parent.discountDuration }} días</em></p>
                        </ng-container>
                    </div>

                    <!-- El Comercio -->
                    <div class="row g-0">
                        <ng-container *ngIf="benefit.control.value?.id === 5">
                            <app-form-input #benefitElComercioCardNumber mask="0000000000000" class="col mb-3" label="Número de cédula o tarjeta" controlName="benefit_ElComercio.card_number"></app-form-input>

                            <div class="col-auto ps-0 mb-3 align-self-end text-end">
                                <app-form-status class=""
                                    [loading]="parent.discountRequest.isLoading()"
                                    [error]="!parent.discountRequest.isLoading() && !!benefitElComercioCardNumber.control.value && !parent.discount"
                                    [success]="!parent.discountRequest.isLoading() && !!benefitElComercioCardNumber.control.value && !!parent.discount"
                                ></app-form-status>
                            </div>

                            <p class="mb-3" *ngIf="parent.discountDuration" class="_fs-12 text-gray-500"><em>* Descuento válido durante {{ parent.discountDuration }} días</em></p>
                        </ng-container>
                    </div>

                    <!-- Cupón de descuento -->
                    <div class="row g-0">
                        <ng-container *ngIf="benefit.control.value?.id === 3">
                            <ng-container
                                [ngTemplateOutlet]="benefitCodeTpl"
                                [ngTemplateOutletContext]="{ $implicit: 'Código de cupón' }
                            "></ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="parent.ppvCode">
                    <div class="row">
                        <p class="lead">Obra PPV: <strong class="fw-bolder">{{ parent.play.title }}</strong></p>

                        <!-- Código de compra PPV -->
                        <ng-container
                            [ngTemplateOutlet]="benefitCodeTpl"
                            [ngTemplateOutletContext]="{ $implicit: 'Código de compra' }"
                        ></ng-container>
                    </div>
                </ng-container>
            </div>
        </app-form>
    </div>

    <div class="mt-auto">
        <p class="mb-3" class="_fs-12 text-gray-600"><em>* Todos los impuestos están incluidos.</em></p>

        <div class="d-block d-md-flex align-items-center">
            <ng-container *ngIf="!parent.storeS.authUser.value?.isLoggedIn()">
                <app-button-form class="d-none d-md-inline-block me-2" color="btn-gray-400" (click)="parent.step = 1">
                    Atrás
                </app-button-form>
            </ng-container>

            <div>
                <app-button-form [request]="formSubscribe.request" [disabled]="!formSubscribe.ready || (parent.ppvCode && !isCodeValid(formSubscribe.group.get('benefit_Code.code')))" (click)="continue()">
                    Continuar
                </app-button-form>
            </div>

            <a class="d-block d-md-none link-gray-600 lh-md mt-3 _fs-14 text-decoration-none" (click)="parent.step = 1" (keyup.enter)="parent.step = 1" tabindex="0">
                <div class="d-flex align-items-center">
                    <i class="fa fa-angle-double-left me-1"></i>paso anterior
                </div>
            </a>
        </div>
    </div>
</div>
