import { Injectable } from '@angular/core';
import { SubscriptionManager } from '../commons/subscription-manager';
import { NewsletterModalService } from '../components/modals/newsletter-modal/newsletter-modal.service';
import { ModalService } from './modal.service';
import { RouteService } from './route.service';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private _openModalTimeout: any;

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    private _newsletterModalS: NewsletterModalService,
    private _storeS: StoreService,
    private _modalS: ModalService,
    private _routeS: RouteService,
  ) { }

  ngOnDestroy(): void {
    clearTimeout(this._openModalTimeout);
    this._sm.clean();
  }

  get canOpenModal(): boolean {
    return !this._storeS.authUser.value?.isLoggedIn()
      && !this._storeS.newsletter.value
      && !this._modalS.hasOpenModals()
  }

  /* -------------------- */

  init() {
    this._sm.add(this._routeS.onNavigationEnd$().subscribe(() => {
      if (this._routeS.beforePage.value?.name === 'SubscribePage' && this._routeS.currentPage.value !== 'SubscribePage' && this._storeS.subscribeStep.value === 1 && this.canOpenModal) {
        this._openModalTimeout = setTimeout(() => {
          this._newsletterModalS.open();
        }, 500);
      }
    }));

    this._openModalTimeout = setTimeout(() => {
      if (this.canOpenModal && ![
        'SubscribePage',
        'GiftCardBuyPage',
        'GiftCardRedeemPage',
        'AuthPage',
      ].includes(this._routeS.currentPage.value.name)) {
        this._newsletterModalS.open();
      }
    }, 3000 * 60);
  }
}
