import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormInputComponent } from './form-input.component';
import { FormElementsModule } from '../../form-elements/form-elements.module';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [FormInputComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormElementsModule,
    NgxMaskModule,
  ],
  exports: [FormInputComponent],
})
export class FormInputModule { }
