import * as _ from 'lodash';

export const helper = {

  lastId: 0,

  getNewId(): number {
    return ++this.lastId;
  },

  flattenKeys(object: any, path: string[] = []) {
    return !_.isObject(object)
      ? { [path.join('.')]: object }
      : _.reduce(object, (cum, next, key) => _.merge(cum, helper.flattenKeys(next, [...path, key])), {});
  },
};


