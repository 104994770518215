<div class="grid">
    <div>
        <h3 class="_fs-36 mb-0"><strong class="fw-bolderest">Pago</strong></h3>
        <p class="d-none d-md-inline-block ff-opensans-r">Completá los datos para terminar el proceso de pago</p>
        <p class="d-md-none ff-opensans-r">Completá los datos para terminar</p>

        <app-form [ngClass]="parent.formClass" *ngIf="parent.isPaymentMethodOnline" [form]="formCheckout" (submitted$)="checkout()">
            <div class="pb-4">
                <ngb-alert *ngIf="formCheckout.request.error?.data?.scalar" class="mb-3" type="danger" [dismissible]="false">
                    <h6 class="_fs-18"><strong>No se pudo completar la transacción</strong></h6>
                    <div class="_fs-14">{{ formCheckout.request.error.data.scalar }}</div>
                </ngb-alert>

                <ngb-alert *ngIf="!formCheckout.request.error?.data?.scalar && stripeError" class="mb-3" type="danger" [dismissible]="false">
                    <div class="_fs-14">{{ stripeError }}</div>
                </ngb-alert>

                <div class="row g-0">
                    <app-form-input class="col mb-3" [mask]="formCheckout.group.get('card_type').value?.maskCardNumber || '0000 0000 0000 0000'" [maskValidation]="true" label="Número de tu tarjeta" controlName="card_number" errorName="creditCardNumber"></app-form-input>

                    <div class="col-auto mb-3 align-self-end text-end">
                        <ng-container *ngIf="formCheckout.group.get('card_number').value">
                            <div class="border border-gray-400 rounded text-uppercase px-2 py-1 ms-2 _fs-10"
                                ngbPopover="Tarjetas permitidas: {{ _.join(_.map(formCheckout.options.get('card_type'), 'name'), ', ') }}."
                                triggers="mouseenter:mouseleave"
                                placement="bottom-right"
                            >
                                <ng-container *ngIf="formCheckout.group.get('card_type').value">
                                    <strong>{{ formCheckout.group.get('card_type').value?.name }}</strong>
                                </ng-container>

                                <ng-container *ngIf="!formCheckout.group.get('card_type').value">
                                    <strong class="text-app-red">No válida</strong>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="row">
                    <div class="col-60 col-sm-30 mb-3">
                        <div class="row">
                            <label class="form-label">Vencimiento (mm/aaaa)</label>
                            <app-form-select class="col-30" controlName="card_expiration_month" [optionsRequired]="true" errorName="creditCardMonthExpiry"></app-form-select>
                            <app-form-select class="col-30" controlName="card_expiration_year" [optionsRequired]="true" errorName="creditCardMonthExpiry"></app-form-select>
                        </div>
                    </div>
                    <app-form-input class="col-60 col-sm-30 mb-3" mask="0009" [maskValidation]="true" label="Código de seguridad" controlName="card_security_code" errorName="creditCardCVC"></app-form-input>
                    <app-form-input class="col-60 mb-3" label="Nombre completo del titular" controlName="card_titular_fullname" errorName="creditCardPersonName"></app-form-input>
                    <app-form-input class="col-60 col-sm-30 mb-3" [maxlength]="11" mask="separator" thousandSeparator="." label="DNI del titular" controlName="card_titular_dni" errorName="dni"></app-form-input>
                </div>
            </div>
        </app-form>

        <app-form [ngClass]="parent.formClass" *ngIf="parent.isPaymentMethodOffline" [form]="formCheckout" (submitted$)="checkout()">
            <div class="pb-4">
                <div class="row">
                    <app-form-select class="col-60 mb-3" label="Canal de pago" controlName="payment_method_offline" [optionsRequired]="true" errorName="paymentMethodName"></app-form-select>
                </div>

                <p class="ff-opensans-r _fs-14">Imprimí el comprobante y acercate a la sucursal correspondiente más cercana, 48 horas después de realizado el pago vas a poder acceder al contenido.</p>
            </div>
        </app-form>
    </div>

    <div class="mt-auto">
        <p>
            Estás pagando:<br>
            <strong class="fw-bolder">
                <span [innerHTML]="plan.name"></span>
                {{ plan.currency_symbol }}{{ parent.getPlanAmountWithDiscount(plan) | number:'1.2-2' }}
            </strong><br>

            <span class="mb-3" *ngIf="parent.discountDuration" class="_fs-12 text-gray-600">
                <em>* Descuento válido durante {{ parent.discountDuration }} días</em>
            </span>
        </p>

        <p class="mb-3" *ngIf="parent.getPlanAmountWithDiscount(plan) === 0" class="_fs-12 text-gray-600"><em>* Podría generarse un cobro mínimo de $5 a $10 para verificación del medio de pago.</em></p>

        <p class="mb-3" *ngIf="!parent.isPaymentMethodOffline && !parent.play?.ppv" class="_fs-12 text-gray-600"><em>* Suscripción recurrente. Podés cancelar tu cuenta en cualquier momento con un solo click.</em></p>

        <div class="d-block d-md-flex align-items-center mt-auto">
            <app-button-form *ngIf="!!stepToGoBack" class="d-none d-md-inline-block me-2" color="btn-gray-400" (click)="parent.step = stepToGoBack">
                Atrás
            </app-button-form>

            <div>
                <app-button-form [request]="formCheckout.request" [disabled]="formCheckout.group.invalid" (click)="checkout()">
                    <ng-container *ngIf="parent.isPaymentMethodOnline">Finalizar registro</ng-container>
                    <ng-container *ngIf="parent.isPaymentMethodOffline">Descargar cupón</ng-container>
                </app-button-form>
            </div>

            <a class="d-block d-md-none link-gray-600 lh-md mt-3 _fs-14 text-decoration-none" (click)="parent.step = stepToGoBack" (keyup.enter)="parent.step = stepToGoBack" tabindex="0">
                <div class="d-flex align-items-center">
                    <i class="fa fa-angle-double-left me-1"></i>paso anterior
                </div>
            </a>
        </div>
    </div>
</div>

