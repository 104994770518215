import { AbstractControl } from '@angular/forms';
import { Form } from './form';
import * as _ from 'lodash';
import { helper } from '../helper';

export class FormOptions {

  constructor(private _form: Form) { }

  /* -------------------- */

  get(name: string): any[] {
    return this._form.get([name, 'options', 'items']) || [];
  }

  getItemChild(name: string): string {
    return this._form.get([name, 'options', 'itemChild']) || null;
  }

  getIndexBy(name: string): string {
    return this._form.get([name, 'options', 'indexBy']) || 'id';
  }

  getAsyncData(name: string): any {
    return this._form.get([name, 'options', 'asyncData']);
  }

  /* -------------------- */

  canSet(name: string): boolean {
    return !!this._form.get([name, 'options', 'items']);
  }

  set(name: string, items: any[], options: { withInit?: boolean, emitEvent?: boolean } = {}): void {
    if (!this.canSet(name)) return;

    this._form.set([name, 'options', 'items'], items);
    this.mapValue(name, options);
  }

  setAsyncData(name: string, value: any): void {
    this._form.set([name, 'options', 'asyncData'], value);
  }

  /* -------------------- */

  resolveControlNames(name: string): string[] {
    const formValueFlattened: any = helper.flattenKeys(this._form.group.value);

    return _.map(_.filter(_.keys(formValueFlattened), (value: string) => {
      const regex: RegExp = new RegExp(name, 'g');
      return value.match(regex);
    }), (value: string) => {
      const regex: RegExp = new RegExp('(' + name + ')(.*)', 'g');
      return value.replace(regex, '$1') + ':' + name;
    });
  }

  mapValue(name: string, options: { withInit?: boolean, emitEvent?: boolean } = {}) {
    if (name.indexOf('*') >= 0 && name.indexOf(':') < 0) {
      const names: string[] = this.resolveControlNames(name);
      _.forEach(names, (name: string) => this.mapValue(name, options));

      return;
    }

    let controlName: string = name;

    if (name.indexOf(':') > 0) {
      const nameSplitted: string[] = name.split(':');

      name = nameSplitted[1];
      controlName = nameSplitted[0];
    }

    if (!this._form.getControl(controlName)) return;

    const control: AbstractControl = this._form.getControl(controlName);
    const indexBy: string = this.getIndexBy(name);
    const asyncData: any[] = this.getAsyncData(name);

    let items: any[];

    !!this.getItemChild(name) ?
      items = _.flatten(_.map(this.get(name), (item: any) => item[this.getItemChild(name)])):
      items = this.get(name);

    if (!_.isEmpty(asyncData) || _.isNumber(asyncData)) {
      this._form.set([name, 'options', 'asyncData'], null);
    }

    if (!_.isEmpty(control.value) || _.isNumber(control.value) || !_.isEmpty(asyncData) || _.isNumber(asyncData)) {
      setTimeout(() => {
        if (_.isArray(control.value)) {
          let values: any[] = asyncData;

          if (!values) {
            _.some(control.value, _.isObject) ?
              values = _.map(control.value, indexBy):
              values = control.value;

            if (!items.length) this.setAsyncData(name, values);
          }

          let valuesNew: any[] = indexBy !== '_' ?
            _.without(values.map(value => items.find(item => item[indexBy] === value)), undefined) || []:
            _.without(values.map(value => items.find(item => item == value)), undefined) || [];

          control.setValue(valuesNew, { emitEvent: options.emitEvent });
        } else {
          let value: any = asyncData;

          if (!value) {
            _.isObject(control.value) ?
              value = control.value[indexBy]:
              value = control.value;

            if (!items.length) this.setAsyncData(name, value);
          }

          let valueNew: any[] = indexBy !== '_' ?
            items.find(item => item[indexBy] === value) || '':
            items.find(item => item == value) || '';

          control.setValue(valueNew, { emitEvent: options.emitEvent });
        }

        this._form.persistControl(controlName, options.withInit);
      });
    }
  }

  mapValues(options: { withInit?: boolean, emitEvent?: boolean } = {}) {
    _.forEach(this._form.getExtras(), (extra: any, name: string) => {
      if (!!extra.options) {
        this._form.options.mapValue(name, options);
      }
    });
  }

  /* -------------------- */

  isLoaded(name: string): boolean {
    if (this.canSet(name) && (this.getAsyncData(name) || !this.get(name).length)) {
      return false;
    }

    return true;
  }

  areLoaded(): boolean {
    let areLoaded: boolean = true;

    _.forEach(this._form.getExtras(), (extra: any, name: string) => {
      if (this.canSet(name) && (this.getAsyncData(name) || !this.get(name).length)) {
        areLoaded = false;
        return;
      }
    });

    return areLoaded;
  }
}
