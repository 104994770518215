<ng-container *ngIf="!_.isUndefined(index)">
    <button class="btn btn-outline-danger rounded-circle ml-2 p-0 _wh-1-25 _f-0-6"
        (click)="formArrayC.formC.form.arrays.remove(formArrayC.controlPath, index, controlArray)"
    >
        <i class="fas fa-minus"></i>
    </button>
</ng-container>

<ng-container *ngIf="_.isUndefined(index) || last">
    <button class="btn btn-outline-primary rounded-circle ml-2 p-0 _wh-1-25 _f-0-6"
        (click)="formArrayC.formC.form.arrays.add(formArrayC.controlPath, 1, controlArray)"
    >
        <i class="fas fa-plus"></i>
    </button>
</ng-container>
