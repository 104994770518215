<div>
    <ng-container *ngIf="type === 'hero'">
        <a class="type-hero btn btn-app-red mb-3 text-uppercase"
            [class.btn-app-green]="storeS.authUser.value?.user.status.is_pending"
            [class.text-white]="storeS.authUser.value?.user.status.is_pending"
            style="max-width: 20rem;"
            [routerLink]="heroHref ? heroHref : navS.pages.SubscribePage.link()"
        >
            <strong *ngIf="!storeS.authUser.value?.user.status.is_pending" class="fw-bolder _fs-16">{{ heroText }}</strong>
            <strong *ngIf="storeS.authUser.value?.user.status.is_pending" lass="fw-bolder _fs-16">Suscripción pendiente</strong>
        </a>

        <p class="ff-opensans-l mb-0" [style.color]="heroSubtitleColor">{{ heroSubtitle }}</p>
    </ng-container>

    <ng-container *ngIf="type === 'heroDetail'">
        <ng-container *ngIf="!storeS.authUser.value?.user.status.is_pending">
            <app-button-play [routerLink]="navS.pages.SubscribePage.link()" size="lg">
                <span *ngIf="play?.hasMainVideo()" class="text-uppercase">Suscribite ahora para reproducir</span>
                <span *ngIf="!play?.hasMainVideo()" class="text-uppercase">Suscribite ahora</span>
            </app-button-play>

            <p class="ff-opensans-r mt-3 mb-0 text-gray-600 _fs-15">
                Por solo {{ info.subscriptionPrice }} por mes y sin límites.<br>
                Cancelás con solo un click.
            </p>
        </ng-container>

        <ng-container *ngIf="storeS.authUser.value?.user.status.is_pending">
            <button class="type-modal btn btn-app-green text-white text-uppercase" [routerLink]="navS.pages.SubscribePage.link()">
                <app-icon onleft name="caret-right-white">
                    <strong class="fw-bolder _fs-14">Suscripción pendiente</strong>
                </app-icon>
            </button>

            <p class="ff-opensans-r mt-3 mb-0 text-gray-600 _fs-15">
                Tu suscripción se encuentra pendiente.<br>
                Podés reimprimir tu cupón o cambiar el método de pago.
            </p>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'modal'">
        <button class="type-modal btn btn-app-red mb-1 text-uppercase" [routerLink]="navS.pages.SubscribePage.link()">
            <app-icon onleft name="caret-right-white"><strong class="fw-bolder _fs-16">Suscribite ahora</strong></app-icon>
        </button>

        <p class="text-gray-600 mb-0 _fs-16"><strong>Por solo {{ info.subscriptionPrice }} por mes y sin límites.</strong></p>
    </ng-container>

    <ng-container *ngIf="type === 'modalMoreInfo'">
        <button class="type-modal btn btn-app-red mb-1 py-1 px-3 text-uppercase"
            [class.btn-app-green]="storeS.authUser.value?.user.status.is_pending"
            [class.text-white]="storeS.authUser.value?.user.status.is_pending"
            [routerLink]="navS.pages.SubscribePage.link()"
        >
            <app-icon onleft name="caret-right-white">
                <strong *ngIf="!storeS.authUser.value?.user.status.is_pending" class="fw-bolder _fs-14">Suscribite ahora</strong>
                <strong *ngIf="storeS.authUser.value?.user.status.is_pending" class="fw-bolder _fs-14">Suscripción pendiente</strong>
            </app-icon>
        </button>

        <app-button-play-more-info class="d-sm-none ms-2" size='sm' [play]="play" type="short"></app-button-play-more-info>
        <app-button-play-more-info class="d-none d-sm-inline-block ms-3" size='sm' [play]="play"></app-button-play-more-info>

        <p class="text-gray-600 mb-0 _fs-16"><strong>Por solo {{ info.subscriptionPrice }} por mes y sin límites.</strong></p>
    </ng-container>

    <ng-container *ngIf="type === 'allInOne'">
        <button class="type-all-in-one btn btn-app-red lh-1" style="max-width: 30rem;" [routerLink]="navS.pages.SubscribePage.link()">
            <strong class="fw-bolder _fs-18 text-uppercase">Suscribite ahora</strong>
            <span class="ff-opensans-l _fs-16 d-block mt-1">{{ info.subscriptionDescription | appHtmlStrip }}</span>
        </button>
    </ng-container>

    <ng-container *ngIf="type === 'onlyButton'">
        <button class="type-only-button btn btn-app-red lh-1" style="max-width: 30rem;" [routerLink]="navS.pages.SubscribePage.link()">
            <strong class="fw-bolder text-uppercase">Suscribite ahora</strong>
        </button>
    </ng-container>

    <ng-container *ngIf="type === 'registerHero'">
        <button class="type-register-hero btn btn-app-red" [routerLink]="navS.pages.SubscribePage.link()">
            <strong class="fw-bolder _fs-16 text-uppercase">
                <span *ngIf="!isInternationalPage">{{ 'components.buttons.subscribe.register_hero.text' | translate }}</span>
                <span *ngIf="isInternationalPage">Suscríbete ahora</span>
                <i class="fa fa-angle-right ms-2"></i></strong>
        </button>

        <p class="ff-opensans-r mt-3 mb-0 _fs-14">
            <ng-container *ngIf="!isInternationalPage">
                {{ 'components.buttons.subscribe.register_hero.description.text' | translate }}<a class="text-reset" (click)="authLoginModalS.open()">{{ 'components.buttons.subscribe.register_hero.description.link' | translate }}</a>
            </ng-container>

            <ng-container *ngIf="isInternationalPage">
                ¿Ya eres suscriptor de Teatrix? <a class="text-reset" (click)="authLoginModalS.open()">Ingresa aquí</a>
            </ng-container>
        </p>
    </ng-container>

    <ng-container *ngIf="type === 'registerHeroSmall'">
        <button class="type-register-hero-small btn btn-app-red" [routerLink]="navS.pages.SubscribePage.link()">
            <strong class="fw-bolder _fs-16 text-uppercase">
                <span *ngIf="!isInternationalPage">{{ 'components.buttons.subscribe.register_hero_small.text' | translate }}</span>
                <span *ngIf="isInternationalPage">Suscríbete ahora</span>
                <i class="fa fa-angle-right ms-2"></i></strong>
        </button>

        <p class="ff-opensans-r mt-3 mb-0 _fs-14">
            <ng-container *ngIf="!isInternationalPage">
                <a class="text-reset" (click)="authLoginModalS.open()">{{ 'components.buttons.subscribe.register_hero_small.description.link' | translate }}</a>
            </ng-container>

            <ng-container *ngIf="isInternationalPage">
                <a class="text-reset" (click)="authLoginModalS.open()">¿Ya eres suscriptor de Teatrix?</a>
            </ng-container>
        </p>
    </ng-container>

    <ng-container *ngIf="type === 'outline'">
        <button class="type-outline btn btn-outline-app-red" [routerLink]="navS.pages.SubscribePage.link()">
            <strong class="fw-bolder _fs-16 text-uppercase">{{ 'components.buttons.subscribe.outline' | translate }} <i class="fa fa-angle-right ms-2"></i></strong>
        </button>
    </ng-container>

    <ng-container *ngIf="type === 'onlyButtonNow'">
        <a class="type-outline btn"
        [class.btn-app-red]="color === 'red'"
        [class.btn-white]="color === 'white'"
        [class.text-app-red]="color === 'white'"
        style="max-width: 20rem;"
        [routerLink]="registerHref ? registerHref : navS.pages.SubscribePage.link()"
        [queryParams]="registerHref && registerParams ? { code: registerParams[0], step2: registerParams[1] } : null"
        >
            <strong *ngIf="!isInternationalPage" class="fw-bolder _fs-16 text-uppercase">{{ 'components.buttons.subscribe.only_button_now' | translate }} <i class="fa fa-angle-right ms-2"></i></strong>
            <strong *ngIf="isInternationalPage" class="fw-bolder _fs-16 text-uppercase">Suscríbete ahora <i class="fa fa-angle-right ms-2"></i></strong>
        </a>
    </ng-container>

    <ng-container *ngIf="type === 'registerOnlyButtonNow'">
        <a class="type-outline btn"
        [class.btn-app-red]="color === 'red'"
        [class.btn-white]="color === 'white'"
        [class.text-app-red]="color === 'white'"
        style="max-width: 20rem;"
        [routerLink]="registerHref ? registerHref : navS.pages.SubscribePage.link()"
        [queryParams]="registerHref && registerParams ? { code: registerParams[0], step2: registerParams[1] } : null"
        >
            <strong *ngIf="!isInternationalPage" class="fw-bolder _fs-16 text-uppercase">{{ 'components.buttons.subscribe.register_only_button_now.text' | translate }} <i class="fa fa-angle-right ms-2"></i></strong>
            <strong *ngIf="isInternationalPage" class="fw-bolder _fs-16 text-uppercase">Suscríbete ahora <i class="fa fa-angle-right ms-2"></i></strong>
        </a>

        <p class="ff-opensans-r mt-3 mb-0 _fs-14">
            <ng-container *ngIf="!isInternationalPage">
                {{ 'components.buttons.subscribe.register_only_button_now.description.text' | translate }}<br class="d-sm-none"><a class="text-reset" [routerLink]="navS.pages.HomePage.link()">{{ 'components.buttons.subscribe.register_only_button_now.description.link' | translate }}</a>
            </ng-container>

            <ng-container *ngIf="isInternationalPage">
                ¿Todavía no estás convencido? <br class="d-sm-none"><a class="text-reset" [routerLink]="navS.pages.HomePage.link()">Visita nuestro catálogo</a>
            </ng-container>
        </p>
    </ng-container>

    <ng-container *ngIf="type === 'promoHero'">
        <a class="type-register-hero btn btn-app-red"
            style="max-width: 30rem;"
            [routerLink]="registerHref"
            [queryParams]="registerHref && registerParams ? { code: registerParams[0], step2: registerParams[1] } : null"
        >
            <strong class="fw-bolder _fs-16 text-uppercase">Suscribite ahora</strong>

            <span class="mx-2" style="text-decoration:line-through;">{{ info.subscriptionPrice }}</span>
            <strong>{{ promoPriceOff }}<i class="fa fa-angle-right ms-2"></i></strong>
        </a>

        <p class="ff-opensans-r mt-3 mb-0 _fs-14">
            *Suscribite ahora por {{ promoPriceOff }} en tu primer mes<br>
            y enamorate del mejor teatro en casa.
        </p>
    </ng-container>

    <ng-container *ngIf="type === 'promoInternationalHero'">
        <a class="type-register-hero btn btn-app-red"
            style="max-width: 30rem;"
            [routerLink]="registerHref"
            [queryParams]="registerHref && registerParams ? { code: registerParams[0], step2: registerParams[1] } : null"
        >
            <strong class="fw-bolder _fs-16 text-uppercase">Suscríbete ahora</strong>

            <span class="mx-2" style="text-decoration:line-through;">{{ info.subscriptionPrice }}</span>
            <strong>{{ promoPriceOff }}<i class="fa fa-angle-right ms-2"></i></strong>
        </a>

        <p class="ff-opensans-r mt-3 mb-0 _fs-14">
            *Suscríbete ahora por {{ promoPriceOff }} en tu primer mes<br>
            y enamórate del mejor teatro en casa.
        </p>
    </ng-container>
</div>
