import { CreditCardTypeEnum } from "./commons/credit-card/enums/credit-card-type.enum";

export interface Combos {
  creditCardTypes: any[],
}

export const combos: Combos = {
  creditCardTypes: [
    { id: 1, name: 'Visa', type: CreditCardTypeEnum.Visa, value: 'VISA' },
    { id: 2, name: 'Mastercard', type: CreditCardTypeEnum.Mastercard, value: 'MASTERCARD' },
    { id: 3, name: 'American Express', type: CreditCardTypeEnum.Amex, value: 'AMEX', maskCardNumber: '0000 000000 00000', maskCardNumberGeneric: 'AAAA AAAAAA AAAAA' },
    { id: 4, name: 'Shopping Card', type: CreditCardTypeEnum.Shopping, value: 'SHOPPING' },
    { id: 5, name: 'Cabal', type: CreditCardTypeEnum.Cabal, value: 'CABAL' },
    { id: 6, name: 'Argencard', type: CreditCardTypeEnum.Argencard, value: 'ARGENCARD' },
    { id: 7, name: 'Cencosud', type: CreditCardTypeEnum.Cencosud, value: 'CENCOSUD' },
    { id: 8, name: 'Tarjeta Naranja', type: CreditCardTypeEnum.Naranja, value: 'NARANJA' },
  ],
};
