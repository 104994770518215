import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import * as clamp from 'clamp-js';
import { SsrService } from '../services/ssr.service';

@Directive({
  selector: '[appClamp]'
})
export class ClampDirective implements OnInit {

  @Input('appClamp') lines: number;

  constructor(
    private _elem: ElementRef,
    private _ssrS: SsrService,
  ) { }

  ngOnInit(): void {
    if (this._ssrS.isServer()) return;

    clamp(this._elem.nativeElement, { clamp: this.lines, useNativeClamp: true });
  }
}
