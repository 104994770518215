<form [formGroup]="form.group" class="position-relative" (submit)="submitted$.emit()">
    <app-request-loading [requests]="[form.requestData, form.requestCombos]" [strict]="true"></app-request-loading>

    <ng-content></ng-content>

    <input class="d-none" type="submit">

    <!-- <app-request-error *ngIf="form.request.isError() && form.request.error.status !== 422" [request]="form.request" class="mt-4"></app-request-error> -->

    <ng-container *ngIf="debug">
        <div class="_f-0-8 mt-5">
            <hr>

            <div class="row g-1 mt-3">
                <div class="col-15 border-right">
                    <pre class="text-uppercase"><strong>Form status</strong></pre>

                    <pre class="m-0"><strong>Are options loaded?:</strong> <span [ngClass]="form.options.areLoaded() ? 'text-success': 'text-danger'">{{ form.options.areLoaded() }}</span></pre>
                    <pre class="m-0"><strong>Is the form ready?:</strong> <span [ngClass]="form.isReady() ? 'text-success': 'text-danger'">{{ form.isReady() }}</span></pre>
                    <pre class="m-0"><strong>Dirty?:</strong> <span [ngClass]="form.group.dirty ? 'text-success': 'text-danger'">{{ form.group.dirty }}</span></pre>
                    <pre class="m-0"><strong>Touched?:</strong> <span [ngClass]="form.group.touched ? 'text-success': 'text-danger'">{{ form.group.touched }}</span></pre>
                </div>

                <!-- <div class="col-15">
                    <pre class="text-uppercase"><strong>Form request data</strong></pre>
                    <pre class="text-secondary">{{ form.requestData | json }}</pre>
                </div>

                <div class="col-30">
                    <pre class="text-uppercase"><strong>Form request</strong></pre>
                    <pre class="text-secondary">{{ form.request | json }}</pre>
                </div> -->
            </div>

            <hr>

            <div class="row g-1 mt-3">
                <div class="col-15 border-right">
                    <pre class="text-uppercase"><strong>Form config</strong></pre>
                    <!-- <pre class="text-warning">{{ form.config | json }}</pre> -->
                </div>

                <div class="col-15">
                    <pre class="text-uppercase"><strong>Form group value</strong></pre>
                    <pre class="text-secondary">{{ form.group.value | json }}</pre>
                </div>

                <div class="col-15">
                    <pre class="text-uppercase"><strong>Form current state</strong></pre>
                    <pre class="text-primary">{{ form.state.current | json }}</pre>
                </div>

                <div class="col-15">
                    <pre class="text-uppercase"><strong>Form init state</strong></pre>
                    <pre class="text-info">{{ form.state.init | json }}</pre>
                </div>
            </div>
        </div>
    </ng-container>
</form>
