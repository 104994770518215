import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArrayComponent } from './form-array.component';
import { FormElementsModule } from '../../form-elements/form-elements.module';



@NgModule({
  declarations: [FormArrayComponent],
  imports: [
    CommonModule,
    FormElementsModule
  ],
  exports: [FormArrayComponent]
})
export class FormArrayModule { }
