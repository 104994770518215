import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormTextareaComponent } from './form-textarea.component';
import { FormElementsModule } from '../../form-elements/form-elements.module';



@NgModule({
  declarations: [FormTextareaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormElementsModule,
  ],
  exports: [FormTextareaComponent],
})
export class FormTextareaModule { }
