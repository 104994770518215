<div class="sidebar-container" [class.open]="isOpen">
    <div class="sidebar-backdrop" (click)="close($event)">
        <div class="sidebar-menu text-gray-500">
            <div #sidebarMenuContentElem class="sidebar-menu-content"
                [class.transition__on]="isOpen"
                [class.menu-secondary-open]="isMenuSecondaryOpen"
                [class.menu-results-open]="isResultsOpen"
            >
                <ng-template #caretRight>
                    <app-icon class="ms-auto" name="caret-right-white" [size]="0.5"></app-icon>
                </ng-template>

                <ng-template #results let-type="type">
                    <div class="d-flex align-items-center align-items-sm-baseline _mb-2">
                        <ng-container *ngIf="type === 'desktop'">
                            <app-title-strip class="me-3 results" size="md" tabindex="0">{{ resultsTitle }}</app-title-strip>
                        </ng-container>

                        <ng-container *ngIf="type === 'mobile'">
                            <div class="title">
                                <a href="javascript:;" (click)="closeResults()">
                                    <app-icon onleft-lg name="back-white">{{ resultsTitle }}</app-icon>
                                </a>
                            </div>
                        </ng-container>

                        <button class="btn-close ms-auto" (click)="close()" aria-label="Cerrar menú" tabindex="0"></button>
                    </div>

                    <ng-container *ngIf="isResultsForSearchOpen">
                        <app-play-searchbar class="mb-5" [static]="true" [playL]="resultsL" placeholder="Ingresa título o persona" style="max-width: unset;"></app-play-searchbar>
                    </ng-container>

                    <app-request-handler [request]="resultsL.request" [isUnfiredToo]="false">
                        <ng-container *ngIf="resultsL.get()?.data.length">
                            <div class="row g-4">
                                <ng-container *ngFor="let play of resultsL.get()?.data">
                                    <app-play-box *ngIf="type === 'desktop'" type="detail" [play]="play"></app-play-box>
                                    <app-play-box *ngIf="type === 'mobile'" type="menu-mobile" [play]="play"></app-play-box>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!resultsL.get()?.data.length && categorySelected === categoryPpv">
                            <div class="message-ppv">
                                <p class="display-6 text-gray-800">
                                    Muy pronto habrá nuevo contenido PPV para disfrutar.
                                </p>
                            </div>
                        </ng-container>
                    </app-request-handler>
                </ng-template>

                <div class="menu menu-main" tabindex="0" data-simplebar>
                    <ul>
                        <li class="d-sm-none d-flex title">
                            <button class="btn-close ms-auto" (click)="close()" aria-label="Cerrar menú" tabindex="0"></button>
                        </li>

                        <li class="d-lg-none" (mouseenter)="itemIcon7.name = 'search-red'" (mouseleave)="itemIcon7.name = 'search-gray'"
                            [class.active]="isResultsForSearchOpen"
                        >
                            <a href="javascript:;" (click)="openResultsForSearch()" (keyup.enter)="openResultsForSearch()" tabindex="0">
                                <app-icon #itemIcon7 onleft-lg name="search-gray">Buscar</app-icon>
                                <ng-container [ngTemplateOutlet]="caretRight"></ng-container>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon1.name = 'catalog-red'" (mouseleave)="itemIcon1.name = 'catalog-gray'">
                            <a href="javascript:;" (click)="openMenuSecondary('catalog')" (keyup.enter)="openMenuSecondary('catalog')" tabindex="0">
                                <app-icon #itemIcon1 onleft-lg name="catalog-gray">Catálogo</app-icon>
                                <ng-container [ngTemplateOutlet]="caretRight"></ng-container>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon2.name = 'flag-red'" (mouseleave)="itemIcon2.name = 'flag-gray'">
                            <a href="javascript:;" (click)="openMenuSecondary('internationals')" (keyup.enter)="openMenuSecondary('internationals')" tabindex="0">
                                <app-icon #itemIcon2 onleft-lg name="flag-gray">Internacionales</app-icon>
                                <ng-container [ngTemplateOutlet]="caretRight"></ng-container>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon3.name = 'ticket-red'" (mouseleave)="itemIcon3.name = 'ticket-gray'"
                            [class.active]="categorySelected?.id === categoryPpv?.id"
                        >
                            <a href="javascript:;" (click)="openResults(categoryPpv)" (keyup.enter)="openResults(categoryPpv)" tabindex="0">
                                <app-icon #itemIcon3 onleft-lg name="ticket-gray">{{ categoryPpv?.name }}</app-icon>
                                <ng-container [ngTemplateOutlet]="caretRight"></ng-container>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon4.name = 'star-red'" (mouseleave)="itemIcon4.name = 'star-gray'">
                            <a class="text-reset text-decoration-none" [routerLink]="navS.pages.PlayCollectionsListPage.link()" tabindex="0">
                                <app-icon #itemIcon4 onleft-lg name="star-gray">Colecciones</app-icon>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon5.name = 'curtain-red'" (mouseleave)="itemIcon5.name = 'curtain-gray'">
                            <a class="text-reset text-decoration-none" [routerLink]="navS.pages.AboutUsPage.link()" tabindex="0">
                                <app-icon #itemIcon5 onleft-lg name="curtain-gray">Sobre Teatrix</app-icon>
                            </a>
                        </li>

                        <ng-container *ngIf="!storeS.authUser.value?.user.hasSubscription()">
                            <li (mouseenter)="itemIcon6.name = 'subscribe-red'" (mouseleave)="itemIcon6.name = 'subscribe-gray'">
                                <a class="text-reset text-decoration-none" [routerLink]="navS.pages.RegisterPage.link()" tabindex="0">
                                    <app-icon #itemIcon6 onleft-lg name="subscribe-gray">Suscribite</app-icon>
                                </a>
                            </li>
                        </ng-container>

                        <li (mouseenter)="itemIcon13.name = 'gift-cards-red'" (mouseleave)="itemIcon13.name = 'gift-cards-gray'">
                            <a class="text-reset text-decoration-none" [routerLink]="navS.pages.GiftCardsPage.link()" tabindex="0">
                                <app-icon #itemIcon13 onleft-lg name="gift-cards-gray">Regalá Teatrix</app-icon>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon11.name = 'gift-red'" (mouseleave)="itemIcon11.name = 'gift-gray'">
                            <a class="text-reset text-decoration-none" [routerLink]="navS.pages.CorporativePage.link()" tabindex="0">
                                <app-icon #itemIcon11 onleft-lg name="gift-gray">Regalos Corporativos</app-icon>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon8.name = 'help-red'" (mouseleave)="itemIcon8.name = 'help-gray'">
                            <a class="text-reset text-decoration-none" [routerLink]="navS.pages.HelpCenterPage.link()" tabindex="0">
                                <app-icon #itemIcon8 onleft-lg name="help-gray">Centro de ayuda</app-icon>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon9.name = 'contact-red'" (mouseleave)="itemIcon9.name = 'contact-gray'">
                            <a class="text-reset text-decoration-none" [routerLink]="navS.pages.ContactPage.link()" tabindex="0">
                                <app-icon #itemIcon9 onleft-lg name="contact-gray">Contacto</app-icon>
                            </a>
                        </li>

                        <li (mouseenter)="itemIcon12.name = 'news-red'" (mouseleave)="itemIcon12.name = 'news-gray'">
                            <a class="text-reset text-decoration-none" [routerLink]="navS.pages.NewsListPage.link()" tabindex="0">
                                <app-icon #itemIcon12 onleft-lg name="news-gray">Noticias</app-icon>
                            </a>
                        </li>

                        <ng-container *ngIf="!storeS.authUser.value?.user.hasSubscription()">
                            <li (mouseenter)="itemIcon10.name = 'partners-red'" (mouseleave)="itemIcon10.name = 'partners-gray'">
                                <a class="text-reset text-decoration-none" [routerLink]="navS.pages.BenefitsPage.link()" tabindex="0">
                                    <app-icon #itemIcon10 onleft-lg name="partners-gray">Beneficios de partners</app-icon>
                                </a>
                            </li>
                        </ng-container>
                    </ul>

                    <div class="sticky-top mt-auto text-center p-4">
                        <ng-container *ngIf="!storeS.authUser.value?.user.hasSubscription()">
                            <app-button-subscribe class="mb-3" type="onlyButton"></app-button-subscribe>
                        </ng-container>

                        <ng-container *ngIf="!storeS.authUser.value?.isLoggedIn()">
                            <a class="d-block link-white text-decoration-none text-uppercase actionable mt-3 _fs-14" href="javascript:;" (click)="authLoginModalS.open(); close();" (keyup.enter)="authLoginModalS.open()" tabindex="0">
                                <app-icon onleft-lg name="user-avatar-white" [size]="1.25">Iniciar sesión</app-icon>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="storeS.authUser.value?.isLoggedIn()">
                            <a class="d-block link-white text-decoration-none text-uppercase actionable _fs-14" (click)="logout(); close();" tabindex="0">
                                <app-icon onleft-lg name="logout-red" [size]="1.25">Cerrar sesión</app-icon>
                            </a>
                        </ng-container>
                    </div>
                </div>

                <div class="menu menu-secondary" data-simplebar>
                    <ng-container *ngIf="menuSecondaryType === 'catalog'">
                        <ul>
                            <li class="title menu-secondary-title">
                                <a class="catalog" (click)="closeMenuSecondary()" (keyup.enter)="closeMenuSecondary()" tabindex="0">
                                    <app-icon class="title" onleft-lg name="back-white">Catálogo</app-icon>
                                </a>

                                <button class="d-sm-none btn-close ms-auto" (click)="close()" aria-label="Cerrar menú" tabindex="0"></button>
                            </li>

                            <ng-container *ngFor="let category of categories">
                                <li [class.active]="categorySelected?.id === category.id">
                                    <a (click)="openResults(category)" (keyup.enter)="openResults(category)" tabindex="0">
                                        {{ category.name }} <ng-container [ngTemplateOutlet]="caretRight"></ng-container>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>

                    <ng-container *ngIf="menuSecondaryType === 'internationals'" data-simplebar>
                        <ul>
                            <li class="title">
                                <a class="internationals" (click)="closeMenuSecondary()" (keyup.enter)="closeMenuSecondary()" tabindex="0">
                                    <app-icon onleft-lg name="back-white">Internacionales</app-icon>
                                </a>

                                <button class="d-sm-none btn-close ms-auto" (click)="close()" aria-label="Cerrar menú" tabindex="0"></button>
                            </li>

                            <ng-container *ngFor="let category of categoriesInternational;">
                                <li [class.active]="categorySelected?.id === category.id">
                                    <a (click)="openResults(category)" (keyup.enter)="openResults(category)" tabindex="0">
                                        <app-icon onleft-lg [name]="category.international_flag" [size]="1.25">{{ category.name }}</app-icon> <ng-container [ngTemplateOutlet]="caretRight"></ng-container>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </div>

                <div class="d-sm-none menu menu-results p-4" data-simplebar>
                    <ng-container [ngTemplateOutlet]="results" [ngTemplateOutletContext]="{ type: 'mobile' }"></ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="isResultsOpen" class="d-none d-sm-flex sidebar-results p-4" data-simplebar>
            <ng-container [ngTemplateOutlet]="results" [ngTemplateOutletContext]="{ type: 'desktop' }"></ng-container>
        </div>
    </div>
</div>
