<div class="grid">
    <div>
        <h3 class="_fs-36 mb-0">
            <strong class="fw-bolderest">
                <span *ngIf="!parent.isFreeRegister">Ingresá tus datos</span>
                <span *ngIf="parent.isFreeRegister">Registrate gratis</span>
            </strong>
        </h3>

        <p class="ff-opensans-r">
            <span *ngIf="!parent.isFreeRegister">Y empezá a disfrutar del mejor Teatro en tu casa.</span>
            <span *ngIf="parent.isFreeRegister">Y empezá a disfrutar de nuestro contenido gratuito.</span>

        </p>

        <app-form [ngClass]="parent.formClass" [form]="formRegister" (submitted$)="register()">
            <div class="pb-4">
                <div class="row">
                    <app-form-input class="col-60 mb-3" type="text" label="Nombre y Apellido" controlName="name"></app-form-input>
                    <app-form-input class="col-60 mb-3" type="email" label="Email" controlName="email"></app-form-input>
                </div>

                <ng-template #passwordShowActionTpl>
                    <div class="col-auto mb-3 text-gray-500 text-end" [ngClass]="password.hasError() ? 'align-self-center' : 'align-self-end'">
                        <i class="far fa-fw"
                            [class.fa-eye]="password.show"
                            [class.fa-eye-slash]="!password.show"
                            (click)="password.show = !password.show; passwordConfirmation.show = !passwordConfirmation.show"
                        ></i>
                    </div>
                </ng-template>

                <div class="row g-2">
                    <app-form-input #password class="col mb-3" type="password" label="Contraseña" controlName="password"></app-form-input>
                    <ng-container [ngTemplateOutlet]="passwordShowActionTpl"></ng-container>
                </div>

                <div class="row g-2">
                    <app-form-input #passwordConfirmation class="col mb-3" type="password" label="Confirmar contraseña" controlName="password_confirmation"></app-form-input>
                    <ng-container [ngTemplateOutlet]="passwordShowActionTpl"></ng-container>
                </div>

                <div class="row">
                    <app-form-checkbox class="col-60 mb-3" controlName="tyc">
                        Acepto los <a class="link-app-red" [routerLink]="navS.pages.TermsPage.link()" target="_blank">términos y condiciones</a>
                    </app-form-checkbox>
                </div>
            </div>
        </app-form>
    </div>

    <div class="mt-auto">
        <div class="d-block d-md-flex align-items-center">
            <div>
                <app-button-form [request]="formRegister.request" (click)="register()">
                    Continuar
                </app-button-form>
            </div>

            <a class="d-block d-md-inline-flex link-gray-600 lh-md mt-3 mt-md-0 ms-md-3 _fs-14" (click)="authLoginModalS.open()" (keyup.enter)="authLoginModalS.open()" tabindex="0">¿Ya tenés cuenta en Teatrix?<br>Iniciá la sesión acá.</a>
        </div>
    </div>
</div>
