import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthLoginModalService } from '../components/core/auth/auth-login-modal/auth-login-modal.service';
import { NavService } from '../services/nav.service';
import { RouteService } from '../services/route.service';
import { StoreService } from '../services/store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserIsLoggedInGuard implements CanActivate {

  constructor(
    private _storeS: StoreService,
    private _navS: NavService,
    private _authLoginModalS: AuthLoginModalService,
    private _router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.resolveCanActivate(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.resolveCanActivate(next, state);
  }

  /* -------------------- */

  resolveCanActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const can: boolean = this._storeS.authUser.value?.isLoggedIn();

    let navigateTo: string = 'HomePage';

    let openLoginModal: boolean = true;

    let redirectAction: string;

    let queryParams: any = {
      redirectTo: state.url
    }

    switch(next.data.name) {
      case 'GiftCardRedeemPage': redirectAction = 'GIFT_CARD_REDEEM'; navigateTo = 'SubscribePage'; openLoginModal = false; break;
    }

    if (redirectAction) {
      queryParams.redirectAction = redirectAction;
    }

    if (!can) {
      this._router.navigate([this._navS.pages[navigateTo].link()], {
        queryParams: queryParams,
      }).then(() => {
        if (openLoginModal) {
          this._authLoginModalS.open();
        }
      });
    }

    return can;
  }
}
