import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { Form } from 'src/app/packages/forms/tools/form';
import { AuthUserHttpService } from 'src/app/services/http/auth-user-http.service';
import { AuthUserSubscriptionHttpService } from 'src/app/services/http/auth-user-subscription-http.service';
import { ApiErrorResponse } from 'src/app/services/http/core/http.service';
import { ToastService } from 'src/app/services/toast.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { AuthSubscribeModalContentComponent } from '../auth-subscribe-modal-content/auth-subscribe-modal-content.component';

@Component({
  selector: 'app-auth-subscribe-modal-subscribe-form',
  templateUrl: './auth-subscribe-modal-subscribe-form.component.html',
  styleUrls: ['./auth-subscribe-modal-subscribe-form.component.scss']
})
export class AuthSubscribeModalSubscribeFormComponent implements OnInit {

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    public parent: AuthSubscribeModalContentComponent,
    private _authUserSubscriptionHttpS: AuthUserSubscriptionHttpService,
    private _authUserHttpS: AuthUserHttpService,
    private _toastS: ToastService,
    private _analytic: AnalyticsService,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._analytic.RegisterStep(2);

    if (this._route.snapshot.queryParamMap.get('code')) {
      this.formSubscribe.group.get('benefit').setValue(this.formSubscribe.config.extras.benefit.options.items.find(item => item.id === 3));
      this.formSubscribe.group.get('benefit_Code').setValue({ code: this._route.snapshot.queryParamMap.get('code') });
    }

    if (this.parent.skipStep2) {
      if (!this._route.snapshot.queryParamMap.get('code')) {
        this.continue();
      } else {
        this._sm.add(this.parent.discountRequest.completed$.subscribe(() => {
          if (this.isCodeValid(this.formSubscribe.group.get('benefit_Code.code'))) {
            this.continue();
          }
        }));
      }
    }
  }

  get formRegister(): Form {
    return this.parent.formRegister;
  }

  get formSubscribe(): Form {
    return this.parent.formSubscribe;
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  /* -------------------- */

  isCodeValid(control: AbstractControl): boolean {
    return !!control.value && (!!this.parent.discount || !!this.parent.discountPlan);
  }

  /* -------------------- */

  continue(): void {
    if (this.formSubscribe.ready && (!this.parent.ppvCode || this.isCodeValid(this.formSubscribe.group.get('benefit_Code.code')))) {
      if (this.parent.discountPlan?.prepagos || this.parent.ppvCode) {
        this.checkout();
      } else {
        /*
         * Discount Register
         */
        this._analytic.BeginCheckout(this.formSubscribe, this.parent.getPlanAmountWithDiscount(this.formSubscribe.group.value?.plan, this.parent.discount), this.parent.isPpv ? 'PPV' : 'Subscription')

        this.formSubscribe.persist();

        this.parent.step = 3;
      }
    }
  }

  /* -------------------- */

  checkout(): void {
    if (this.formSubscribe.request.isLoading()) return;

    this.formSubscribe.resetMarks();

    let method: string;
    let input: any;


    if (!this.parent.isPpv) {
      method = this.parent.useMocks ? 'mockCheckout' : 'checkout';

      input = {
        planCountryId: this.formSubscribe.group.value?.plan?.plans_countries_id || null,
        campaignCode: this.formSubscribe.group.value?.benefit_Code.code,
        /* -------------------- */
        value: this.parent.getPlanAmountWithDiscount(this.formSubscribe.group.value?.plan),
      };

      if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
        input.name = this.formRegister.state.current.name;
        input.email = this.formRegister.state.current.email;
        input.clientPassword = this.formRegister.state.current.password;
      }
    }
    else {
      method = this.parent.useMocks ? 'mockCheckoutForPpvCode' : 'checkoutForPpvCode';

      input = {
        campaignCode: this.formSubscribe.group.value?.benefit_Code.code,
        playID: this.parent.play.id,
      };

      if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
        input.name = this.formRegister.state.current.name;
        input.email = this.formRegister.state.current.email;
        input.password = this.formRegister.state.current.password;
      }
    }

    this._sm.add(
      this.formSubscribe.request.send<any>(this._authUserSubscriptionHttpS[method](input)).subscribe(res => {
        const err: ApiErrorResponse = this.formSubscribe.request.error;

        if (err) {
          if (err.status !== 422) {
            this._toastS.showHttpError(err);
          }
        } else {
          this.formSubscribe.persist();

          if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
            const inputLogin = {
              email: this.formRegister.state.current.email,
              password: this.formRegister.state.current.password,
              remember_me: true,
            };

            this._sm.clean('checkout');

            this._sm.add(
              this.formSubscribe.request.send(this._authUserHttpS.login(inputLogin)).pipe(
                finalize(() => {
                  this.parent.storeS.emit('authUser', this.parent.isPpv ? 'checkoutPpv' : 'checkoutSubscription');
                  this.parent.step = 4;
                })
              ).subscribe()
            , 'checkout');
          } else {
            this.parent.storeS.emit('authUser', this.parent.isPpv ? 'checkoutPpv' : 'checkoutSubscription');
            this.parent.step = 4;
          }
        }
      }, (err: ApiErrorResponse) => {
        if (err.status !== 422) {
          this._toastS.showHttpError(err);
        }
      })
    , 'checkout');
  }
}
