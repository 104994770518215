<ng-template #requestLoading let-request="request">
    <ng-container *ngIf="!request.isUnfired() && ((!strict && !request.isComplete()) || (strict && !request.isSuccess()))">
        <div class="request-loading d-flex align-items-center justify-content-center">
            <ng-container *ngIf="request.isLoading()">
                <div class="spinner-grow text-primary" role="status" aria-hidden="true"></div>
            </ng-container>

            <ng-container *ngIf="strict && request.isError()">
                <app-request-error class="shadow" [request]="request"></app-request-error>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #requestMessage>
    <div class="request-loading d-flex align-items-center justify-content-center">
        <p class="m-0">{{ message }}</p>
    </div>
</ng-template>

<ng-container *ngIf="message" [ngTemplateOutlet]="requestMessage"></ng-container>
<ng-container *ngIf="request" [ngTemplateOutlet]="requestLoading" [ngTemplateOutletContext]="{ request: request }"></ng-container>

<ng-container *ngFor="let request of requests">
    <ng-container [ngTemplateOutlet]="requestLoading" [ngTemplateOutletContext]="{ request: request }"></ng-container>
</ng-container>
