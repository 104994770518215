import { Directive, ElementRef, Input, TemplateRef, HostListener, OnDestroy } from '@angular/core';
import { HoverPreviewService } from './hover-preview.service';

@Directive({
  selector: '[hoverPreview]'
})
export class HoverPreviewDirective implements OnDestroy {

  @Input('hoverPreview') templateRef: TemplateRef<any>;
  @Input() delay: number = 0;
  @Input() scale: number = 0.3;
  @Input() minWidth: number;
  @Input() maxWidth: number;

  constructor(
    private _elem: ElementRef,
    private _hoverPreviewS: HoverPreviewService
  ) { }

  ngOnDestroy(): void {
    clearTimeout(this._hoverPreviewS.timeout);

    if (this.templateRef === this._hoverPreviewS.templateRef) {
      this._hoverPreviewS.templateRef = null;
      this._hoverPreviewS.elemNext(null);
    }
  }

  /* -------------------- */

  @HostListener('mouseenter')
  onMouseEnter(): void {
    clearTimeout(this._hoverPreviewS.timeout);

    this._hoverPreviewS.timeout = setTimeout(() => {
      this._hoverPreviewS.scale = this.scale;
      this._hoverPreviewS.minWidth = this.minWidth;
      this._hoverPreviewS.maxWidth = this.maxWidth;
      this._hoverPreviewS.templateRef = this.templateRef;
      this._hoverPreviewS.elemNext(this._elem.nativeElement);
    }, this.delay);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    clearTimeout(this._hoverPreviewS.timeout);
  }
}
