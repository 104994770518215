import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from 'src/app/services/nav.service';


@Component({
  selector: 'app-free-trial-modal',
  templateUrl: './free-trial-modal.component.html',
  styleUrls: ['./free-trial-modal.component.scss']
})
export class FreeTrialModalComponent implements OnInit {
  @Input() finishDate: string;

  constructor(
    public modal: NgbActiveModal,
    public navS: NavService,
  ) { }

  ngOnInit(): void {
    //
  }

}
