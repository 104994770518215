<button type="button" class="btn-close btn-close-dark" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>

<div class="grid modal-body text-center align-items-center">
    <div class="py-4 px-sm-5">
        <p class="mt-5 text-gray-600">Tu email no se encuentra validado</p>
        <h3 class="ff-gilroy-b mb-3">Por favor, validá tu email</h3>

        <p class="my-4">Es importante que tu cuenta de email esté validada para seguir disfrutando del mejor teatro online, así podremos brindarte todo el soporte que necesites para operar TEATRIX.</p>

        <p class="my-4">Tu email registrado es: <strong>{{ storeS.authUser.value?.user.email }}</strong></p>

        <p class="my-4">Podés editar tus datos o solicitar que te reenviemos el email de validación desde tu perfil.</p>

        <a class="d-block mt-3 mb-3" [routerLink]="navS.pages.MyAccountPage.link()">
            <app-button-form class="w-100" [fullWidth]="true">Editar mi perfil / Reenviar</app-button-form>
        </a>

        <a class="link-gray-600 lh-md mt-3 mx-auto mx-sm-0 _fs-14" [routerLink]="navS.pages.HomePage.link()">Confirmar luego, seguir navegando TEATRIX</a>
    </div>
</div>
