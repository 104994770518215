import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appNl2br'
})
export class Nl2BrPipe implements PipeTransform {

  transform(value: string): any {
    return value.replace(/\r?\n|\r/g, '<br>');
  }

}
