<div class="hover-preview"
    [ngStyle]="{
        top: position.top,
        left: position.left,
        right: position.right,
        width: position.width
    }"
>
    <ng-container *ngIf="hoverPreviewS.templateRef" [ngTemplateOutlet]="hoverPreviewS.templateRef"></ng-container>
</div>
