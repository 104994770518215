import { Component, OnInit, Optional, Input, ElementRef } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
})
export class FormCheckboxComponent extends AbstractFormFieldComponent implements OnInit {

  @Input() asSwitch: boolean = false;

  id: string;

  constructor(
    @Optional() public formC: FormComponent,
    @Optional() protected _formGroupParent: FormGroupDirective,
    private _elem: ElementRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    setTimeout(() => {
      this._elem.nativeElement.querySelector("input[type='radio']:checked")?.focus();

      let i: number = 0;
      let id: string;

      do {
        i++;
        id = 'appFormCheckbox_' + i;
      } while (!!document.querySelector('#' + id))

      this.id = id;
    });

    this._sm.add(this.control.valueChanges.subscribe((value: any) => {
      if (this._.isNull(value)) {
        this.control.setValue(false, { emitEvent: false });
      }
    }));
  }

  get type(): string {
    return this.isMultiple() || this.isBoolean() ? 'checkbox' : 'radio';
  }
}
