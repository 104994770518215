import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { FreeTrialModalComponent } from './free-trial-modal.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    FreeTrialModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PipesModule,
    RouterModule,
  ]
})
export class FreeTrialModalModule { }
