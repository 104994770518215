import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import { homeHttpServiceMock } from './mocks/mocks';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HomeHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
  ) { }

  /* -------------------- */

  getHero(params?: any) {
    const tokenType: TokenType = 'authGuestToken';
    const paramsParsed = this._httpS.parseParams(params);

    if (this._httpS.useMocks) {
      return of(cloneDeep(homeHttpServiceMock.getHero)).pipe(delay(200));
    }

    return this._httpS.send(this._httpClient.get(this._httpS.getEndpoint(`cache:/home/home-hero?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getBanner(params?: any) {
    const tokenType: TokenType = 'authGuestToken';
    const paramsParsed = this._httpS.parseParams(params);

    if (this._httpS.useMocks) {
      return of(homeHttpServiceMock.getBanner).pipe(delay(200));
    }

    return this._httpS.send(this._httpClient.get(this._httpS.getEndpoint(`cache:/home/banner?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }
}
