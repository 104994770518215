<div class="form-group">
    <div #formFieldElem class="form-field" [class.focus]="formElem.isOpen()" [ngClass]="getFormFieldClass()" (click)="formElem.open()" (keyup.enter)="formElem.open()" tabindex="0">
        <label (click)="formElem.open()">
            <app-form-disabled [formFieldC]="this"></app-form-disabled>
            <!-- <app-form-error [formFieldC]="this"></app-form-error> -->
            {{ label || '&nbsp;' }}
            <span *ngIf="!form.ready && optionsCanSet && !options.length">(cargando... <div class="spinner-grow" style="width: 0.9rem; height: 0.9rem;"></div>)</span>
        </label>

        <i *ngIf="!!icon" class="form-icon fa-fw" [ngClass]="icon"></i>

        <div #formElem="ngbDropdown" class="form-control" ngbDropdown
            [class.is-invalid]="isInvalid()"
            [autoClose]="isMultiple() ? 'outside' : true"
        >
            <div class="form-control-value text-truncate" ngbDropdownAnchor>
                <ng-container *ngIf="optionsIndexBy !== '_'">{{ isMultiple() ? _.join(_.map(control.value, 'name'), ', ') : control.value?.name }}</ng-container>
                <ng-container *ngIf="optionsIndexBy === '_'">{{ isMultiple() ? _.join(_.map(control.value), ', ') : control.value }}</ng-container>
            </div>

            <div ngbDropdownMenu data-simplebar>
                <div [hidden]="isSearchableUndefined">
                    <div class="px-3 mb-3">
                        <app-form-input #search icon="fas fa-search" [themes]="['one-line']"></app-form-input>
                    </div>
                </div>

                <button type="button" *ngIf="!options.length || isDisabled()" ngbDropdownItem [disabled]="true">-- No hay opciones</button>

                <ng-container *ngIf="options.length && !isDisabled()">
                    <button type="button" *ngIf="!isMultiple() && !optionsRequired" ngbDropdownItem (click)="select('')">--</button>

                    <ng-template #buttonItemTpl let-option let-hasParent="hasParent">
                        <button type="button" ngbDropdownItem [class.selected]="isSelected(option)" (click)="select(option)">
                            <div class="row g-1 align-items-center">
                                <span class="col text-truncate">
                                    <ng-container *ngIf="optionsIndexBy !== '_'">
                                        <ng-container *ngIf="hasParent">&nbsp;&nbsp;</ng-container>{{ option?.name }}
                                    </ng-container>

                                    <ng-container *ngIf="optionsIndexBy === '_'">
                                        <ng-container *ngIf="hasParent">&nbsp;&nbsp;</ng-container>{{ option }}
                                    </ng-container>
                                </span>

                                <i *ngIf="isSelected(option)" class="col-auto ms-auto fas fa-check"></i>
                            </div>
                        </button>
                    </ng-template>

                    <ng-container *ngIf="!!optionsItemChild">
                        <ng-container *ngFor="let option of options">
                            <button type="button" ngbDropdownItem disabled>
                                <strong>{{ option?.name }}</strong>
                            </button>

                            <ng-container *ngFor="let optionChild of option[optionsItemChild] | search:search?.control.value:searchable; trackBy: trackByFn">
                                <ng-container [ngTemplateOutlet]="buttonItemTpl" [ngTemplateOutletContext]="{ $implicit: optionChild, hasParent: true }"></ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!optionsItemChild">
                        <ng-container *ngFor="let option of options | search:search?.control.value:searchable; trackBy: trackByFn">
                            <ng-container [ngTemplateOutlet]="buttonItemTpl" [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <i class="form-icon form-icon-toggle fas fa-angle-{{ formElem.isOpen() ? 'up' : 'down' }}"></i>
    </div>

    <small *ngIf="help" class="form-text text-muted">{{ help }}</small>

    <app-form-error [formFieldC]="this"></app-form-error>
</div>
