import { Injectable, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { RouteService } from 'src/app/services/route.service';
import { TaxonomyOptions } from './interfaces/taxonomy-options.interface';
import { Taxonomies } from './interfaces/taxonomy.interface';
import { taxonomies } from './taxonomies';
import { TaxonomyBreadcrumb } from './interfaces/taxonomy-breadcrumb.interface';
import { StoreService } from '../services/store.service';
import { PlayCategory } from '../core/play-category/play-category.model';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {

  taxonomies: Taxonomies = taxonomies;

  constructor(
    @Inject(DOCUMENT) private _dom: Document,
    private _title: Title,
    private _meta: Meta,
    private _routeS: RouteService,
    private _storeS: StoreService,
  ) {

    this._routeS.onNavigationEnd$().subscribe((event: NavigationEnd) => {
      const snapshot = this._routeS.getSnapshotLastChild();

      let taxonomy = _.cloneDeep(this.taxonomies[snapshot.data.name]);
      let pageName: string;
      let params: string[];

      this.taxonomies.hasOwnProperty(snapshot.data.name) && !taxonomy.title.includes('{param_')
        ? pageName = snapshot.data.name
        : pageName = '_default';

      if (['AboutUsPage', 'NotFoundPage'].includes(snapshot.data.name) || pageName === '_default') {
        params = [
          PlayCategory.getForSidebar(this._storeS.playCategories.value).map(playCategory => playCategory.name).join(', ')
        ]
      }

      this.taxonomies.hasOwnProperty(snapshot.data.name)
        ? this.resolve(pageName, { params })
        : this.resolve(pageName, { params });
    });
  }

  get domUrl(): string {
    return `${environment.url}${this._dom.location.pathname}`;
  }

  get domBaseUri(): string {
    return environment.url;
  }

  /* -------------------- */

  resolve(name: string, options?: TaxonomyOptions): any {
    let taxonomy = _.cloneDeep(this.taxonomies[name]);

    taxonomy.canonical = this.domUrl;
    taxonomy.ogImage = `${this.domBaseUri}/assets/img/open-graph-teatrix.webp`;

    if (options) {
      if (options.params) {
        options.params.forEach((param: string, key: number) => {
          taxonomy.title = this._capitalize(taxonomy.title.replace(new RegExp('{param_' + key + '}', 'g'), param.toLowerCase()));
          taxonomy.description = this._capitalize(taxonomy.description.replace(new RegExp('{param_' + key + '}', 'g'), param.toLowerCase()));
          taxonomy.keywords = this._capitalize(taxonomy.keywords.replace(new RegExp('{param_' + key + '}', 'g'), param.toLowerCase()));

          if (taxonomy.breadcrumbs) {
            taxonomy.breadcrumbs.map((breadcrumb: TaxonomyBreadcrumb) => {
              breadcrumb.name = this._capitalize(breadcrumb.name.replace(new RegExp('{param_' + key + '}', 'g'), param.toLowerCase()));

              if (breadcrumb.item) {
                breadcrumb.item = breadcrumb.item.replace(new RegExp('{param_' + key + '}', 'g'), param.toLowerCase());
              }

              return breadcrumb;
            });
          }
        });
      }

      if (options.paramsNoLowerCase) {
        options.paramsNoLowerCase.forEach((param: string, key: number) => {
          taxonomy.title = this._capitalize(taxonomy.title.replace(new RegExp('{param_nolowercase_' + key + '}', 'g'), param));
          taxonomy.description = this._capitalize(taxonomy.description.replace(new RegExp('{param_nolowercase_' + key + '}', 'g'), param));
          taxonomy.keywords = this._capitalize(taxonomy.keywords.replace(new RegExp('{param_nolowercase_' + key + '}', 'g'), param));

          if (taxonomy.breadcrumbs) {
            taxonomy.breadcrumbs.map((breadcrumb: TaxonomyBreadcrumb) => {
              breadcrumb.name = this._capitalize(breadcrumb.name.replace(new RegExp('{param_nolowercase_' + key + '}', 'g'), param));
              return breadcrumb;
            });
          }
        });
      }

      if (options.data) {
        if (options.data.title) taxonomy.title = options.data.title;
        if (options.data.description) taxonomy.description = options.data.description;
        if (options.data.keywords) taxonomy.keywords = options.data.keywords;
      }

      if (options.robots) taxonomy.robots = options.robots;
      if (options.canonical) taxonomy.canonical = options.canonical;
      if (options.ogImage) taxonomy.ogImage = options.ogImage;
    }

    this._title.setTitle(taxonomy.title);

    environment.production ?
      this._meta.updateTag({ name: 'robots', content: taxonomy.robots }):
      this._meta.updateTag({ name: 'robots', content: 'noindex,nofollow' });

    environment.production ?
      this._meta.updateTag({ name: 'description', content: taxonomy.description }):
      this._meta.updateTag({ name: 'description', content: taxonomy.description + ' (' + taxonomy.robots + ')' });

    this._meta.updateTag({ name: 'keywords', content: taxonomy.keywords });

    this._meta.updateTag({ property: 'og:type', content: name === '_default' ? 'website' : 'article' });
    this._meta.updateTag({ property: 'og:site_name', content: 'Teatrix' });
    this._meta.updateTag({ property: 'og:url', content: taxonomy.canonical });
    this._meta.updateTag({ property: 'og:title', content: taxonomy.title  });
    this._meta.updateTag({ property: 'og:description', content: taxonomy.description });
    this._meta.updateTag({ property: 'og:image', content: taxonomy.ogImage });
    this._meta.updateTag({ property: 'og:image:width', content: '1200' });
    this._meta.updateTag({ property: 'og:image:height', content: '630' });

    this._dom.querySelector('[rel="canonical"]').setAttribute('href', taxonomy.canonical);

    let breadcrumbs: any = {};

    if (taxonomy.breadcrumbs) {
      breadcrumbs = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Teatrix",
            "item": environment.url
          }
        ]
      };

      taxonomy.breadcrumbs.forEach((breadcrumb: TaxonomyBreadcrumb, key: number) => {
        let listItem: any = {
          "@type": "ListItem",
          "position": key + 2,
          "name": breadcrumb.name
        };

        if (breadcrumb.item) {
          listItem.item = breadcrumb.item;
        };

        breadcrumbs.itemListElement.push(listItem);
      });
    };

    this._dom.querySelector('#breadcrumbs').innerHTML = JSON.stringify(breadcrumbs);

    return {
      breadcrumbs: breadcrumbs
    }
  }

  private _capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
