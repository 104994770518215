import { Directive, ElementRef, Input, OnInit, OnChanges, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appLoadingBtn]'
})
export class LoadingBtnDirective implements AfterViewInit, OnChanges {

  @Input('appLoadingBtn') loading: boolean;
  @Input() selector: string = 'button';

  content: HTMLSpanElement;
  spinner: HTMLDivElement;

  constructor(
    private _elem: ElementRef,
  ) {
    //
  }

  ngAfterViewInit() {
    const content: string = `<span>${this.elem.innerHTML}</span>`;
    const spinner: string = `<div class="spinner-border" role="status" aria-hidden="true" style="
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 1rem;
      height: 1rem;
      border-width: 0.1rem;
    "></div>`;

    this.elem.innerHTML = content + spinner;
    this.elem.style.position = 'relative';

    this.content = this.elem.querySelector(this.selector + ' > span');
    this.spinner = this.elem.querySelector(this.selector + ' > div');

    this.loading ?
      this.setLoading():
      this.unsetLoading();
  }

  ngOnChanges(changes: any) {
    if (changes.loading) {
      this.loading ?
        this.setLoading():
        this.unsetLoading();
    }
  }

  get elem(): HTMLButtonElement {
    return this._elem.nativeElement;
  }

  /* -------------------- */

  setLoading(): void {
    if (this.content && this.spinner) {
      this.content.style.visibility = 'hidden';
      this.spinner.style.display = 'block';
    }
  }

  unsetLoading(): void {
    if (this.content && this.spinner) {
      this.content.style.visibility = 'visible';
      this.spinner.style.display = 'none';
    }
  }
}
