import { UntypedFormGroup, AbstractControl, UntypedFormArray } from '@angular/forms';
import { Request } from 'src/app/commons/request';
import { FormExtras, FormExtra } from '../types/form-extras';
import { FormConfig } from '../types/form-config';
import { FormState } from './form-state';
import { FormQueryParams } from './form-query-params';
import { FormOptions } from './form-options';
import { FormApiParams } from './form-api-params';
import { FormMapping } from './form-mapping';
import { FormArrays } from './form-arrays';
import * as _ from 'lodash';

export class Form {

  ready: boolean = false;
  request: Request = new Request();
  requestData: Request = new Request();
  requestCombos: Request = new Request();

  state: FormState = new FormState(this);
  options: FormOptions = new FormOptions(this);
  queryParams: FormQueryParams = new FormQueryParams(this);
  apiParams: FormApiParams = new FormApiParams(this);
  mapping: FormMapping = new FormMapping(this);
  arrays: FormArrays = new FormArrays(this);

  constructor(
    public group: UntypedFormGroup,
    public config?: FormConfig,
  ) {

    this.init();
  }

  /* -------------------- */

  init(): void {
    this.options.mapValues({ withInit: true });
    this.arrays.reset(true);
    this.state.set(this.group.getRawValue());
  }

  isReady(): boolean {
    return this.ready && this.options.areLoaded();
  }

  /* -------------------- */

  getExtras(): FormExtras {
    return this.config?.extras;
  }

  getExtra(name: string): FormExtra {
    return _.get(this.getExtras(), name, null);
  }

  getExtraName(name: string): string {
    return _.replace(name, /\.[0-9]*\./g, '.*.');
  }

  /* -------------------- */

  get(path: string | string[]): any {
    return _.get(this.getExtras(), path, null);
  }

  set(path: string | string[], value: any) {
    _.set(this.getExtras(), path, value);
  }

  /* -------------------- */

  getControlName(control: AbstractControl): string {
    if (!control.parent) return null;

    const formGroup = control.parent.controls;
    return _.find(_.keys(formGroup), (item => formGroup[item] === control));
  }

  getControlPath(control: AbstractControl, path: string = ''): string {
    path = this.getControlName(control) + path;

    if (!!control.parent && this.getControlName(control.parent)) {
      path = '.' + path;
      return this.getControlPath(control.parent, path);
    }

    return path;
  }

  getControlPathGeneric(control: AbstractControl): string {
    let path: string = this.getControlPath(control);
    path = path.replace(new RegExp('\.([0-9])+\.?', 'g'), '.*.');

    if (path.endsWith('.')) {
      path = path.slice(0, -1);
    }

    return path;
  }

  /* -------------------- */

  getControl(controlName: string): AbstractControl {
    return this.group.get(controlName);
  }

  getControlGroup(controlName: string): UntypedFormGroup {
    return this.group.get(controlName) as UntypedFormGroup;
  }

  getControlArray(controlName: string): UntypedFormArray {
    return this.group.get(controlName) as UntypedFormArray;
  }

  /* -------------------- */

  /**
   * @deprecated
   * Usar "form.state.setAndReset" para correcto armado de arrays.
   */
  patch(value: any, options: { withInit?: boolean, emitEvent?: boolean } = {}): void {
    this.group.patchValue(value, { emitEvent: options.emitEvent });
    this.persist(options.withInit);
  }

  /**
   * @deprecated
   * Usar "form.state.setAndReset" para correcto armado de arrays.
   */
  patchAndReset(value: any, options: { withInit?: boolean, emitEvent?: boolean } = {}): void {
    this.patch(value, options);
    this.reset(options);
  }

  reset(options: { withInit?: boolean, emitEvent?: boolean } = {}): void {
    this.request.reset();
    this.arrays.reset(options.withInit);

    setTimeout(() => {
      !!options.withInit ?
        this.group.reset(_.cloneDeep(this.state.init), { emitEvent: options.emitEvent }):
        this.group.reset(_.cloneDeep(this.state.current), { emitEvent: options.emitEvent });

      this.options.mapValues(options);
    });
  }

  resetMarks() {
    this.group.markAsPristine();
    this.group.markAsUntouched();
  }

  persist(withInit?: boolean): void {
    this.state.set(this.group.getRawValue(), withInit);
  }

  persistAndReset(withInit?: boolean): void {
    this.state.setAndReset(this.group.getRawValue(), { withInit });
  }

  persistControl(name: string, withInit?: boolean): void {
    this.state.setControl(name, this.group.get(name).value, withInit);
  }

  /* -------------------- */

  removeError(path: string, startsWith: boolean = true): void {
    if (!!this.request.error && !!this.request.error.errors) {
      this.request.error.errors = _.omit(this.request.error.errors, _.keys(
        _.pickBy(this.request.error.errors, (item: any, key: string) => {
          if (startsWith) {
            return key.startsWith(path);
          }

          return key === path;
        })
      ));

      if (_.isEmpty(this.request.error.errors)) {
        this.request.error = null;
        this.request.error$.emit(false);
      }
    }
  }
}
