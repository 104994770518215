import { Component, OnInit } from '@angular/core';
import { info } from 'src/app/info';
import { ChangeVersionModalService } from '../../modals/change-version-modal/change-version-modal.service';

@Component({
  selector: 'app-change-version-bar',
  templateUrl: './change-version-bar.component.html',
  styleUrls: ['./change-version-bar.component.scss']
})
export class ChangeVersionBarComponent implements OnInit {

  constructor(
    public changeVersionModalS: ChangeVersionModalService,
  ) { }

  ngOnInit(): void {
    //
  }

  get info() {
    return info;
  }
}
