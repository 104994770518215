import { IPlayVideo } from "../play.model";

export class PlayVideoMapper {

  static map(item: any): IPlayVideo {
    return {
      id: item.id,
      name: item.name,
      duration: parseInt(item.duration || item.seconds || '0'),
      watched_seconds: parseInt(item.progress?.seconds_watched || '0'),
      img_cover_url: item.cover_image,
      type_id: item.type,
      url: item.url || null,
      subtitles_url: item.subtitle || null,
    };
  };

  static mapMany(items: any[]): IPlayVideo[] {
    return items.map(value => PlayVideoMapper.map(value));
  };
}
