import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDefaultComponent } from './form-default.component';
import { FormElementsModule } from '../../form-elements/form-elements.module';



@NgModule({
  declarations: [FormDefaultComponent],
  imports: [
    CommonModule,
    FormElementsModule,
  ],
  exports: [FormDefaultComponent],
})
export class FormDefaultModule { }
