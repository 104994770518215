/**
 * Source: https://developer.ebanx.com/docs/resources/validation-rules/
 */

import { CreditCardTypeEnum } from "./enums/credit-card-type.enum";

export const creditCardType = {

  [`is${CreditCardTypeEnum.Amex}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^((34)|(37))/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Diners}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^((30)|(36)|(38))/, 'g');
    // const patternEx = new RegExp(/^((3646)|(3648))/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Mastercard}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/, 'g');

    // const patternEx = new RegExp(/^(514256|514586|526461|511309|514285|501059|557909|501082|589633|501060|501051|501016|589657|553839|525855|553777|553771|551792|528733|549180|528745|517562|511849|557648|546367|501070|601782|508143|501085|501074|501073|501071|501068|501066|589671|589633|588729|501089|501083|501082|501081|501080|501075|501067|501062|501061|501060|501058|501057|501056|501055|501054|501053|501051|501049|501047|501045|501043|501041|501040|501039|501038|501029|501028|501027|501026|501025|501024|501023|501021|501020|501018|501016|501015|589657|589562|501105|557039|542702|544764|550073|528824|522135|522137|562397|566694|566783|568382|569322|504363)/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Elo}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(50(67(1[589]|2[012456789]|3[01234569]|4[123567]|53|7[4568])|9(0(0[0137]|2[0-2]|3[59]|4[012578]|5[1235789]|6[013456789]|7[0134789]|8[047]|9[123489])|1(0[0456789]|46)|220|407))|6(27780|36368|5(0(0(3[12356789]|4[0-9]|5[01789]|6[0-9]|7[0-6])|4(0[6-9]|1[0-9]|2[0-9]|3[0-9]|8[5-9]|9[0-9])|5(0[012346789]|1[0-9]|2[0-9]|3[0-8]|5[2-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8])|72[0-7]|9(0[1-9]|1[0-9]|2[0128]|3[89]|4[6-9]|5[0-9]|6[0-9]|7[0-8]))|1(6(5[2-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])|70[0-4])|50(0[0-9]|1[0-9]|2[1-9]|3[0-9]|4[0-9]|5[0-7])))|65(0(0(4[89]|5[01789]|6[013456789]|7[0-6])|4(1[2-9]|2[0-9]|3[01238])|5(3[1-8]|5[2-9]|6[0-9]|7[0-6]|8[18]|9[4-8])|720|9(5[01236789]|6[0129]|7[02345678]))|1(6(62|7[0-4]|8[0-9]|9[0-9])|70[0-4])|50(05|1[189]|2[1-9]|3[0-9]|4[0-9]|5[0345])))/, 'g');

    // const patternEx = new RegExp(/^((509091)|(636368)|(636297)|(504175)|(438935)|(40117[8-9])|(45763[1-2])|(457393)|(431274)|(50990[0-2])|(5099[7-9][0-9])|(50996[4-9])|(509[1-8][0-9][0-9])|(5090(0[0-2]|0[4-9]|1[2-9]|[24589][0-9]|3[1-9]|6[0-46-9]|7[0-24-9]))|(5067(0[0-24-8]|1[0-24-9]|2[014-9]|3[0-379]|4[0-9]|5[0-3]|6[0-5]|7[0-8]))|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))|(6504(8[5-9]|9[0-9])|6505(0[0-9]|1[0-9]|2[0-9]|3[0-8]))|(6505(4[1-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))|(6507(0[0-9]|1[0-8]))|(65072[0-7])|(6509(0[1-9]|1[0-9]|20))|(6516(5[2-9]|6[0-9]|7[0-9]))|(6550(0[0-9]|1[0-9]))|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8])))/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Hipercard}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^((606282)|(637095)|(637568)|(637599)|(637609)|(637612))/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Visa}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(4)/, 'g');
    // const patternEx = new RegExp(/^((451416)|(438935)|(40117[8-9])|(45763[1-2])|(457393)|(431274)|(402934))/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Naranja}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(589562)/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Cabal}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^((627170)|(589657)|(603522)|(604((20[1-9])|(2[1-9][0-9])|(3[0-9]{2})|(400)))|(65008700)|(650900)|(650272([0-9]){2}))/, 'g');
    // const patternEx = new RegExp(/^(604201|604225|604246)/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Cencosud}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(603493)/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Shopping}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(27995)/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Cmr}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(557039)/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.Argencard}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^(501105)/, 'g');
    // const patternEx = new RegExp(/^((589562)|(527571)|(527572))/, 'g');

    return !!number.match(patternIn)?.length;
  },

  [`is${CreditCardTypeEnum.MercadoPago}`]: (number: string): boolean => {
    const patternIn = new RegExp(/^((515073)|(515070)|(532384))/, 'g');

    return !!number.match(patternIn)?.length;
  },
}
