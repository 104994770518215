import { Component, Input, OnInit } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';
import { AuthLoginModalService } from '../../core/auth/auth-login-modal/auth-login-modal.service';
import { NavbarService } from './navbar.service';
import { factory } from 'src/app/commons/factory';
import { PlayCategoryHttpService } from 'src/app/services/http/play-category-http.service';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() onlyLogo: boolean = false;
  @Input() withRegister: boolean = false;

  playsNotifications: Play[];

  constructor(
    public navbarS: NavbarService,
    public authLoginModalS: AuthLoginModalService,
    public storeS: StoreService,
    public navS: NavService,
    private _playCategoryHttpS: PlayCategoryHttpService,
    private _cacheS: CacheService,
  ) { }

  ngOnInit(): void {
    // this.playsNotifications = factory.createMany<Play>(PlaysMock, Play);
  }

  /* -------------------- */

  logout(): void {
    this.storeS.authUser.value?.logout(this.storeS, this._cacheS);

    this._playCategoryHttpS.get().toPromise();
  }
}
