import { Request } from "src/app/commons/request";
import { factory } from "./factory";

export interface IListPage<T> {
  data: T[];
  current_page?: number;
  last_page?: number;
  per_page?: number;
  from?: number;
  to?: number;
  total?: number;
}

export class List<T> {

  data: T[] = [];
  pages: IListPage<T>[] = [];
  currentPage: number = null;

  request: Request = new Request();

  /* -------------------- */

  constructor() {
  }

  /* -------------------- */

  get(number?: number): IListPage<T> {
    return this.pages.find(page => number ? page.current_page === number : page.current_page === this.currentPage);
  }

  add(page: IListPage<T>, className?: any): void {
    if (className) {
      page.data = factory.createMany<T>(page.data, className);
    }

    this.pages = this.pages.filter(innerPage => innerPage.current_page !== page.current_page);
    this.pages.push(page);

    this.currentPage = page.current_page;
  }

  set(data: any, className?: any): void {
    if (className) {
      data = factory.createMany<T>(data, className);
    }

    this.data = data;
    this.currentPage = null;
  }

  /* -------------------- */

  has(number?: number): boolean {
    return number
      ? this.pages.some(page => page.current_page === number)
      : this.pages.length > 0
  }

  /* -------------------- */

  reset(): void {
    this.data = [];
    this.pages = [];
    this.currentPage = null;
  }
}
