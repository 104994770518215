import { Component, OnInit } from '@angular/core';
import { Form } from 'src/app/packages/forms/tools/form';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';
import { AuthLoginModalService } from '../../auth-login-modal/auth-login-modal.service';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { AuthUserHttpService } from 'src/app/services/http/auth-user-http.service';
import { ToastService } from 'src/app/services/toast.service';
import { ApiErrorResponse } from 'src/app/services/http/core/http.service';
import { AuthUserSubscriptionHttpService } from 'src/app/services/http/auth-user-subscription-http.service';
import { finalize } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthSubscribeModalContentComponent } from '../auth-subscribe-modal-content/auth-subscribe-modal-content.component';

@Component({
  selector: 'app-auth-subscribe-modal-register-form',
  templateUrl: './auth-subscribe-modal-register-form.component.html',
  styleUrls: ['./auth-subscribe-modal-register-form.component.scss']
})
export class AuthSubscribeModalRegisterFormComponent implements OnInit {

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    public parent: AuthSubscribeModalContentComponent,
    public storeS: StoreService,
    public authLoginModalS: AuthLoginModalService,
    public navS: NavService,
    private _authUserHttpS: AuthUserHttpService,
    private _authUserSubscriptionHttpS: AuthUserSubscriptionHttpService,
    private _toastS: ToastService,
    private _analytic:AnalyticsService
  ) {


   }

  ngOnInit(): void {

    this._analytic.RegisterStep(1)
  }

  get formRegister(): Form {
    return this.parent.formRegister;
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  /* -------------------- */

  register(): void {
    if (this.formRegister.request.isLoading()) return;

    this.formRegister.resetMarks();

    const input = this.formRegister.group.value;

    this._sm.add(
      this.formRegister.request.send(this._authUserHttpS.registerValidate(input)).subscribe(() => {
        const err: ApiErrorResponse = this.formRegister.request.error;

        if (err) {
          if (err.status !== 422) {
            this._toastS.showHttpError(err);
          } else if (err.errors['email'] && err.errors['email'][0].includes('en uso')) {
            err.errors['email'][0] = 'Ya estás registrado, accedé con tu cuenta.';
          }
        } else {
          if (this.parent.isFreeRegister) {
            this._sm.clean('register');

            this.checkout();
          } else {
            this.formRegister.persist();
            this.parent.step = 2;
          }
        }
      }, (err: ApiErrorResponse) => {
        if (err.status !== 422) {
          this._toastS.showHttpError(err);
        }
      })
    , 'register');
  }

  checkout(): void {
    if (this.formRegister.request.isLoading()) return;

    this.formRegister.resetMarks();

    let method: string;
    let input: any;

    if (this.parent.isFreeRegister) {
      method = this.parent.useMocks ? 'mockCheckoutForFreeUser' : 'checkoutForFreeUser';

      input = this.formRegister.group.value;
    }

    this._sm.add(
      this.formRegister.request.send<any>(this._authUserSubscriptionHttpS[method](input)).subscribe(res => {
        const err: ApiErrorResponse = this.formRegister.request.error;

        if (err) {
          if (err.status !== 422) {
            this._toastS.showHttpError(err);
          }
        } else {
          this.formRegister.persist();

          if (!this.parent.storeS.authUser.value?.isLoggedIn()) {
            const inputLogin = {
              email: this.formRegister.state.current.email,
              password: this.formRegister.state.current.password,
              remember_me: true,
            };

            this._sm.clean('checkout');

            this._sm.add(
              this.formRegister.request.send(this._authUserHttpS.login(inputLogin)).pipe(
                finalize(() => {
                  this.parent.storeS.emit('authUser', 'checkoutSubscription');
                  this.parent.step = 4;
                })
              ).subscribe()
            , 'checkout');
          } else {
            this.parent.storeS.emit('authUser', 'checkoutSubscription');
            this.parent.step = 4;
          }
        }
      }, (err: ApiErrorResponse) => {
        if (err.status !== 422) {
          this._toastS.showHttpError(err);
        }
      })
    , 'checkout');
  }
}
