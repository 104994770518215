export const plays = {
  "el-cabaret-de-los-hombres-perdidos": 8,
  "caligula": 9,
  "los-amados": 10,
  "romeo-and-juliet-broadway": 12,
  "la-nona": 13,
  "cita-a-ciegas": 15,
  "venecia": 16,
  "juana-la-loca": 17,
  "el-loco-y-la-camisa": 20,
  "el-principio-de-arquimedes": 21,
  "maestra-normal": 22,
  "anda-jaleo": 23,
  "mas-canchero": 24,
  "chicos-catolicos-apostolicos-y-romanos": 25,
  "eran-tres-alpinos": 26,
  "esquizopena-intimidad-rioplatense": 27,
  "como-quien-oye-llover": 28,
  "la-casa-de-bernarda-alba": 29,
  "la-omision-de-la-familia-coleman": 30,
  "maria-elena": 32,
  "othelo": 33,
  "marica": 34,
  "el-senor-del-bano": 35,
  "tanguera-el-musical-argentino": 36,
  "tercer-cuerpo": 37,
  "cyrano-de-bergerac-broadway-hd": 39,
  "las-denfrente": 40,
  "alta-comedia-ciega-ambicion": 41,
  "salsa-criolla": 42,
  "nativo": 44,
  "alta-comedia-las-vueltas-de-la-vida": 45,
  "alta-comedia-el-engano": 46,
  "alta-comedia-mas-alla-de-todo": 47,
  "alta-comedia-sueno-de-navidad": 48,
  "alta-comedia-las-tres-hermanas": 49,
  "alta-comedia-la-marca-del-orillo": 50,
  "alta-comedia-hoy-digo-basta": 51,
  "alta-comedia-persona-no-grata": 52,
  "alta-comedia-sin-salida": 53,
  "alta-comedia-la-sangre-de-los-ortiz": 54,
  "alta-comedia-seis-personajes-en-busca-de-un-autor": 55,
  "el-gran-deschave": 56,
  "los-dias-de-julian-bisbal": 58,
  "el-acompanamiento": 62,
  "la-mala-sangre": 63,
  "Medea-en-Manhattan": 64,
  "mujeres-de-ceniza": 65,
  "campi": 66,
  "incident-at-vichy": 67,
  "yiya": 68,
  "que-lo-pario": 69,
  "dracula-el-musical-cibrian-mahler": 71,
  "homenaje-a-alejandro-romay": 73,
  "taquicardia-kbaret-post-apokaliptico": 75,
  "el-violinista-en-el-tejado": 76,
  "wunderkindz-el-encuentro": 77,
  "buried-child": 78,
  "wilde": 79,
  "nada-del-amor-me-produce-envidia": 80,
  "pieza-plastica": 81,
  "bajo-terapia": 82,
  "dorian-gray-el-retrato": 85,
  "victor-victoria": 86,
  "el-hombre-de-la-mancha": 87,
  "el-diario-privado-de-adan-y-eva": 88,
  "she-loves-me": 91,
  "antiheroe-off": 92,
  "todas-las-rayuelas": 93,
  "en-boca-cerrada": 94,
  "cactus-orquidea": 95,
  "la-denuncia": 100,
  "llegue-para-irme": 101,
  "bilac-ve-estrellas": 102,
  "pura-ficcion": 103,
  "el-padre": 104,
  "filosofia-de-vida": 106,
  "rey-lear": 107,
  "dias-contados": 108,
  "el-botella": 109,
  "saltimbanquis": 110,
  "el-jorobado-de-paris": 112,
  "vivitos-y-coleando": 113,
  "las-mil-y-una-noches": 114,
  "otelo-el-musical": 115,
  "a-beira-do-abismo-me-cresceram-asas": 116,
  "a-escola-do-escandalo": 117,
  "ella-en-mi-cabeza": 118,
  "popcorn": 119,
  "justo-en-lo-mejor-de-mi-vida": 120,
  "abalou-bangu": 121,
  "present-laughter": 122,
  "la-madre-que-los-pario": 123,
  "marco-polo": 124,
  "la-sala-roja": 126,
  "lord": 127,
  "todas-las-canciones-de-amor": 128,
  "el-pequeno-poni": 129,
  "casados-sin-hijos": 130,
  "la-discreta-enamorada": 131,
  "mama-decia": 133,
  "las-canciones-que-nunca-volvi-a-cantar": 134,
  "nerium-park": 135,
  "amado-mio": 138,
  "contemplo-la-nieve-que-cae-blandamente": 139,
  "araoz-y-la-verdad": 140,
  "tita-una-vida-en-tiempo-de-tango": 141,
  "el-precio2": 142,
  "pelonintensivo": 143,
  "from-here-to-eternity": 145,
  "isabel-de-guevara": 146,
  "tango-corrupto-y-a-otra-cosa-mariposa": 147,
  "un-rato-con-el": 148,
  "yo-no-duermo-la-siesta": 150,
  "jettatore": 151,
  "indecent": 152,
  "compania": 153,
  "la-desgracia": 154,
  "una-mujer-de-otro-mundo": 155,
  "bossi-big-bang-show": 156,
  "salvajes": 158,
  "por-que-sera-que-las-queremos-tanto": 159,
  "portuguese-kid": 160,
  "labios-negros": 161,
  "moscu": 162,
  "simplemente-eladia": 163,
  "coronado-de-gloria": 164,
  "vuelo-a-capistrano": 165,
  "el-viento-en-los-sauces": 166,
  "juegos-de-amor-y-de-guerra": 167,
  "el-elogio-de-la-risa": 168,
  "maxi-5-mas-que-un-stand-up": 169,
  "asi-de-simple": 170,
  "un-final-feliz": 171,
  "esquizopena-duele": 172,
  "rotos-de-amor": 173,
  "j-timerman": 176,
  "viva-la-vida": 177,
  "terapia-amorosa": 178,
  "un-enemigo-del-pueblo": 179,
  "el-diccionario": 181,
  "mucho-ruido-y-pocas-nueces": 182,
  "lo-quiero-ya": 183,
  "national-pastime": 184,
  "loop-amor-sin-fin": 185,
  "dona-disparate-y-bambuco": 186,
  "cepo-centro-experimental-para-oscuros": 187,
  "a-mecanica-das-borboletas": 188,
  "excalibur-una-leyenda-musical": 189,
  "los-albornoz-delicias-de-una-familia-argentina": 190,
  "conexion-marilyn-monroe": 192,
  "yatencontrare": 193,
  "trio-en-mi-bemol": 195,
  "un-marido-ideal": 196,
  "mi-mujer-se-llama-mauricio": 197,
  "una-magnifica-desolacion": 211,
  "unipergonal": 212,
  "stefano": 213,
  "los-amantes-de-la-casa-azul": 214,
  "trastorno": 215,
  "gardel-teatro-musical-argentino": 217,
  "fuera-de-linea": 218,
  "la-lechuga": 219,
  "Mucho": 220,
  "mucho_cba_stafe": 221,
  "mucho_mza": 222,
  "diego-reinhold-comedy-show": 228,
  "como-si-pasara-un-tren15": 230,
  "vivitos_y_coleando_2": 231,
  "maria-elena_2": 232,
  "el-botella_2": 234,
  "por-el-nombre-del-padre": 236,
  "yatencontrare_2": 237,
  "saltimbanquis_2": 238,
  "dona-disparate-y-bambuco_2": 239,
  "andres-ini-presenta-tributo-a-mi": 240,
  "historias-de-divan": 241,
  "musiquitas": 242,
  "brujas": 243,
  "manzi-la-vida-en-orsai": 244,
  "franz-y-albert": 245,
  "la-gran-farandula": 246,
  "un-enemigo-del-pueblo-ppv": 247,
  "fatima-superstar": 250,
  "othelo-fiba": 252,
  "yo-no-duermo-la-siesta-fiba": 253,
  "la-omision-de-la-familia-coleman-fiba": 255,
  "acaloradas": 256,
  "pucha": 257,
  "como-hace-3000-anos": 258,
  "el-amor-sos-vos": 259,
  "la-hora-de-la-calabaza": 260,
  "los-monstruos": 261,
  "muchas-pelucas-para-un-solo-calvo": 262,
  "la-celosa-de-si-misma": 263,
  "tu-cuna-fue-un-conventillo": 264,
  "la-boheme-en-tacos": 265,
  "airefuego": 266,
  "sr-mikozzi-especial-de-pandemia": 267,
  "el-paraiso-de-la-invencion": 268,
  "fiebre-hamilton": 269,
  "te-aconsejo-que-me-olvides": 270,
  "homenaje-a-hugo-arana-todas-las-rayuelas": 271,
  "el-plan-rodolfo": 272,
  "fin-de-engano": 273,
  "avant-premiere-te-aconsejo-que-me-olvides": 274,
  "pure-100-belloso": 275,
  "protocolo-macoco": 276,
  "el-amor-en-los-tiempos-del-colesterol": 277,
  "picadito-de-picotto": 278,
  "cuareterna-con-gonal": 279,
  "de-eso-no-se-canta": 280,
  "parasitos": 281,
  "cachorro-de-leon": 282,
  "el-hilador": 283,
  "a-ocho-columnas": 284,
  "toquinho-intimo-para-argentina": 285,
  "entre-ella-y-yo": 286,
  "un-instante-sin-dios": 287,
  "reconocernos": 288,
  "mentiras-que-menti": 289,
  "el-bululu-antologia-endiablada": 290,
  "ay-amor-divino": 291,
  "auto-sex": 292,
  "hermans": 293,
  "hermans-version-ellos": 294,
  "cuando-llueve": 295,
  "herederos": 296,
  "bergman-y-liv-correspondencia-amorosa": 297,
  "el-fixer": 299,
  "la-traviata": 300,
  "piensa-en-mi-una-historia-de-boleros": 310,
  "dios-mio": 311,
  "relaciones-peligrosas": 312,
  "una": 313,
  "flashback": 315,
  "mi-don-imaginario": 316,
  "maten-a-hamlet": 317,
  "lluvia-de-alegrias": 318,
  "el-pozo-de-los-mil-demonios": 319,
  "rita": 321,
  "solita-y-sola": 322,
  "solo-llame-para-decirte-que-te-amo": 323,
  "bossi-master-show-2019": 324,
  "dispuestos-a-todo": 325,
  "eva-y-victoria": 326,
  "ahata-aju": 327,
  "la-revelacion": 328,
  "mamushka": 329,
  "gente-feliz": 330,
  "hombres-y-ratones": 331,
  "dos-velas": 332,
  "the-red-shoes": 333,
  "cinderella": 334,
  "romeo-juliet": 335,
  "swan-lake": 336,
  "the-car-man": 337,
  "siempre-que-llovio-paro": 338,
  "los_monstruos": 339,
  "como_si-pasara-un-tren15": 340,
  "antisocial": 341,
  "yo-elegi-ser-evita": 342,
  "hoy-como-ayer": 343,
  "moscu-version-nacional": 344,
  "teresita-una-vida-de-mierda": 347,
  "doble-o-nada": 348,
  "el-amor-es-una-mierda": 350,
  "antes-de-que-me-olvide": 351,
  "hay-un-complot": 352,
  "delia": 353,
  "los-tutores": 354,
  "miguel-de-molina-al-desnudo": 355,
  "dijeron-de-mi": 356,
  "aida": 357,
  "cosi-fan-tutte": 358,
  "la-traviata-2020": 359,
  "i-puritani": 360,
  "lucia-di-lammermoor": 361,
  "pergolesi-y-vivaldi-en-la-catedral-de-burgos": 362,
  "turandot": 363,
  "roberto-devereux": 364,
  "parsifal": 365,
  "il-trovatore": 366,
  "los-soviets-de-san-antonio": 369,
  "bomarzo": 371,
  "alma-de-artista": 374,
  "ciclo-jose-abadi-edipo": 378,
  "cavalleria-rusticana": 380,
};
