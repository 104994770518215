import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';

@Injectable({
  providedIn: 'root'
})
export class NewsletterHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
  ) { }

  /* -------------------- */

  save(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send(this._httpClient.post(this._httpS.getEndpoint(`/v2/saveNewsletter`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }
}
