import { Component, Input, OnInit } from '@angular/core';
import { Request } from 'src/app/commons/request';

@Component({
  selector: 'app-button-form',
  templateUrl: './button-form.component.html',
  styleUrls: ['./button-form.component.scss']
})
export class ButtonFormComponent implements OnInit {

  @Input() icon: string;
  @Input() request: Request;
  @Input() color: string = 'btn-app-red';
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
