<button type="button" class="d-md-none btn-close btn-close-dark" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
<button type="button" class="d-none d-md-flex btn-close btn-close-light" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>

<div class="grid modal-body p-0 text-dark">
    <div class="row g-0">
        <div class="login col-60 col-md-40 d-flex flex-column bg-white _py-4 _px-2">
            <h3 class="_fs-20 mb-5 title-line-bottom-red"><strong class="fw-bolder">
                {{ !mustShowFormUpdatePassword ? 'Recuperar' : 'Actualizar' }} contraseña
            </strong></h3>

            <ng-container *ngIf="!mustShowFormUpdatePassword">
                <app-form [form]="form" (submitted$)="sendEmail()">
                    <div class="pb-4">
                        <div class="row">
                            <app-form-input class="col-60 mb-3" type="email" label="Email" controlName="email"></app-form-input>
                        </div>
                    </div>
                </app-form>

                <div class="d-md-flex align-items-center mt-auto">
                    <app-button-form [request]="form.request" (click)="sendEmail()">
                        Recuperar
                    </app-button-form>

                    <a class="d-block d-md-inline link-gray-600 lh-md mt-3 mt-md-0 ms-md-3 _fs-14" (click)="authLoginModalS.open()" (keyup.enter)="authLoginModalS.open()" tabindex="0">¡Ya la recuerdo!</a>
                </div>
            </ng-container>

            <ng-container *ngIf="mustShowFormUpdatePassword">
                <app-form [form]="form" (submitted$)="updatePassword()">
                    <div class="pb-4">
                        <div class="row">
                            <app-form-input class="col-60 mb-3" type="password" label="Nueva contraseña" controlName="password"></app-form-input>
                            <app-form-input class="col-60 mb-3" type="password" label="Confirmar nueva contraseña" controlName="password_confirmation"></app-form-input>
                        </div>
                    </div>
                </app-form>

                <div class="d-md-flex align-items-center mt-auto">
                    <app-button-form [request]="form.request" (click)="updatePassword()">
                        Actualizar
                    </app-button-form>

                    <a class="d-block d-md-inline link-gray-600 lh-md mt-3 mt-md-0 ms-md-3 _fs-14" (click)="authLoginModalS.open()" (keyup.enter)="authLoginModalS.open()" tabindex="0">¡Ya la recuerdo!</a>
                </div>
            </ng-container>
        </div>

        <div class="subscribe col-60 col-md-20 d-flex flex-column bg-app-red text-white _py-4 _px-2 mt-md-0 mt-auto">
            <div class="pb-md-4">
                <h3 class="_fs-20 mb-md-5 title-line-bottom-dark"><strong class="fw-bolder">¿Aún no eres socio?</strong></h3>

                <div class="_fs-14">
                    <p class="d-none d-md-block">¿Qué esperás para disfrutar del mejor teatro sin importar donde estés?</p>
                    <p>Creá tu cuenta ahora por solo <strong>{{ info.subscriptionPrice }}</strong> por mes.</p>
                </div>
            </div>

            <div class="mt-auto">
                <button class="d-block w-100 btn btn-dark rounded-2 _fs-16" [routerLink]="navS.pages.SubscribePage.link()">
                    <strong>Suscribirme</strong>
                </button>
            </div>
        </div>
    </div>
</div>
