import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import { of } from 'rxjs';
import { combosHttpServiceMock } from './mocks/mocks';
import { delay } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root'
})
export class CombosHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
    private _storeS: StoreService,
  ) { }

  /* -------------------- */

  getSubscriptionPlans(params?: any) {
    const tokenType: TokenType = this._storeS.authUser.value?.isLoggedIn() ? 'authUserToken' : 'authGuestToken';
    const paramsParsed = this._httpS.parseParams(params);

    if (this._httpS.useMocks) {
      return of(cloneDeep(combosHttpServiceMock.getSubscriptionPlans)).pipe(delay(200));
    }

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/users/getPlansStep?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }
}
