import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { HoverPreviewService } from './hover-preview.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-hover-preview',
  templateUrl: './hover-preview.component.html',
  styleUrls: ['./hover-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HoverPreviewComponent implements OnInit {

  elem: HTMLElement;

  position = {
    top: '0px',
    left: '0px',
    right: '0px',
    width: '0px',
  };

  private _transitionTimeoutFix: any;
  private _transitionEndTimeoutFix: any;

  constructor(
    private _elem: ElementRef,
    public hoverPreviewS: HoverPreviewService
  ) { }

  ngOnInit(): void {
    this.hoverPreviewS.elem.subscribe((elem: HTMLElement) => {
      this.hoverPreviewElem.classList.remove('animate__show', 'animate__hide');

      this.elem = elem;

      if (this.elem) {
        this.resolveScale();

        const elemPosition = this.getElemPosition();

        this.position.width = elemPosition.width * (1 + this.hoverPreviewS.scale) + 'px';
        this.position.top = elemPosition.top + 'px';

        if (elemPosition.left < elemPosition.width) {
          this.position.left = elemPosition.left + 'px';
          this.position.right = null;

          this.hoverPreviewElem.style.transform = this.getInitialTransformOnLeft();
        }
        else if (elemPosition.right < elemPosition.width) {
          this.position.left = null;
          this.position.right = elemPosition.right + 'px';

          this.hoverPreviewElem.style.transform = this.getInitialTransformOnRight();
        }
        else {
          this.position.left = elemPosition.left - (elemPosition.width * this.hoverPreviewS.scale / 2) + 'px';
          this.position.right = null;

          this.hoverPreviewElem.style.transform = this.getInitialTransform();
        }

        this._transitionTimeoutFix = setTimeout(() => this.showHoverPreviewElem(), 50);
      }
    });
  }

  get hoverPreviewElem(): HTMLElement {
    return (this._elem.nativeElement as HTMLElement).firstElementChild as HTMLElement;
  }

  get hoverPreviewOpacityElem(): HTMLElement {
    return this.hoverPreviewElem.querySelector('.hover-preview-opacity') as HTMLElement;
  }

  /* -------------------- */

  resolveScale(): void {
    const elemPosition = this.getElemPosition();
    let positionWidth = elemPosition.width * (1 + this.hoverPreviewS.scale);

    if (typeof this.hoverPreviewS.minWidth !== 'undefined' && positionWidth < this.hoverPreviewS.minWidth) {
      this.hoverPreviewS.scale = this.hoverPreviewS.minWidth / elemPosition.width - 1;
    }
    else if (typeof this.hoverPreviewS.maxWidth !== 'undefined' && positionWidth > this.hoverPreviewS.maxWidth) {
      this.hoverPreviewS.scale = 400 / elemPosition.width - 1;

      positionWidth = elemPosition.width * (1 + this.hoverPreviewS.scale);

      if (positionWidth < elemPosition.width) {
        this.hoverPreviewS.scale = 0;
      }
    }
  }

  /* -------------------- */

  showHoverPreviewElem(): void {
    clearTimeout(this._transitionEndTimeoutFix);

    this.elem.style.visibility = 'hidden';

    this.hoverPreviewElem.classList.add('animate__show');
    this.hoverPreviewElem.style.transform = `translateY(-25%)`;

    this.hoverPreviewOpacityElem.classList.add('animate__show');
    this.hoverPreviewOpacityElem.style.opacity = '1';
  }

  hideHoverPreviewElem(): void {
    clearTimeout(this._transitionEndTimeoutFix);

    this.hoverPreviewElem.classList.add('animate__hide');

    const elemPosition = this.getElemPosition();

    if (elemPosition.left < elemPosition.width) {
      this.hoverPreviewElem.style.transform = this.getInitialTransformOnLeft();
    } else if (elemPosition.right < elemPosition.width) {
      this.hoverPreviewElem.style.transform = this.getInitialTransformOnRight();
    } else {
      this.hoverPreviewElem.style.transform = this.getInitialTransform();
    }

    this.hoverPreviewOpacityElem.classList.add('animate__hide');
    this.hoverPreviewOpacityElem.style.opacity = '0';

    this.hoverPreviewElem.ontransitionend = this.hoverPreviewElemOntransitionEvent;
    this.hoverPreviewOpacityElem.ontransitionend = this.hoverPreviewOpacityElemOntransitionEvent;

    /** Fix en caso de que el evento transitionend no se dispare. */
    /** Cambiar el tiempo según el tiempo asignado a la transición en la hoja de estilos. */
    this._transitionEndTimeoutFix = setTimeout(() => {
      if (this.hoverPreviewS.templateRef) {
        this.hoverPreviewS.templateRef = null;
        this.hoverPreviewS.elemNext(null);
      }
    }, 300);
  }

  /* -------------------- */

  hoverPreviewElemOntransitionEvent = (event: TransitionEvent) => {
    if (this.hoverPreviewElem?.classList.contains('animate__show')) {
      if (event.propertyName === 'transform') {
        this.hoverPreviewElem.classList.remove('animate__show');
      }
    }

    if (this.hoverPreviewElem?.classList.contains('animate__hide')) {
      if (event.propertyName === 'transform') {
        this.hoverPreviewElem.classList.remove('animate__hide');
      }
    }
  }

  hoverPreviewOpacityElemOntransitionEvent = (event: TransitionEvent) => {
    if (this.hoverPreviewOpacityElem?.classList.contains('animate__show')) {
      if (event.propertyName === 'opacity') {
        this.hoverPreviewElem.classList.remove('animate__show');
      }
    }

    if (this.hoverPreviewOpacityElem?.classList.contains('animate__hide')) {
      if (event.propertyName === 'opacity') {
        this.hoverPreviewOpacityElem.classList.remove('animate__hide');

        /** Estas líneas deben ir sólo en la transición que más tarde en terminar su ciclo. */
        this.hoverPreviewS.templateRef = null;
        this.hoverPreviewS.elemNext(null);
      }
    }
  }

  /* -------------------- */

  getElemPosition() {
    return {
      ...this.elem.getBoundingClientRect().toJSON(),
      top: this.elem.getBoundingClientRect().top + window.scrollY,
      right: document.documentElement.clientWidth - this.elem.getBoundingClientRect().right,
    };
  }

  getInitialScaleValue(): number {
    const elemPosition = this.getElemPosition();
    return _.round(elemPosition.width / (elemPosition.width * (1 + this.hoverPreviewS.scale)), 2);
  }

  getInitialTransformValue(): number {
    return (1 - this.getInitialScaleValue()) * 100 / 2 * -1
  }

  getInitialTransform(): string {
    return `
      translateY(${this.getInitialTransformValue()}%)
      scale(${this.getInitialScaleValue()})
    `;
  }

  getInitialTransformOnLeft(): string {
    return `
      translateY(${this.getInitialTransformValue()}%)
      translateX(${this.getInitialTransformValue()}%)
      scale(${this.getInitialScaleValue()})
    `;
  }

  getInitialTransformOnRight(): string {
    return `
      translateY(${this.getInitialTransformValue()}%)
      translateX(${this.getInitialTransformValue() * -1}%)
      scale(${this.getInitialScaleValue()})
    `;
  }

  /* -------------------- */

  @HostListener('mouseleave')
  onMouseLeave(): void {
    clearTimeout(this.hoverPreviewS.timeout);
    clearTimeout(this._transitionTimeoutFix);

    this.hideHoverPreviewElem();
  }
}
