<button type="button" class="btn-close btn-close-dark" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>

<div class="grid modal-body p-0 text-dark">
    <div class="row g-0 align-items-center">
        <ng-container *ngIf="step < 4">
            <div class="d-none d-lg-block col-35 multiplatform-img">
                <img class="img-fluid" src="assets/img/bg-auth-subscribe-modal-1.webp" alt="Multiplataformas" >
            </div>

            <div class="content grid grid-tb-inverse col col-lg-25">
                <div>
                    <div class="d-md-none">
                        <img class="_pb-2" src="assets/img/teatrix-logo-black.webp" alt="Teatrix" >
                    </div>

                    <app-auth-subscribe-modal-step-counter *ngIf="!isFreeRegister" [step]="step"></app-auth-subscribe-modal-step-counter>
                </div>

                <app-auth-subscribe-modal-register-form *ngIf="step === 1"></app-auth-subscribe-modal-register-form>
                <app-auth-subscribe-modal-subscribe-form *ngIf="step === 2 && !isFreeRegister"></app-auth-subscribe-modal-subscribe-form>
                <app-auth-subscribe-modal-checkout-form *ngIf="step === 3 && !isFreeRegister"></app-auth-subscribe-modal-checkout-form>
            </div>
        </ng-container>

        <ng-container *ngIf="step === 4">
            <div class="d-none d-lg-block col-37 py-5">
                <img class="img-fluid" src="assets/img/bg-auth-subscribe-modal-2.webp" alt="Multiplataformas" >
            </div>

            <div class="content grid grid-tb col col-lg-23 text-center">
                <ng-container *ngIf="isPpv">
                    <app-icon class="justify-content-center" name="teatrix-logo" [size]="3"></app-icon>

                    <h3 class="_fs-36" style="line-height: -1;"><strong class="fw-bolderest">PPV disponible<div class="_fs-20">{{ play.title }}</div></strong></h3>
                    <p class="ff-opensans-r">Esta obra se estrena el <strong class="ff-opensans-b">{{ play.ppv.starts_at | date:'longDate' }}</strong> y se mantendrá disponile para que puedas verla <strong class="ff-opensans-b">por un periodo de {{ play.ppv.duration | appDays }}</strong>.<br><br>Seguí disfrutando del Teatro en tu casa.</p>

                    <img class="d-lg-none img-fluid" src="assets/img/bg-auth-subscribe-modal-2.webp" alt="Multiplataformas" >

                    <a class="d-block mt-5" [routerLink]="[navS.pages.PlayDetailPage.link(play)]" (click)="modal.close()">
                        <app-button-form>Ir al detalle de la Obra</app-button-form>
                    </a>
                </ng-container>

                <ng-container *ngIf="!isPpv">
                    <ng-container *ngIf="isPaymentMethodOnline && !storeS.authUser.value?.user.status.is_pending">
                        <app-icon class="justify-content-center" name="teatrix-logo" [size]="3"></app-icon>

                        <h3 class="_fs-36"><strong class="fw-bolderest">¡Bienvenido!</strong></h3>
                        <p class="ff-opensans-r">Ya podés empezar a disfrutar del Teatro en tu casa.<br><br>Completá tu perfil para recibir nuestras novedades.</p>

                        <img class="d-lg-none img-fluid" src="assets/img/bg-auth-subscribe-modal-2.webp" alt="Multiplataformas" >

                        <a class="d-block mt-5" [routerLink]="[navS.pages.MyAccountPage.link()]" (click)="modal.close()">
                            <app-button-form>Mi Perfil</app-button-form>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="isPaymentMethodOffline || storeS.authUser.value?.user.status.is_pending">
                        <app-auth-subscribe-modal-step-counter innerClass="justify-content-md-center" [step]="3"></app-auth-subscribe-modal-step-counter>

                        <ng-container *ngIf="!storeS.authUser.value?.user.status.is_pending_credit_card">
                            <ng-container *ngIf="storeS.authUser.value?.user.payment_coupon_url">
                                <h3 class="_fs-36"><strong class="fw-bolderest">Reimprimir cupón</strong></h3>
                            </ng-container>

                            <ng-container *ngIf="!storeS.authUser.value?.user.payment_coupon_url">
                                <h3 class="_fs-36"><strong class="fw-bolderest">¡Cupón descargado!</strong></h3>
                            </ng-container>

                            <div class="ff-opensans-r">
                                <p>Tu suscripción está pendiente de aprobación.</p>
                                <p><strong class="ff-opensans-b">Recordá que en 15 días se vence el cupón que descargaste.</strong></p>
                                <p>Si ya abonaste y todavía no podes acceder, comunicate con el centro de atención al cliente para poder acceder a tu cuenta.</p>
                                <p><strong class="ff-opensans-b">Centro de atención: {{ info.phoneNumber1 }}.</strong></p>
                            </div>

                            <a class="d-block mt-4" [href]="paymentMethodOfflineDownloadLink">
                                <app-button-form>Volver a descargar cupón</app-button-form>
                            </a>

                            <ng-container *ngIf="storeS.authUser.value?.user.payment_coupon_url">
                                <a class="link-gray-500 mt-3" (click)="step = 2">
                                    Cambiar método de pago
                                </a>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="storeS.authUser.value?.user.status.is_pending_credit_card">
                            <h3 class="_fs-36"><strong class="fw-bolderest">Suscripción en proceso</strong></h3>
                            <div class="ff-opensans-r">
                                <p>Tu suscripción está pendiente de aprobación.</p>
                                <p><strong class="ff-opensans-b">Volvé a ingresar dentro de un rato para comprobar el estado.</strong></p>
                                <p>También podés comunicarte con el centro de atención al cliente.</p>
                                <p><strong class="ff-opensans-b">Centro de atención: {{ info.phoneNumber1 }}.</strong></p>
                            </div>

                            <a class="d-block mt-5" [routerLink]="[navS.pages.MyAccountPage.link()]" (click)="modal.close()">
                                <app-button-form>Mi Perfil</app-button-form>
                            </a>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
