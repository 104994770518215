import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import { of } from 'rxjs';
import { playCategoryHttpServiceMock } from './mocks/mocks';
import { delay, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { factory } from 'src/app/commons/factory';
import { PlayCategory } from 'src/app/core/play-category/play-category.model';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root'
})
export class PlayCategoryHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
    private _storeS: StoreService,
  ) { }

  /* -------------------- */

  get(params?: any) {
    const tokenType: TokenType = this._storeS.authUser.value?.isLoggedIn() ? 'authUserToken' : 'authGuestToken';

    const paramsParsed = this._httpS.parseParams(params);

    if (this._httpS.useMocks) {
      return of(cloneDeep(playCategoryHttpServiceMock.get)).pipe(delay(200));
    }

    return this._httpS.send<any>(this._httpClient.get(this._httpS.getEndpoint(`/categories/all?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType).pipe(
      map((res: any) => {
        const playCategories = factory.createMany([
          ...res.data.categories,
          ...res.data.countries.map((item: any) => { return { ...item, is_international: true, international_flag: item.image } }),
          {
            id: 9001,
            name: 'Obras PPV',
          }
        ], PlayCategory);

        this._storeS.set('playCategories', playCategories, false, 60 * 24);

        return res;
      })
    );
  }
}
