<ng-container *ngIf="mustShow()">
    <div id="subscribe-bar">
        <ng-container *ngIf="!storeS.authUser.value?.isLoggedIn() || storeS.authUser.value?.user.permissions.subscribe_from_bar">
            <ng-container *ngIf="(isPromoPage || isPromoInterPage) && !storeS.authUser.value?.user.status.is_cancel">
                <a [routerLink]="'/registro'" [queryParams]="{ code: info.subscriptionPromoCode, step2: 'true' }">
                    <div class="subscribe-bar d-lg-flex text-marginless p-1">
                        <p class="text-white text-decoration-underline">
                            <app-icon *ngIf="!isPromoInterPage" onleft name="caret-right-white"><strong class="text-uppercase me-md-3">Suscribite ahora 50% OFF</strong></app-icon>
                            <app-icon *ngIf="isPromoInterPage" onleft name="caret-right-white"><strong class="text-uppercase me-md-3">Suscríbete ahora 50% OFF</strong></app-icon>
                        </p>

                        <p class="text-white _fs-14">
                            <span class="d-md-none">Por solo <span style="text-decoration:line-through; text-decoration-color: black;">{{ info.subscriptionPriceSubscribeBar }}</span><strong> {{ info.subscriptionPromoAmount }} por mes</strong> y sin límites.</span>
                            <span class="d-none d-md-inline">solo <span style="text-decoration:line-through; text-decoration-color: black;">
                                {{ info.subscriptionPriceSubscribeBar }}</span><strong> {{ info.subscriptionPromoAmount }} por mes</strong>, sin límites,
                                <span *ngIf="!isPromoInterPage">cancelás</span>
                                <span *ngIf="isPromoInterPage">cancelas</span>
                                tu cuenta con un solo click.
                            </span>
                        </p>
                    </div>
                </a>
            </ng-container>

            <ng-container *ngIf="!isPromoPage && !isPromoInterPage">
                <a [routerLink]="navS.pages.SubscribePage.link()">
                    <div class="subscribe-bar d-lg-flex text-marginless p-1" [class.green]="storeS.authUser.value?.user.status.is_cancel">
                        <p class="text-white text-decoration-underline">
                            <ng-container *ngIf="!storeS.authUser.value?.user.status.is_cancel">
                                <app-icon onleft name="caret-right-white"><strong class="text-uppercase me-md-3">Suscribite ahora</strong></app-icon>
                            </ng-container>

                            <ng-container *ngIf="storeS.authUser.value?.user.status.is_cancel">
                                <app-icon onleft name="caret-right-white"><strong class="text-uppercase me-md-3">Reactivá tu suscripción</strong></app-icon>
                            </ng-container>
                        </p>

                        <p class="text-white _fs-14">
                            <span>{{ info.subscriptionPriceSubscribeBar }} por mes. {{ info.subscriptionDescription | appHtmlStrip }}</span>
                        </p>
                    </div>
                </a>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="storeS.authUser.value?.user.permissions.cancel_unsubscription">
            <a [routerLink]="navS.pages.MyAccountPage.link()">
                <div class="subscribe-bar d-lg-flex text-marginless p-1">
                    <p class="text-white text-decoration-underline">
                        <app-icon onleft name="caret-right-white"><strong class="text-uppercase me-md-3">Cancelá tu proceso de baja</strong></app-icon>
                    </p>

                    <p class="text-white _fs-14">
                        <span class="d-md-none">Mantené tu suscripción por {{ info.subscriptionPriceSubscribeBar }} por mes.</span>
                        <span class="d-none d-md-inline">{{ info.subscriptionPriceSubscribeBar }} por mes, sin límites, cancelás tu cuenta con un solo click.</span>
                    </p>
                </div>
            </a>
        </ng-container>

        <ng-container *ngIf="storeS.authUser.value?.user.status.is_pending">
            <a [routerLink]="navS.pages.SubscribePage.link()" [queryParams]="storeS.authUser.value?.user.status.is_pending_credit_card ? { changePaymentMethod: true } : null">
                <div class="subscribe-bar green d-lg-flex text-marginless p-1">
                    <ng-container *ngIf="!storeS.authUser.value?.user.status.is_pending_credit_card">
                        <p class="text-white text-decoration-underline">
                            <app-icon onleft name="caret-right-white"><strong class="text-uppercase me-md-3">Reimprimir cupón</strong></app-icon>
                        </p>

                        <p class="text-white _fs-14">
                            <span class="d-md-none">Tu suscripción está pendiente de pago.</span>
                            <span class="d-none d-md-inline">Tu suscripción se encuentra pendiente de pago, podés reimprimir tu cupón o cambiar tu método de pago.</span>
                        </p>
                    </ng-container>

                    <ng-container *ngIf="storeS.authUser.value?.user.status.is_pending_credit_card">
                        <p class="text-white text-decoration-underline">
                            <app-icon onleft name="caret-right-white"><strong class="text-uppercase me-md-3">Suscripción pausada</strong></app-icon>
                        </p>

                        <p class="text-white _fs-14">
                            <span class="d-md-none">Tu suscripción se encuentra pausada.</span>
                            <span class="d-none d-md-inline">Tu suscripción se encuentra pausada porque no se pudo procesar tu pago. Podés cambiar tu medio de pago aquí.</span>
                        </p>
                    </ng-container>
                </div>
            </a>
        </ng-container>
    </div>
</ng-container>
