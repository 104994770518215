import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SsrService } from "./ssr.service";

@Injectable({
  providedIn: 'root'
})
export class ViewportService implements OnDestroy {

  sizes = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 1600,
    xxxxl: 1800,
    xxxxxl: 2000,
  };

  windowWidth: BehaviorSubject<number> = new BehaviorSubject(this._ssrS.isBrowser() ? window.innerWidth : 1920);

  constructor(
    private _ssrS: SsrService,
  ) {
    if (this._ssrS.isBrowser()) {
      window.addEventListener('resize', () => {
        this.windowWidth.next(window.innerWidth);
      });
    }
  }

  ngOnDestroy(): void {
    if (this._ssrS.isBrowser()) {
      window.removeEventListener('resize', null);
    }
  }

  /* -------------------- */

  private _getSize(size: number | string): number {
    if (typeof size === 'number') {
      return size;
    }

    return this.sizes[size];
  }

  /* -------------------- */

  up(size: number | string): boolean {
    return this.windowWidth.value >= this._getSize(size);
  }

  down(size: number | string): boolean {
    return this.windowWidth.value <= this._getSize(size);
  }
};
