import { Component, Input, OnInit } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';
import { PlayDetailModalService } from '../play-detail-modal/play-detail-modal.service';
import { ViewportService } from 'src/app/services/viewport.service';

@Component({
  selector: 'app-play-box',
  templateUrl: './play-box.component.html',
  styleUrls: ['./play-box.component.scss']
})
export class PlayBoxComponent implements OnInit {

  @Input() play: Play;
  @Input() type: 'default' | 'detail' | 'result' | 'menu-mobile' = 'default';

  constructor(
    public storeS: StoreService,
    public playDetailModalS: PlayDetailModalService,
    public navS: NavService,
    public viewportS: ViewportService,
  ) { }

  ngOnInit(): void {
  }
}
