import { browserSupports } from "src/app/browser-supports";
import supportsWebP from "supports-webp";

export interface IHomeHeroSlide {
  readonly id: number;
  title: {
    text: string,
    color: string,
  };
  subtitle: {
    text: string,
    color: string,
  };
  button: {
    text: string,
    color: string,
    background_color: string,
    href: string,
    subtitle: {
      text: string,
      color: string,
    }
  };
}

export interface IHomeHero {
  readonly id: number;
  img_url_desktop: string;
  img_url_mobile: string;
  video_url_desktop: string;
  video_url_mobile: string;
  slides: IHomeHeroSlide[];
}

export class HomeHero implements IHomeHero {

  readonly id: number;
  img_url_desktop: string;
  img_url_mobile: string;
  video_url_desktop: string;
  video_url_mobile: string;
  slides: IHomeHeroSlide[];

  /* -------------------- */

  constructor(value: any) {
    this.set(value);
  }

  /* -------------------- */

  set(value: any): void {
    Object.assign(this, value);

    value?.main_image ? this.img_url_desktop = value?.main_image : null;
    value?.mobile_image ? this.img_url_mobile = value?.mobile_image : null;
    value?.main_video ? this.video_url_desktop = value?.main_video : null;
    value?.mobile_video ? this.video_url_mobile = value?.mobile_video : null;

    if (!(browserSupports.webp)) {
      this.img_url_desktop = this.img_url_desktop.replace('.webp', '.jpg');
      this.img_url_mobile = this.img_url_mobile.replace('.webp', '.jpg');
    }

    if (!this.supportsWebm()) {
      this.video_url_desktop = this.video_url_desktop.replace('.webm', '.mp4');
      this.video_url_mobile = this.video_url_mobile.replace('.webm', '.mp4');
    }

    // this.video_url_desktop = 'https://www.teatrix.com/assets/videos/teatrix/todos.mp4';
    // this.video_url_mobile = 'https://www.teatrix.com/assets/videos/teatrix/todos.mp4';

    if (value?.texts) {
      this.slides = [];

      for (const text of value?.texts) {
        this.slides.push({
          id: text.id,
          title: {
            text: text.title?.title,
            color: text.title?.color,
          },
          subtitle: {
            text: text.subtitle?.title,
            color: text.subtitle?.color,
          },
          button: {
            text: text.main_button?.title,
            color: text.main_button?.color,
            background_color: text.main_button?.background,
            href: text.main_button?.link,
            subtitle: {
              text: text.clarification?.title,
              color: text.clarification?.color,
            }
          },
        });
      }
    }
  }

  /* -------------------- */

  supportsWebm(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    const isBrowserSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isBrowserSafari) {
      return false;
    }

    let element = document.createElement('video');

    if (element.canPlayType) {
      switch (element.canPlayType('video/webm')) {
        case 'probably': return true;
        case 'maybe': return true;
        case '': return false;
      }
    }

    return false;
  }
}
