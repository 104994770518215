<ng-container *ngIf="ssrS.isBrowser()">
    <div id="toasts">
        <ngb-toast *ngFor="let toast of toastS.toasts"
            [ngClass]="toast.className"
            [header]="toast.header"
            [autohide]="true"
            [delay]="toast.delay || 3000"
            (hide)="toastS.remove(toast)"
        >
            {{ toast.body }}
        </ngb-toast>
    </div>
</ng-container>

<router-outlet></router-outlet>

<ng-container *ngIf="ssrS.isBrowser()">
    <app-hover-preview></app-hover-preview>
</ng-container>
