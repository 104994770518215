import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiErrorResponse } from './http/core/http.service';

type TExtras = {
  header?: string,
  body?: string,
  delay?: number,
};

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];

  constructor() { }

  /* -------------------- */

  show(type: 'Success' | 'Danger' | 'Warning' | 'Dark' | 'Default' = 'Default', extras: TExtras) {
    let className: string;

    switch (type) {
      case 'Success': className = 'bg-success text-light'; break;
      case 'Danger': className = 'bg-danger text-light'; break;
      case 'Warning': className = 'bg-warning text-light'; break;
      case 'Dark': className = 'bg-dark text-light'; break;
      default: break;
    }

    this.toasts = [];

    this.toasts.push({
      header: extras.header,
      body: extras.body,
      delay: extras.delay,
      className,
    });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t != toast);
  }

  /* -------------------- */

  private _getMessage(err: ApiErrorResponse, message: string): string {
    return err.message;
  }

  showHttpError(err: ApiErrorResponse) {
    this.show('Danger', { body: this._getMessage(err, 'Ha ocurrido un error.') });
  }

  showError(message: string) {
    this.show('Danger', { body: message });
  }

  showDataSaved(message?: string) {
    this.show('Success', { body: message || 'Los datos se han guardado con éxito.' });
  }
}
