<ng-container *ngIf="isMultiple() && !isEmpty()">
    <div class="form-row">
        <ng-container *ngFor="let file of control.value; trackBy: trackByFn; let index = index; let first = first; let last = last;">
            <div class="col-30 col-sm-20 col-lg-15 col-xl-12">
                <app-form-dropzone-value
                    [file]="file"
                    [label]="
                        labels.first && first ?
                            labels.first:
                            labels.last && last ?
                                labels.last:
                        labels[index]
                    "
                    [index]="index"
                ></app-form-dropzone-value>
            </div>
        </ng-container>
    </div>
</ng-container>

<!-- <div [hidden]="(!isMultiple() && !isEmpty()) || (isMultiple() && control.value.length >= max)" class="form-group"> -->
<div [hidden]="!isMultiple() && !isEmpty()">
    <app-form-dropzone-upload #upload></app-form-dropzone-upload>
</div>

<div *ngIf="!isMultiple() && !isEmpty()">
    <app-form-dropzone-value [file]="control.value"></app-form-dropzone-value>
</div>
