import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBtnDirective } from './loading-btn.directive';
import { AppendHtmlDirective } from './append-html.directive';
import { ClampDirective } from './clamp.directive';
import { HlsDirective } from './hls.directive';
import { LazyImageDirective } from './lazy-image.directive';
import { UseWebpDirective } from './use-webp.directive';



@NgModule({
  declarations: [
    LoadingBtnDirective,
    AppendHtmlDirective,
    ClampDirective,
    HlsDirective,
    LazyImageDirective,
    UseWebpDirective,
  ],
  exports: [
    LoadingBtnDirective,
    AppendHtmlDirective,
    ClampDirective,
    HlsDirective,
    LazyImageDirective,
    UseWebpDirective,
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
