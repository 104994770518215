import { StoreService } from "src/app/services/store.service";
import { User } from "../user/user.model";
import { CacheService } from "src/app/services/cache.service";

export interface IAuthUser {
  user: User;
  token: string;
}

export class AuthUser implements IAuthUser {

  user: User;
  token: string;

  /* -------------------- */

  constructor(value: IAuthUser) {
    Object.assign(this, value);

    this.user = new User(value.user);
  }

  /* -------------------- */

  isLoggedIn(): boolean {
    return !!this.user && !!this.token;
  }

  /* -------------------- */

  logout(storeS: StoreService, cacheS: CacheService): void {
    storeS.remove('authUser');
    storeS.emit('authUser', 'logout');

    cacheS.delete('authUser');
  }
}
