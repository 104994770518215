import { Injectable } from '@angular/core';
import { PixelService } from 'ngx-pixel';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SsrService } from '../ssr.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  userId: number | null = null;
  constructor(
    private _ssrS: SsrService,
    private _gtmService: GoogleTagManagerService,
    private _pixel: PixelService,
  ) { 
    const storedData = localStorage.getItem('app.authUser');

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.userId = parsedData.value?.user?.user_id || null;
    } 
  }

  get _twitter(): any {
    if (this._ssrS.isServer()) return;
    return window;
  }

  async Login(data: any) {
    let info = await data;

    if(this._ssrS.isBrowser()){
      
      this._gtmService.pushTag({event:'login',user_id:info.user.user_id.toString()});

      this._pixel.trackCustom('login',data.user);

      this._twitter.twq('event', 'tw-ocjtb-ocrgx', {
        email_address: info.user.user_email
      });
    }
 }

  Purchase(data:any,discount:number,category:any)
  {
    if(data.group.value.plan){

      let benefit ='';

      switch(true){
        case data.group.value.benefit_365.card_number !='':
          benefit ='Clarin 365'
        break;
        case data.group.value.benefit_LaNacion.card_number != '':
          benefit ='Club La Nacion'
        break;
        case data.group.value.benefit_Code.code != '':
          benefit = data.group.value.benefit_Code.code
        break;
    }

    
     let params = <any>{
        user_id:this.userId,
        currency: String(data.group.value.plan.currency_iso),
        value: Number(discount),
        coupon: String(benefit),
        items: [{
          item_id: String(data.group.value.plan.plan_id),
          discount: Number(data.group.value.plan.amount - discount),
          item_name: String(data.group.value.plan.name),
          item_category: String(category),
          quantity: 1,
          price: Number(data.group.value.plan.amount),
        }]
      }
      if(this._ssrS.isBrowser()){

        this._gtmService.pushTag({event:'purchase',product:params});

        this._pixel.track('Purchase',{
          content_ids       :[data.group.value.plan.plan_id],
          value             : Number(discount),            // Value of all items
          currency          : data.group.value.plan.currency_iso,                   // Currency of the value
          content_type      : category,
          num_items         : 1
        });

        this._twitter.twq('event', 'tw-ocjtb-ocjtx', {
          value:  Number(discount),
          currency: data.group.value.plan.currency_iso,
          conversion_id: data.group.value.plan.plan_id
        });
      }
    }

  }

  RegisterStep(step_id:any){
    if(this._ssrS.isBrowser()){
      this._pixel.trackCustom('Step :' + step_id,{step_id: step_id });
      this._gtmService.pushTag({event:'Step',step_id: step_id,user_id:this.userId});
      this._twitter.twq('event','tw-ocjtb-ocrhp',{});
    }
  }


  RegisterVisit(){
    if(this._ssrS.isBrowser()){
       this._pixel.trackCustom('RegisterVisit');
       this._gtmService.pushTag({event:'RegisterVisit',visit:true,user_id:this.userId});
       this._twitter.twq('event', 'tw-ocjtb-ocrhg',{});
    }
  }
  BeginCheckout(data:any,discount:number,category:any)
  {

   if(data.group.value.plan){

      let benefit ='';

      switch(true){
        case data.group.value.benefit_365.card_number !='':
          benefit ='Clarin 365'
        break;
        case data.group.value.benefit_LaNacion.card_number != '':
          benefit ='Club La Nacion'
        break;
        case data.group.value.benefit_Code.code != '':
          benefit = data.group.value.benefit_Code.code
        break;
      }

     let params = <any>{
        user_id:this.userId,
        currency: String(data.group.value.plan.currency_iso),
        value: Number(discount),
        coupon: String(benefit),
        items: [{
          item_id: String(data.group.value.plan.plan_id),
          discount: Number(discount),
          item_name: String(data.group.value.plan.name),
          item_category: String(category),
          quantity: 1,
          price: Number(data.group.value.plan.amount),
        }]
      }
      if(this._ssrS.isBrowser()){
        this._gtmService.pushTag({event:'begin_checkout',product:params});

        this._pixel.track('InitiateCheckout',{
          content_ids       :[data.group.value.plan.plan_id],
          value             : Number(data.group.value.plan.amount - discount),            // Value of all items
          currency          : data.group.value.plan.currency_iso,                   // Currency of the value
          content_type      : category,
          num_items         : 1
        });

        this._twitter.twq('event', 'tw-ocjtb-ocrh7', {
          value: Number(data.group.value.plan.amount - discount), // use this to pass the value of the conversion (e.g. 5.00)
          currency:  data.group.value.plan.currency_iso, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
          conversion_id: data.group.value.plan.plan_id // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')

       });

      }
    }

  }
  ViewContent(data:any,videoselected:any)
  {

   let content_type : any

    switch(videoselected.type_id){
      case 1:
        content_type = 'Contenido Adicional'
      break;
      case 3:
         content_type = 'Obra Completa'
      break;
      case 5:
          content_type = 'Thriller'
      break;
    }
    if(this._ssrS.isBrowser()){

    this._gtmService.pushTag({event:'select_content',content:{
      content_type: content_type,
      item_id: [data.play_id]
    }});


    this._pixel.track('ViewContent',{
      content_ids       :[data.play_id],
      content_name      : data.play_name,
      content_type      : content_type
    });
    }
  }
}
