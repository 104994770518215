import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { AuthSubscribeModalComponent } from './auth-subscribe-modal.component';
import { FormsModule } from 'src/app/packages/forms/forms.module';
import { RouterModule } from '@angular/router';
import { ButtonFormModule } from 'src/app/components/buttons/button-form/button-form.module';
import { AuthSubscribeModalRegisterFormComponent } from './auth-subscribe-modal-register-form/auth-subscribe-modal-register-form.component';
import { AuthSubscribeModalStepCounterComponent } from './auth-subscribe-modal-step-counter/auth-subscribe-modal-step-counter.component';
import { AuthSubscribeModalSubscribeFormComponent } from './auth-subscribe-modal-subscribe-form/auth-subscribe-modal-subscribe-form.component';
import { AuthSubscribeModalCheckoutFormComponent } from './auth-subscribe-modal-checkout-form/auth-subscribe-modal-checkout-form.component';
import { IconModule } from 'src/app/components/commons/icon/icon.module';
import { RequestHandlerModule } from 'src/app/components/request-handlers/request-handler/request-handler.module';
import { NgbAlertModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { AuthSubscribeModalContentComponent } from './auth-subscribe-modal-content/auth-subscribe-modal-content.component';



@NgModule({
  declarations: [
    AuthSubscribeModalComponent,
    AuthSubscribeModalContentComponent,
    AuthSubscribeModalRegisterFormComponent,
    AuthSubscribeModalStepCounterComponent,
    AuthSubscribeModalSubscribeFormComponent,
    AuthSubscribeModalCheckoutFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ButtonFormModule,
    IconModule,
    RequestHandlerModule,
    NgbAlertModule,
    NgbPopoverModule,
    PipesModule
  ],
  exports: [
    AuthSubscribeModalContentComponent,
    AuthSubscribeModalRegisterFormComponent,
    AuthSubscribeModalStepCounterComponent,
    AuthSubscribeModalSubscribeFormComponent,
    AuthSubscribeModalCheckoutFormComponent,
  ]
})
export class AuthSubscribeModalModule { }
