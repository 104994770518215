import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserBenefitHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
  ) { }

  /* -------------------- */

  getDiscountFor365(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/validate365Card`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getDiscountForLaNacion(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/validateNacionCard`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getDiscountForELPais(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/validateElpaisCard`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getDiscountForELComercio(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/validateElcomercio`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getDiscountForCode(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/validateCode`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getDiscountForPpvCode(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/PPV/playCode`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  getDiscountForPpvExchangeCode(input: any) {
    const tokenType: TokenType = 'authGuestToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/PPV/exchangeCodeCheck`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }
}
