import { Directive, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';
import { onViewportIntersection } from '../helper';
import { SsrService } from '../services/ssr.service';

@Directive({
  selector: 'img-deny',
})
export class LazyImageDirective implements OnChanges {

  @HostBinding('attr.src') srcAttr: string = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  @Input() src!: string;

  constructor(
    private _elem: ElementRef<HTMLImageElement>,
    private _ssrS: SsrService,
  ) { }

  ngOnChanges(changes: any): void {
    if (changes.src) {
      if (this._ssrS.isServer()) {
        return;
      }

      this._lazyLoad();
    }
  }

  /* -------------------- */

  private _lazyLoad(): void {
    onViewportIntersection(this._elem.nativeElement, () => {
      this._load();
      this._elem.nativeElement.setAttribute('data-lazy-load', 'true');
    });
  }

  private _load(): void {
    setTimeout(() => this.srcAttr = this.src);
  }
}
