import { Injectable, inject } from '@angular/core';
import { StoreService } from './store.service';

type TCache = {
  authUser?: TDetail[],
  authGuest?: TDetail[],
};

type TDetail = {
  endpoint: string,
  data: any,
};

type TAuth = 'authUser' | 'authGuest';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private _storeS = inject(StoreService);

  cache: TCache = {
    authUser: [],
    authGuest: [],
  };

  constructor() { }

  /* -------------------- */

  get(endpoint: string) {
    return this.cache[this.getAuth()]?.find((item: TDetail) => item.endpoint === endpoint);
  }

  set(endpoint: string, data: any) {
    let previous = this.get(endpoint);

    if (previous) {
      previous.data = data;
    } else {
      this.cache[this.getAuth()]?.push({
        endpoint: endpoint,
        data: data
      });
    }
  }

  delete(auth: TAuth) {
    switch (auth) {
      case 'authUser': this.cache.authUser = []; break;
      case 'authGuest': this.cache.authGuest = []; break;

      default: {
        this.cache.authUser = [];
        this.cache.authGuest = [];
      }
    }
  }

  /* -------------------- */

  getAuth(): TAuth {
    return this._storeS.authUser.value?.isLoggedIn() ? 'authUser' : 'authGuest';
  }
}
