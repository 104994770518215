import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthUserCanCancelSubscriptionGuard } from './guards/auth-user-can-cancel-subscription.guard';
import { AuthUserHasSubscriptionGuard } from './guards/auth-user-has-subscription.guard';
import { AuthUserIsLoggedInGuard } from './guards/auth-user-is-logged-in.guard';
import { plays } from './plays';
import { DefaultTemplateComponent } from './templates/default-template/default-template.component';
import { SimpleHeaderTemplateComponent } from './templates/simple-header-template/simple-header-template.component';
import { SimpleHeaderWithRegisterTemplateComponent } from './templates/simple-header-with-register-template/simple-header-with-register-template.component';
import { AuthUserIsNotLoggedInGuard } from './guards/auth-user-is-not-logged-in.guard';

const notFoundRoute = 'not-found';
const homeRoute = 'teatro-online';

const homePageMatcher = (url: UrlSegment[]): UrlMatchResult => {
  if (url.length === 1 && url[0].path === homeRoute) {
    return {
      consumed: url
    };
  }

  if (url.length === 3 && url[0].path === 'preview') {
    return {
      consumed: url,
      posParams: {
        playSlug: new UrlSegment(url[1].path, {}),
        playId: new UrlSegment(url[2].path, {}),
      }
    };
  }

  if (url.length === 3 && url[0].path === 'password' && url[1].path === 'reset') {
    return {
      consumed: url,
      posParams: {
        hash: new UrlSegment(url[2].path, {}),
      }
    };
  }

  if (url.length === 1 && url[0].path === 'forgot-password') {
    return {
      consumed: url,
      posParams: {
        openResetPasswordModal: new UrlSegment('true', {}),
      }
    };
  }

  if (url.length === 1 && url[0].path === 'login') {
    return {
      consumed: url,
      posParams: {
        openLoginModal: new UrlSegment('true', {}),
      }
    };
  }

  // if (url.length === 1 && url[0].path === 'login-provider') {
  //   return {
  //     consumed: url,
  //     posParams: {
  //       openLoginModal: new UrlSegment('true', {}),
  //       loginModalType: new UrlSegment('provider', {}),
  //     }
  //   };
  // }

  return null;
};

const registerPageMatcher = (url: UrlSegment[]): UrlMatchResult => {
  if (url.length === 1 && url[0].path === 'suscribite') {
    return {
      consumed: url
    };
  }

  return null;
};

const routes: Routes = [
  // { path: 'test', loadChildren: () => import('./pages/test-page/test-page.module').then(mod => mod.TestPageModule), data: { name: 'TestPage' } },
  // { path: 'not-found', component: DefaultTemplateComponent, loadChildren: () => import('./pages/errors/not-found-page/not-found-page.module').then(mod => mod.NotFoundPageModule), data: { name: 'NotFoundPage' } },
  /* -------------------- */
  {
    path: '',
    component: DefaultTemplateComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/' + homeRoute },
      { matcher: homePageMatcher, loadChildren: () => import('./pages/home-page/home-page.module').then(mod => mod.HomePageModule), data: { name: 'HomePage' } },
      { path: 'colecciones', loadChildren: () => import('./pages/play-collections-list-page/play-collections-list-page.module').then(mod => mod.PlayCollectionsListPageModule), data: { name: 'PlayCollectionsListPage' } },
      { path: 'oauth/:jwt', loadChildren: () => import('./pages/oauth-page/oauth-page.module').then(mod => mod.OauthPageModule), data: { name: 'OauthPage' } },
      { path: 'colecciones/:playCollectionSlug/:playCollectionId', loadChildren: () => import('./pages/play-collection-detail-page/play-collection-detail-page.module').then(mod => mod.PlayCollectionDetailPageModule), data: { name: 'PlayCollectionDetailPage' } },
      { path: 'obras/:playSlug/:playId', loadChildren: () => import('./pages/play-detail-page/play-detail-page.module').then(mod => mod.PlayDetailPageModule), data: { name: 'PlayDetailPage' } },
      { path: 'beneficios', loadChildren: () => import('./pages/benefits-page/benefits-page.module').then(mod => mod.BenefitsPageModule), data: { name: 'BenefitsPage' } },
      { path: 'contacto', loadChildren: () => import('./pages/contact-page/contact-page.module').then(mod => mod.ContactPageModule), data: { name: 'ContactPage' } },
      { path: 'terminos-y-condiciones', loadChildren: () => import('./pages/terms-page/terms-page.module').then(mod => mod.TermsPageModule), data: { name: 'TermsPage' } },
      { path: 'politica-de-privacidad', loadChildren: () => import('./pages/privacy-policy-page/privacy-policy-page.module').then(mod => mod.PrivacyPolicyPageModule), data: { name: 'PrivacyPolicyPage' } },
      { path: 'politica-de-cookies', loadChildren: () => import('./pages/cookies-policy-page/cookies-policy-page.module').then(mod => mod.CookiesPolicyPageModule), data: { name: 'CookiesPolicyPage' } },
      { path: 'defensa-al-consumidor', loadChildren: () => import('./pages/consumer-protection-page/consumer-protection-page.module').then(mod => mod.ConsumerProtectionPageModule), data: { name: 'ConsumerProtectionPage' } },
      { path: 'centro-de-ayuda', loadChildren: () => import('./pages/help-center-page/help-center-page.module').then(mod => mod.HelpCenterPageModule), data: { name: 'HelpCenterPage' } },
      { path: 'quienes-somos', loadChildren: () => import('./pages/about-us-page/about-us-page.module').then(mod => mod.AboutUsPageModule), data: { name: 'AboutUsPage' } },
      { path: 'planes-de-suscripcion', loadChildren: () => import('./pages/subscription-plans-page/subscription-plans-page.module').then(mod => mod.SubscriptionPlansPageModule), data: { name: 'SubscriptionPlansPage' } },
      { path: 'corporativo', loadChildren: () => import('./pages/corporative-page/corporative-page.module').then(mod => mod.CorporativePageModule), data: { name: 'CorporativePage' } },
      // { path: 'promo', loadChildren: () => import('./pages/promo-page/promo-page.module').then(mod => mod.PromoPageModule), data: { name: 'PromoPage' } },
      // { path: 'promo-inter', loadChildren: () => import('./pages/promo-international-page/promo-international-page.module').then(mod => mod.PromoInternationalPageModule), data: { name: 'PromoInternationalPage' } },
      { path: 'noticias', loadChildren: () => import('./pages/news-list-page/news-list-page.module').then(mod => mod.NewsListPageModule), data: { name: 'NewsListPage' } },
      { path: 'noticias/:newsSlug/:newsId', loadChildren: () => import('./pages/news-detail-page/news-detail-page.module').then(mod => mod.NewsDetailPageModule), data: { name: 'NewsDetailPage' } },
      { path: 'comprar-ppv/:playSlug/:playId', loadChildren: () => import('./pages/subscribe-page/subscribe-page.module').then(mod => mod.SubscribePageModule), data: { name: 'SubscribePage' } },
      { path: 'regala-teatrix', loadChildren: () => import('./pages/gift-cards-page/gift-cards-page.module').then(mod => mod.GiftCardsPageModule), data: { name: 'GiftCardsPage' } },
      { path: 'comprar-gift-card/:giftCardId', loadChildren: () => import('./pages/gift-card-buy-page/gift-card-buy-page.module').then(mod => mod.GiftCardBuyPageModule), data: { name: 'GiftCardBuyPage' } },
      { path: 'canjear-gift-card', canActivate: [AuthUserIsLoggedInGuard], loadChildren: () => import('./pages/gift-card-redeem-page/gift-card-redeem-page.module').then(mod => mod.GiftCardRedeemPageModule), data: { name: 'GiftCardRedeemPage' } },
      {
        path: '',
        children: [
          { path: 'mi-lista', canActivate: [AuthUserHasSubscriptionGuard], loadChildren: () => import('./pages/my-list-page/my-list-page.module').then(mod => mod.MyListPageModule), data: { name: 'MyListPage', authRequired: true } },
          { path: 'mi-cuenta', canActivate: [AuthUserIsLoggedInGuard], loadChildren: () => import('./pages/my-account-page/my-account-page.module').then(mod => mod.MyAccountPageModule), data: { name: 'MyAccountPage', authRequired: true } },
          { path: 'mi-cuenta/baja', canActivate: [AuthUserCanCancelSubscriptionGuard], loadChildren: () => import('./pages/cancel-subscription-page/cancel-subscription-page.module').then(mod => mod.CancelSubscriptionPageModule), data: { name: 'CancelSubscriptionPage', authRequired: true } },
          { path: 'mi-cuenta/facturacion', canActivate: [AuthUserIsLoggedInGuard], loadChildren: () => import('./pages/invoices-page/invoices-page.module').then(mod => mod.InvoicesPageModule), data: { name: 'InvoicesPage', authRequired: true } },
        ],
        data: { }
      }
    ],
    data: { groupName: 'DefaultTemplate' }
  },
  {
    path: '',
    component: SimpleHeaderTemplateComponent,
    children: [
      { matcher: registerPageMatcher, loadChildren: () => import('./pages/register-page/register-page.module').then(mod => mod.RegisterPageModule), data: { name: 'RegisterPage' } },
      { path: 'suscribite-inter', loadChildren: () => import('./pages/register-international-page/register-international-page.module').then(mod => mod.RegisterInternationalPageModule), data: { name: 'RegisterInternationalPage' } },
      { path: 'activar', canActivate: [AuthUserIsLoggedInGuard], loadChildren: () => import('./pages/activation-page/activation-page.module').then(mod => mod.ActivationPageModule), data: { name: 'ActivationPage' } },
      { path: 'validar-email', loadChildren: () => import('./pages/email-verification-page/email-verification-page.module').then(mod => mod.EmailVerificationPageModule), data: { name: 'EmailVerificationPage' } },
    ],
    data: { groupName: 'SimpleHeaderTemplate' }
  },
  {
    path: '',
    component: SimpleHeaderWithRegisterTemplateComponent,
    children: [
      { path: 'login-provider', canActivate: [AuthUserIsNotLoggedInGuard], loadChildren: () => import('./pages/auth-page/auth-page.module').then(mod => mod.AuthPageModule), data: { name: 'AuthPage' } },
    ],
    data: { groupName: 'SimpleHeaderWithRegisterTemplate' }
  },
  {
    path: '',
    children: [
      { path: 'obras/:playSlug/:playId/videos/obra', canActivate: [AuthUserIsLoggedInGuard], loadChildren: () => import('./pages/play-view-page/play-view-page.module').then(mod => mod.PlayViewPageModule), data: { name: 'PlayViewPage', authRequired: true } },
      { path: 'obras/:playSlug/:playId/videos/extras/:playVideoExtraId', loadChildren: () => import('./pages/play-view-page/play-view-page.module').then(mod => mod.PlayViewPageModule), data: { name: 'PlayViewPage' } },
      { path: 'apps', loadChildren: () => import('./pages/apps-page/apps-page.module').then(mod => mod.AppsPageModule), data: { name: 'AppsPage' } },
      { path: 'not-available-region', loadChildren: () => import('./pages/errors/not-available-region-page/not-available-region-page.module').then(mod => mod.NotAvailableRegionPageModule), data: { name: 'NotAvailableRegionPage' } },
      { path: 'suscribite-hoy', loadChildren: () => import('./pages/subscribe-page/subscribe-page.module').then(mod => mod.SubscribePageModule), data: { name: 'SubscribePage' } },
      { path: 'suscribite-ahora', loadChildren: () => import('./pages/register-alternative-v1-page/register-alternative-v1-page.module').then(mod => mod.RegisterAlternativeV1PageModule), data: { name: 'RegisterAlternativeV1Page' } },
      { path: 'suscribete-ahora', loadChildren: () => import('./pages/register-alternative-v2-page/register-alternative-v2-page.module').then(mod => mod.RegisterAlternativeV2PageModule), data: { name: 'RegisterAlternativeV2Page' } },
      { path: 'suscribete-ahoraww', loadChildren: () => import('./pages/register-alternative-v3-page/register-alternative-v3-page.module').then(mod => mod.RegisterAlternativeV3PageModule), data: { name: 'RegisterAlternativeV3Page' } },
      { path: 'suscripcion-ar', loadChildren: () => import('./pages/register-v1-page/register-v1-page.module').then(mod => mod.RegisterV1PageModule), data: { name: 'RegisterV1Page' } },
      { path: 'suscripcion-mx', loadChildren: () => import('./pages/register-v2-page/register-v2-page.module').then(mod => mod.RegisterV2PageModule), data: { name: 'RegisterV2Page' } },
      { path: 'suscripcion-ww', loadChildren: () => import('./pages/register-v3-page/register-v3-page.module').then(mod => mod.RegisterV3PageModule), data: { name: 'RegisterV3Page' } },
    ],
    data: { groupName: 'NoTemplate' }
  },
  /* -------------------- */
  // { path: '**', redirectTo: '/' + notFoundRoute },
  { path: '**', component: DefaultTemplateComponent, loadChildren: () => import('./pages/errors/not-found-page/not-found-page.module').then(mod => mod.NotFoundPageModule), data: { name: 'NotFoundPage' } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
