import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { NewsletterModalComponent } from './newsletter-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NewsletterModalService {

  constructor(
    private _modalS: ModalService,
  ) { }

  /* -------------------- */

  open(): void {
    if (!this._modalS.storeS.authUser.value?.isLoggedIn()) {
      this._modalS.beforeOpen('NewsletterModal');

      const modalRef = this._modalS.ngbModal.open(NewsletterModalComponent, {
        windowClass: 'newsletter-modal-window fullscreen-md',
        centered: true,
        size: 'xl',
      });
    }
  }
}
