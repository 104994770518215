import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Form } from 'src/app/packages/forms/tools/form';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { ApiErrorResponse } from 'src/app/services/http/core/http.service';
import { ToastService } from 'src/app/services/toast.service';
import { NewsletterHttpService } from 'src/app/services/http/newsletter-http.service';
import { StoreService } from 'src/app/services/store.service';
@Component({
  selector: 'app-newsletter-modal',
  templateUrl: './newsletter-modal.component.html',
  styleUrls: ['./newsletter-modal.component.scss']
})
export class NewsletterModalComponent implements OnInit, OnDestroy {

  form: Form;

  step: number = 1;

  private _sm = new SubscriptionManager();

  constructor(
    public modal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private _toastS: ToastService,
    private _newsletterHttpS: NewsletterHttpService,
    private _storeS: StoreService,
  ) { }

  ngOnInit(): void {
    this.form = new Form(this._fb.group({
      name: [''],
      email: [''],
    }));

    this.form.ready = true;
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  /* -------------------- */

  save(): void {
    if (this.form.request.isLoading()) return;

    this.form.resetMarks();

    const input = this.form.group.value;

    this._sm.add(
      this.form.request.send(this._newsletterHttpS.save(input)).subscribe((res: any) => {
        const err: ApiErrorResponse = this.form.request.error;

        if (err) {
          if (err.status !== 422) {
            this._toastS.showHttpError(err);
          }
        } else {
          let newsletterData = {
            state: 'activated',
            email: res.data.email
          }

          this._storeS.set('newsletter', newsletterData, true);

          this.step = 2;
        }
      }, (err: ApiErrorResponse) => {
        if (err.status !== 422) {
          this._toastS.showHttpError(err);
        }
      })
    , 'newsletter');
  }

  close(reason?: string) {
    let newsletterData = {
      state: 'denied',
      email: null
    }

    this._storeS.set('newsletter', newsletterData);

    this.modal.dismiss(reason);
  }
}
