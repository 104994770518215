import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appJsonString'
})
export class JsonStringPipe implements PipeTransform {

  transform(value: string): any {
    if (value.startsWith('{')) {
      return JSON.parse(value);
    }

    return value;
  }

}
