import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './core/http.service';

@Injectable({
  providedIn: 'root'
})
export class FileHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
  ) { }

  /* -------------------- */

  upload(concept: string, input: FormData) {
    return this._httpS.send(this._httpClient.post(this._httpS.getEndpoint('/files/upload/' + concept), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken() })
    }));
  }
}
