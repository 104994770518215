import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], term: any, field: string = 'name', exactWord: boolean = false): any {
    if (!term) return items;

    term = this._normalize(term.toLowerCase());

    if (exactWord) {
      term = new RegExp(`\\b${term}\\b`);
    }

    return items.filter((option: any) => {
      const value = JSON.stringify(field ? option[field] : option);
      return this._normalize(value.toLowerCase()).search(term) > -1;
    });
  }

  private _normalize(value: string) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

}
