import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { SubscribeBarComponent } from './subscribe-bar.component';
import { IconModule } from '../../commons/icon/icon.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [
    SubscribeBarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IconModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    SubscribeBarComponent
  ]
})
export class SubscribeBarModule { }
