import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCheckboxComponent } from './form-checkbox.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormElementsModule } from '../../form-elements/form-elements.module';



@NgModule({
  declarations: [FormCheckboxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormElementsModule
  ],
  exports: [FormCheckboxComponent]
})
export class FormCheckboxModule { }
