import { Component, OnInit, Input, Optional, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { FormComponent } from '../../form/form.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
})
export class FormInputComponent extends AbstractFormFieldComponent implements OnInit, AfterViewInit {

  @ViewChild('formElem') formElem: ElementRef<HTMLInputElement>;

  @Input() type: string = 'text';
  @Input() show: boolean = false;
  @Input() readonly: boolean = false;
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() mask: string;
  @Input() maskPrefix: string = '';
  @Input() maskValidation: boolean = false;
  @Input() thousandSeparator: string;
  @Input() label: string;

  constructor(
    @Optional() public formC: FormComponent,
    @Optional() protected _formGroupParent: FormGroupDirective,
    private _datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setMaskedValueOnControl();

    this._sm.add(this.control.valueChanges.subscribe((value: any) => {
      this.setMaskedValueOnControl();

      switch (this.type) {
        case 'datetime-local': this.control.setValue(this._datePipe.transform(this.control.value, 'yyyy-MM-ddTHH:mm'), { emitEvent: false }); break;

        default: break;
      }
    }));
  }

  ngAfterViewInit(): void {

  }

  /* -------------------- */

  setMaskedValueOnControl(): void {
    if (this.mask) {
      setTimeout(() => {
        this.control['valueMasked'] = this.formElem?.nativeElement?.value || '';
      });
    }
  }
}
