import { Component, Input, OnInit } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-button-play-more-info',
  templateUrl: './button-play-more-info.component.html',
  styleUrls: ['./button-play-more-info.component.scss']
})
export class ButtonPlayMoreInfoComponent implements OnInit {

  @Input() play: Play;
  @Input() href: string;
  @Input() size: 'sm' | 'md' = 'md';
  @Input() type: 'short' | 'ppv' | 'default' = 'default';


  constructor(
    public navS: NavService,
  ) { }

  ngOnInit(): void {
  }

  get iconSize(): number {
    switch (this.size) {
      case 'sm': return 1.25;

      default: return 1.5;
    }
  }

  get text(): string {
    switch (this.type) {
      case 'short': return 'Más info';
      case 'ppv': return 'Ver detalle de obra';

      default: return 'Más información';
    }
  }
}
