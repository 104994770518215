import { Form } from './form';
import * as _ from 'lodash';
import { FormExtra } from '../types/form-extras';
import { helper } from 'src/app/helper';

export class FormApiParams {

  constructor(private _form: Form) { }

  /* -------------------- */

  get(): any {
    const state = this._form.group.value;
    let apiParams: any = {};

    _.forEach(this._form.getExtras(), (extra: FormExtra, key: string) => {
      if (!extra.apiParams) return;

      const stateValue: any = this._resolveStateValue(extra, _.get(state, key));
      const apiParamValues: string[] = this._resolveApiParamValues(extra, stateValue);

      if ((!stateValue && !_.isNumber(stateValue) || !apiParamValues.length)) return;

      let apiParam: any = {};

      _.forEach(apiParamValues, (path: string) => {
        const res: any = this._resolvePathAndValue(path, stateValue);
        _.merge(apiParams, _.set(apiParam, res.path, res.value));
      });
    });

    helper.consoleLog('Info', ['ApiParams', apiParams]);

    return apiParams;
  }

  /* -------------------- */

  private _resolveStateValue(extra: FormExtra, value: any): any {
    if (_.isEmpty(value) && !_.isNumber(value) && extra.options && extra.options.asyncData) {
      const indexBy: string = extra.options.indexBy || 'id';

      if (_.isArray(extra.options.asyncData)) {
        value = extra.options.asyncData.map((value: any) => {
          return { [indexBy]: value };
        });
      } else {
        value = { [indexBy]: extra.options.asyncData };
      }
    }

    if (_.isArray(value)) {
      value = value.map((item: any) => this._resolveStateValue(extra, item));
      value = value.join(',');

    } else if (_.isObject(value)) {
      value = value[extra.apiParams.field || 'id'];
    }

    return value;
  }

  private _resolveApiParamValues(extra: FormExtra, stateValue: any): string[] {
    let apiParamValues: string[] = [];

    if (!_.isEmpty(extra.apiParams.pathsFor)) {
      _.forEach(extra.apiParams.pathsFor, (paths: any, value: any) => {
        if (stateValue == value) {
          _.isArray(paths) ?
            apiParamValues = paths:
            apiParamValues.push(paths);
        }
      });

    } else if (!_.isEmpty(extra.apiParams.paths)) {
      _.isArray(extra.apiParams.paths) ?
        apiParamValues = extra.apiParams.paths:
        apiParamValues.push(extra.apiParams.paths);
    }

    return apiParamValues;
  }

  private _resolvePathAndValue(path: string, value: any): any {
    if (!path.includes('=>')) {
      return { path: path, value: value };
    }

    const pathSplitted: string[] = path.split('=>');

    if (pathSplitted[1].includes('{value}')) {
      pathSplitted[1] = pathSplitted[1].replace('{value}', value);
    }

    return { path: pathSplitted[0], value: pathSplitted[1] };
  }
}
