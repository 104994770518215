export enum CreditCardTypeEnum {
  Amex = 'Amex',
  Diners = 'Diners',
  Mastercard = 'Mastercard',
  Elo = 'Elo',
  Hipercard = 'Hipercard',
  Visa = 'Visa',
  Naranja = 'Naranja',
  Cabal = 'Cabal',
  Cencosud = 'Cencosud',
  Shopping = 'Shopping',
  Cmr = 'Cmr',
  Argencard = 'Argencard',
  MercadoPago = 'MercadoPago',
}
