export let info = {
  subscriptionPrice: '',
  subscriptionPriceSubscribeBar: '',
  subscriptionDescription: '',
  subscriptionPromoAmount: '',
  subscriptionPromoOff: 0.5,
  subscriptionPromoCode: 'mar2250off',
  ppvPrice: '$500',
  mailtoInfo: 'info@teatrix.com',
  mailtoSupport: 'soporte@teatrix.com',
  mailtoAdmin: 'administracion@teatrix.com',
  mailtoCorporative: 'corporativo@teatrix.com',
  phoneNumber1: '(+54) 11-2643-1703',
  phoneNumber1Schedule: 'Lunes a Viernes de 9 a 15hs',
  phoneNumber2: '(+54) 11-5332-3300',
  phoneNumber2Schedule: 'Lunes a Viernes de 14 a 18hs',
  whatsappNumber: '5491124017469',
  whatsappNumberParsed: '(+54) 11-2401-7469',
  socialMedia: '@SomosTeatrix',
  facebook: 'https://www.facebook.com/SomosTeatrix',
  instagram: 'https://www.instagram.com/somosteatrix',
  twitter: 'https://twitter.com/SomosTeatrix',
  youtube: 'https://www.youtube.com/c/SomosTeatrix',
  linkedin: 'https://www.linkedin.com/company/productora-de-contenidos-educativos-para-el-desarrollo',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.teatrix.player',
  appStore: 'https://itunes.apple.com/us/app/teatrix/id1211733424',
};
