import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from './form-error.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [FormErrorComponent],
  imports: [
    CommonModule,
    NgbPopoverModule
  ],
  exports: [FormErrorComponent]
})
export class FormErrorModule { }
