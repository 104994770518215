import { environment } from 'src/environments/environment';

export interface IPlayCategory {
  readonly id: number;
  name: string;
  type_id: number;
  is_international: boolean;
  international_flag: string;
  featured_internal: number;
  is_collections: boolean;
}

export class PlayCategory implements IPlayCategory {

  readonly id: number;
  name: string;
  type_id: number;
  is_international: boolean;
  international_flag: string;
  featured_internal: number;
  is_collections: boolean;

  /* -------------------- */

  constructor(value: any) {
    Object.assign(this, value);

    value?.type ? this.type_id = value?.type : null;
    value?.name === environment.colecctionsCategoryName ? this.is_collections = true : null;
  }

  /* -------------------- */

  static getForSidebar(categories: PlayCategory[]) {
    return categories.filter(category => category.type_id === 1 && category.name !== environment.colecctionsCategoryName);
  }

  static getInternational(categories: PlayCategory[]) {
    return categories.filter(category => category.is_international);
  }

  static getPpv(categories: PlayCategory[]) {
    return categories.find(category => category.id === 9001);
  }

  static getForHome(categories: PlayCategory[]) {
    return categories.filter(category => !category.is_international && category.id !== 9001 && category.featured_internal === 1);
  }
}
