import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthUserSubscriptionHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
  ) { }

  /* -------------------- */

  checkout(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/saveUser`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  checkoutForFreeUser(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/subscriptions/saveFree`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  checkoutForPpv(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/PPV/buyPlay`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  checkoutForPpvCode(input: any) {
    const tokenType: TokenType = 'authUserToken';

    return this._httpS.send<any>(this._httpClient.post(this._httpS.getEndpoint(`/PPV/exchengeCode`), input, {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }), tokenType);
  }

  /* -------------------- */

  mockCheckoutOnline(input: any) {
    return of<any>({
      data: {
        address: null,
        amount: 499,
        amountTrial: null,
        amountWithoutDiscount: 499,
        campaignCode: 0,
        clientEmail: "marcelo+1630521485500@test.com",
        clientName: "Marcelo Marrone",
        creditCardID: 175937,
        currency: "ARS",
        endTrial: null,
        invoiceID: 2353395,
        paymentID: 939936,
        paymentMethodName: "VISA",
        phone: "",
        planID: 62,
        planName: "Mensual",
        recurringDays: 30,
        returnPaypal: false,
        statusPlan: 3,
        subscriptionDate: "2021-09-01 18:38:06",
        subscriptionID: 627928,
      }
    }).pipe(
      delay(1000),
    );
  }

  mockCheckoutOffline(input: any) {
    return of<any>({
      data: {
        address: null,
        amount: 1497,
        amountTrial: 1048,
        amountWithoutDiscount: 1497,
        campaignCode: 0,
        clientEmail: "marcelo+1630504387808@test.com",
        clientName: "Marcelo Marrone",
        creditCardID: null,
        currency: "ARS",
        endTrial: null,
        invoiceID: 2353351,
        linkOffline: "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/receipt?vid=858618327Ycaae68870e1d4feYf9934f27ce53292",
        paymentID: 939909,
        paymentMethodName: "PAGOFACIL",
        phone: "",
        planID: 63,
        planName: "Trimestral",
        recurringDays: 30,
        returnPaypal: false,
        statusPlan: 3,
        subscriptionDate: "2021-09-01 14:07:02",
        subscriptionID: 627893,
      }
    }).pipe(
      delay(1000),
    );
  }

  mockCheckoutForPpvOnline(input: any) {
    return of<any>({
      data: {
        amountTrial: null,
        amountWithoutDiscount: 500,
        paymentID: 33261,
        playID: 17,
        processAPI: true,
        clientEmail: "marcelo+1630521485500@test.com",
      }
    }).pipe(
      delay(1000),
    );
  }

  mockCheckoutForPpvCode(input: any) {
    return of<any>({
      data: {
        amountTrial: null,
        amountWithoutDiscount: 500,
        paymentID: 33261,
        playID: 17,
        processAPI: true,
        clientEmail: "marcelo+1630521485500@test.com",
      }
    }).pipe(
      delay(1000),
    );
  }

  mockCheckoutForFreeUser(input: any) {
    return of<any>({
      data: {
        address: null,
        amount: null,
        amountTrial: null,
        amountWithoutDiscount: null,
        campaignCode: null,
        clientEmail: "marcelo+1630521485500@test.com",
        clientName: "Marcelo Marrone",
        creditCardID: null,
        currency: null,
        endTrial: null,
        invoiceID: null,
        paymentID: null,
        paymentMethodName: null,
        phone: "",
        planID: null,
        planName: "Gratis",
        recurringDays: null,
        returnPaypal: null,
        statusPlan: null,
        subscriptionDate: "2021-09-01 18:38:06",
        subscriptionID: 627928,
      }
    }).pipe(
      delay(1000),
    );
  }
}
