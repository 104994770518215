import { Input, OnInit, OnDestroy, Directive } from '@angular/core';
import { UntypedFormGroup, AbstractControl, FormGroupDirective, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { FormComponent } from '../form/form.component';
import { Form } from '../../tools/form';
import { Request } from 'src/app/commons/request';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';

@Directive()
export abstract class AbstractFormFieldComponent implements OnInit, OnDestroy {

  /**
   * Para todos los elementos
   */
  @Input() icon: string;
  @Input() label: string;
  @Input() value: any;
  @Input() initValue: any = '';
  @Input() placeholder: string;
  @Input() controlName: string;
  @Input() optionsPath: string;
  @Input() optionsRequired: boolean;
  @Input('options') optionsCustom: any[];
  @Input() optionsCustomIndexBy: string;
  @Input() help: string;
  @Input() themes: string[];
  @Input() disabled: boolean;
  @Input() errorName: string;

  group: UntypedFormGroup;
  control: AbstractControl;
  // controlPath: string;
  error: any = null;

  private _multiple: boolean;
  private _boolean: boolean;

  /* -------------------- */

  formDefault: Form = new Form(new UntypedFormGroup({}));

  formC: FormComponent;
  protected _formGroupParent: FormGroupDirective;
  protected _formFieldClassName: string;

  protected _sm: SubscriptionManager = new SubscriptionManager;

  constructor() { }

  ngOnInit(): void {
    !!this._formGroupParent ?
      this.group = this._formGroupParent.form:
      !!this.formC ?
        this.group = this.formC.form.group:
        this.group = this.formDefault.group;

    !!this.controlName ?
      this.control = this.group.get(this.controlName):
      this.control = new UntypedFormControl(this.initValue);

    this._.isArray(this.control.value) ?
      this._multiple = true: false;

    this._.isBoolean(this.control.value) || this._.isNull(this.control.value) ?
      this._boolean = true: false;

    // this.controlPath = this.getControlPath();

    this._sm.add(this.form.request.error$.subscribe((hasErrors: boolean) => {
      if (!hasErrors) this.error = null;
    }));
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  get _(): _.LoDashStatic {
    return _;
  }

  get form(): Form {
    return !!this.formC ?
      this.formC.form:
      this.formDefault;
  }

  get request(): Request {
    return this.form.request;
  }

  get requestData(): Request {
    return this.form.requestData;
  }

  get options(): any[] {
    return !!this.optionsCustom ?
      this.optionsCustom:
      this.form.options.get(this.getOptionsPath());
  }

  get optionsItemChild(): string {
    return this.form.options.getItemChild(this.getOptionsPath());
  }

  get optionsIndexBy(): string {
    return !!this.optionsCustom && !!this.optionsCustomIndexBy ?
      this.optionsCustomIndexBy:
      this.form.options.getIndexBy(this.getOptionsPath());
  }

  get optionsCanSet(): boolean {
    return this.form.options.canSet(this.getOptionsPath());
  }

  get controls(): AbstractControl[] {
    return (this.control as UntypedFormArray).controls;
  }

  get controlPath(): string {
    return this.getControlPath();
  }

  get controlPathGeneric(): string {
    return this.getControlPathGeneric();
  }

  get controlAsFormControl(): UntypedFormControl {
    return this.control as UntypedFormControl;
  }

  /* -------------------- */

  get isLabelUndefined(): boolean {
    return typeof this.label === 'undefined';
  }

  /* -------------------- */

  getControlName(control: AbstractControl): string {
    return this.form.getControlName(control);
  }

  getControlPath(control?: AbstractControl): string {
    if (!control) control = this.control;
    return this.form.getControlPath(control);
  }

  getControlPathGeneric(control?: AbstractControl): string {
    if (!control) control = this.control;
    return this.form.getControlPathGeneric(control);
  }

  hasControls(): boolean {
    return !!_.get(this.control, 'controls', []).length;
  }

  /* -------------------- */

  getOptionsPath(): string {
    return !!this.optionsPath ?
      this.optionsPath:
      this.controlPathGeneric;
  }

  /* -------------------- */

  getErrorPath(): string {
    return this.errorName || this.controlPath;
  }

  getRawError(index: string | number = '', startsWith: boolean = true): any {
    const errors: any = _.pickBy(_.get(this.request.error, ['errors'], {}), (item: any, key: string) => {
      const res: any = {
        default: key === `${this.getErrorPath()}`,
        defaultIndex: key.startsWith(`${this.getErrorPath()}.${index}`),
        uploaded: key === `${this.getErrorPath()}__uploaded`,
        uploadedIndex: key.startsWith(`${this.getErrorPath()}__uploaded.${index}`),
      };

      if (this._formFieldClassName === 'FormDropzoneComponent') {
        if (this.isMultiple()) {
          return !index ?
            res.default || res.uploaded || res.uploadedIndex:
            res.default || res.defaultIndex;
        }

        return res.default || res.defaultIndex || res.uploaded || res.uploadedIndex;
      }

      if (startsWith) {
        return res.default || res.defaultIndex;
      }

      return res.default;
    });

    return errors;
  }

  getError(index: string | number = '', startsWith: boolean = true): any {
    if (!_.isNull(this.error)) return this.error;

    const errors: any[] = _.flatten(_.values(this.getRawError(index, startsWith)));

    if (errors.length) {
      this.error = errors;
      return errors;
    }

    return;
  }

  removeError(index: string | number = '', startsWith: boolean = true): void {
    if (!!this.request.error && !!this.request.error.errors) {
      // const formFieldClassName: string = this._formFieldClassName;
      // this._formFieldClassName = null;

      this.request.error.errors = _.omit(this.request.error.errors, _.keys(this.getRawError(index, startsWith)));

      // this._formFieldClassName = formFieldClassName;

      if (_.isEmpty(this.request.error.errors)) {
        this.request.error = null;
        this.request.error$.emit(false);
      }
    }
  }

  hasError(index: string | number = '', startsWith: boolean = true): boolean {
    return !this.control.dirty && !!this.getError(index, startsWith);
    // return !!this.getError(index, startsWith);
  }

  /* -------------------- */

  isEmpty(): boolean {
    return _.isEmpty(this.control.value) && !_.isNumber(this.control.value) && !_.get(this, 'type', '').startsWith('date');
  }

  isFilled(): boolean {
    return !this.isEmpty();
  }

  isDisabled(): boolean {
    return this.control.disabled || this.disabled;
  }

  isInvalid(index: string | number = '', startsWith: boolean = true): boolean {
    return ((this.control.dirty || this.control.touched) && this.control.invalid) || this.hasError(index, startsWith);
  }

  isMultiple(): boolean {
    return this._multiple;
  }

  isBoolean(): boolean {
    return this._boolean;
  }

  isObjectSelectable(): boolean {
    return _.some(this.options, _.isObject);
  }

  /* -------------------- */

  getFormFieldClass(index: string | number = '', startsWith: boolean = true): string[] {
    let formFieldClass: string[];

    !_.isEmpty(this.themes) ?
      formFieldClass = [ ...this.themes ]:
      !!this.formC ?
        formFieldClass = [ ...this.formC.themes ]:
        formFieldClass = [ ...environment.formTheme ];

    if (!!this.icon) formFieldClass.push('has-icon');
    if (!this.isLabelUndefined) formFieldClass.push('has-label');
    if (!this.isEmpty()) formFieldClass.push('filled');
    if (this.isDisabled()) formFieldClass.push('is-disabled');
    if (this.isInvalid(index, startsWith)) formFieldClass.push('is-invalid');

    return formFieldClass;
  }

  /* -------------------- */

  persist(withInit?: boolean): void {
    this.form.persistControl(this.controlName, withInit);
  }

  select(option: any): void {
    if (this.isDisabled()) return;

    this.control.markAsDirty();
    this.control.markAsTouched();

    this.isMultiple() ?
      this.control.setValue(this.optionsIndexBy !== '_' && this._formFieldClassName !== 'FormDropzoneComponent' ?
        _.xorBy(this.control.value, [option], this.optionsIndexBy):
        _.xor(this.control.value, [option])):

      this.control.setValue(option);
  }

  unselect(option: any): void {
    if (this.isDisabled()) return;

    this.control.markAsDirty();
    this.control.markAsTouched();

    this.isMultiple() ?
      this.control.setValue(this.optionsIndexBy !== '_' && this._formFieldClassName !== 'FormDropzoneComponent' ?
        this.control.value.filter((item: any) => item[this.optionsIndexBy] !== option[this.optionsIndexBy]):
        this.control.value.filter((item: any) => item != option)):

      this.control.setValue('');
  }

  unselectAll(): void {
    if (this.isDisabled()) return;

    this.control.markAsDirty();
    this.control.markAsTouched();

    this.isMultiple() ?
      this.control.setValue([]):
      this.control.setValue('');
  }

  isSelected(option: any): boolean {
    const controlValue: any = this.control.value || '';

    return this.isMultiple() ?
      controlValue.find((item: any) => this.optionsIndexBy !== '_' && this._formFieldClassName !== 'FormDropzoneComponent' ?
        item[this.optionsIndexBy] === option[this.optionsIndexBy]:
        item == option):

      this.optionsIndexBy !== '_' && this._formFieldClassName !== 'FormDropzoneComponent' ?
        controlValue[this.optionsIndexBy] === option[this.optionsIndexBy]:
        controlValue == option;
  }

  trackByFn(index: any, option: any): any {
    return this._formFieldClassName !== 'FormDropzoneComponent' ?
      option.url:
      this.optionsIndexBy !== '_' ?
        option[this.optionsIndexBy]:
        index;
  }
}
