<button class="btn btn-app-red rounded-2"
    [class.py-2]="size === 'lg'"
    [disabled]="disabled"
    [tabindex]="tabIndex"
>
    <app-icon onleft name="caret-right-white">
        <strong class="_fs-16">
            <ng-content></ng-content>
        </strong>
    </app-icon>
</button>
