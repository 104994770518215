import { Component, OnInit, Input } from '@angular/core';
import { FormDropzoneComponent } from '../form-dropzone.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-form-dropzone-value',
  templateUrl: './form-dropzone-value.component.html',
})
export class FormDropzoneValueComponent implements OnInit {

  @Input() file: any;
  @Input() label: string;
  @Input() index: string | number = '';

  constructor(
    public parent: FormDropzoneComponent,
  ) { }

  ngOnInit() {
  }

  /* -------------------- */

  getFormFieldClass(index: string | number = ''): string[] {
    return this.parent.getFormFieldClass(index).filter((item: string) => item !== 'has-label');
  }

  getMimeType(): string {
    if (!this.file) return 'default';

    const mime: string = this.file.mime || '';

    if (mime.startsWith('image/')
      || this.file.url.endsWith('.jpg')
      || this.file.url.endsWith('.jpeg')
      || this.file.url.endsWith('.png')
    ) {
      return 'image';
    }

    if (mime.startsWith('video/')) {
      return 'video';
    }

    return 'default';
  }
}
