import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthLoginModalService } from '../components/core/auth/auth-login-modal/auth-login-modal.service';
import { NavService } from '../services/nav.service';
import { StoreService } from '../services/store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthUserCanCancelSubscriptionGuard implements CanActivate {

  constructor(
    private _storeS: StoreService,
    private _navS: NavService,
    private _authLoginModalS: AuthLoginModalService,
    private _router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.resolveCanActivate(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.resolveCanActivate(next, state);
  }

  /* -------------------- */

  resolveCanActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const can: boolean = this._storeS.authUser.value?.isLoggedIn() && this._storeS.authUser.value?.user.permissions.access_cancel_subscription_form;

    let queryParams: any = {
      redirectTo: state.url
    }

    if (!can) {
      this._router.navigate([this._navS.pages.HomePage.link()], {
        queryParams: queryParams,
      }).then(() => {
        this._authLoginModalS.open();
      });
    }

    return can;
  }
}
