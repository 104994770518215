<button type="button" class="btn-close btn-close-dark" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>

<div class="grid modal-body align-items-center p-0">
    <div class="row g-0 align-items-center">
        <div class="d-none d-lg-block col-28 multiplatform-img">
            <img class="img-fluid" src="assets/img/bg-auth-subscribe-modal-1.webp" alt="Multiplataformas" >
        </div>

        <div class="content grid grid-tb-inverse col col-lg-32">
            <div class="">
                <h3 class="_fs-36 ff-gilroy-b mb-0">Disfrutá Teatrix <span class="text-app-red">Gratis</span></h3>

                <p class="mb-5">Lo mejor del teatro online <span *ngIf="finishDate">hasta las {{ finishDate | date:'H:mm' }} hs del {{ finishDate | date:'dd/MM/yy' }}</span></p>

                <p><strong>1. </strong>Navegá en nuestro catálogo y/o buscá en la lupita tu obra favorita</p>
                <p><strong>2. </strong>Clickeá en ‘Reproducir’</p>
                <p><strong>3. </strong>Registrate gratis con tu nombre, apellido, email y contraseña</p>
                <p class="mb-5"><strong>4. </strong>¡Listo! Ya podés disfrutar GRATIS de Teatrix <span *ngIf="finishDate">hasta las {{ finishDate | date:'H:mm' }} hs del {{ finishDate | date:'dd/MM/yy' }}</span></p>

                <p class="">O bien haz click en el botón de abajo 👇, completá el registro sin costo alguno y luego elegí lo que quieras ver.</p>

                <a [routerLink]="navS.pages.SubscribePage.link()" [queryParams]="{ free: 'true' }">
                    <button class="btn btn-app-red text-uppercase rounded-3 px-4 mt-3">
                       <strong>Registrate ahora <span class="text-app-yellow-1">gratis</span><i class="fa fa-angle-right ms-2"></i></strong>
                    </button>
                </a>

                <p class="text-gray-600 _fs-14 mt-3">¿Dudas o consultas? Ingresá al <a class="link-gray-600 lh-md _fs-14 actionable fw-bolderest" [routerLink]="[navS.pages.HelpCenterPage.link()]" tabindex="0">centro de ayuda</a></p>
            </div>
        </div>
</div>
