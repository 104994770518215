<div class="form-group">
    <div #formFieldElem class="form-field" [ngClass]="getFormFieldClass()">
        <label *ngIf="!placeholder" class="form-label" (click)="formElem.focus()">
            <app-form-disabled [formFieldC]="this"></app-form-disabled>
            <!-- <app-form-error [formFieldC]="this"></app-form-error> -->
            {{ label || '&nbsp;' }}
        </label>

        <i *ngIf="icon" class="form-icon fa-fw" [ngClass]="icon"></i>

        <textarea #formElem class="form-control"
            [class.is-invalid]="isInvalid()"
            [attr.placeholder]="placeholder"
            [attr.rows]="rows"
            [formControl]="controlAsFormControl"
            [value]="control.value"
            (blur)="formFieldElem.classList.remove('focus')"
            (focus)="formFieldElem.classList.add('focus')"
        ></textarea>
    </div>

    <small *ngIf="help" class="form-text text-muted">{{ help }}</small>

    <ng-container *ngIf="!placeholder">
        <app-form-error [formFieldC]="this"></app-form-error>
    </ng-container>

    <ng-container *ngIf="placeholder && hasError()">
        <ng-container *ngFor="let error of getError()">
            <small class="form-text text-danger">{{ error }}</small>
        </ng-container>
    </ng-container>
</div>
