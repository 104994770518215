import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Play } from 'src/app/core/play/play.model';
import { ModalService } from 'src/app/services/modal.service';
import { PlayDetailModalComponent } from './play-detail-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PlayDetailModalService {

  playToOpen: Play;

  constructor(
    private _modalS: ModalService,
  ) { }

  /* -------------------- */

  set(play: Play): void {
    this.playToOpen = play;
  }

  /* -------------------- */

  open(play: Play | number): void {
    if (this._modalS.ssrS.isServer()) return;

    this._modalS.beforeOpen('PlayDetailModal');

    const modalRef = this._modalS.ngbModal.open(PlayDetailModalComponent, {
      windowClass: 'play-detail-modal-window fullscreen-sm',
      centered: true,
      size: 'lg',
    });

    if (play instanceof Play) {
      modalRef.componentInstance.play = play;
    } else if (this.playToOpen) {
      modalRef.componentInstance.play = this.playToOpen;
    } else {
      modalRef.componentInstance.playId = play;
    }

    this.playToOpen = null;

    this._modalS.navOnDismiss(modalRef, 'HomePage');
  }
}
