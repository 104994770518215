import { Taxonomies } from "./interfaces/taxonomy.interface";

export const taxonomies: Taxonomies = {
  _default: {
    title: 'TEATRIX | Teatro Online en HD',
    description: 'El mejor teatro online desde tu casa. Convertí tu sillón favorito en la mejor butaca y accede al más completo catálogo de obras de teatro nacionales e internacionales.',
    keywords: 'teatro, online, streaming, video, argentina, broadway, brasil, españa, mexico, {param_0}',
    robots: 'index,follow'
  },

  /* -------------------- */

  RegisterPage: {
    title: 'TEATRIX | Registrate para ver el mejor teatro Online',
    description: 'Distrutá del mejor teatro online, con un extenso catálogo de actores, directores y obras nacionales, de Broadway, Brasil, España y México.',
    keywords: 'teatrix, teatro, butaca, entrada, ticket, telon, online, streaming, video, argentina, broadway, brasil, españa, mexico',
    robots: 'index,follow'
  },

  AboutUsPage: {
    title: 'TEATRIX | El mejor teatro cuando quieras y desde donde quieras',
    description: 'TEATRIX es la primera plataforma del país que te permite ver obras de teatro online en alta definición, desde cualquier dispositivo mediante una suscripción mensual fija.',
    keywords: 'teatrix, teatro, online, streaming, video, hd, {param_0}',
    robots: 'index,follow'
  },

  BenefitsPage: {
    title: 'TEATRIX | Descuentos y Beneficios en tu suscripción',
    description: 'Accedé a descuentos en tu membresía de TEATRIX para usuarios de {param_nolowercase_0}',
    keywords: 'descuentos, beneficios, teatro, online, streaming, video, hd, {param_nolowercase_0}',
    robots: 'index,follow'
  },

  HelpCenterPage: {
    title: 'TEATRIX | Preguntas frecuentes y centro de ayuda',
    description: 'Más información sobre {param_nolowercase_0} para que no te queden dudas.',
    keywords: 'preguntas frecuentes, FAQ, teatrix, teatro, online, streaming, video, hd, {param_nolowercase_0}',
    robots: 'index,follow'
  },

  ContactPage: {
    title: 'TEATRIX | Contactanos en {param_0}, {param_1}',
    description: 'Conectá con TEATRIX en {param_0}, {param_1}, y encontranos en nuestras redes sociales como {param_nolowercase_0}',
    keywords: 'contacto, teatro, online, streaming, video, {param_nolowercase_0}, facebook, twitter, instagram, youtube, linkedin',
    robots: 'index,follow'
  },

  TermsPage: {
    title: 'TEATRIX | Términos y Condiciones de Uso',
    description: 'Términos y condiciones de uso de TEATRIX, la primera plataforma para ver el teatro en casa.',
    keywords: 'terminos, condiciones, teatro, online, streaming, video, hd, argentina, broadway, brasil, españa, mexico',
    robots: 'index,follow',
  },

  NotFoundPage: {
    title: 'TEATRIX | Teatro Online desde tu sillon favorito',
    description: 'Disfrutá del mejor teatro online, con obras nacionales e internacionales desde tu sillón favorito y sin moverte de tu casa.',
    keywords: 'teatro, online, streaming, video, {param_0}',
    robots: 'noindex,follow',
  },

  /* -------------------- */

  PlayDetailPage: {
    title: '{param_nolowercase_0} | {param_nolowercase_1} | TEATRIX',
    description: 'Disfrutá del mejor teatro online viendo {param_nolowercase_0}. {param_nolowercase_2}',
    keywords: '{param_nolowercase_0}, {param_nolowercase_1}, teatro, online, streaming, video, hd',
    robots: 'index,follow',
  },

  PlayViewObra: {
    title: '{param_nolowercase_0} | {param_nolowercase_1} | Obra completa TEATRIX',
    description: 'El mejor teatro online, disfrutá {param_nolowercase_0}',
    keywords: '{param_nolowercase_0}, {param_nolowercase_1}, teatro, online, streaming, video, hd',
    robots: 'noindex,nofollow',
  },

  PlayViewExtras: {
    title: 'Material extra de {param_nolowercase_0} | {param_nolowercase_1} | TEATRIX',
    description: 'El mejor teatro online, disfrutá material extra de {param_nolowercase_0}',
    keywords: '{param_nolowercase_0}, {param_nolowercase_1}, teatro, online, streaming, video, hd',
    robots: 'index,follow',
  },

  PlayCollectionsListPage: {
    title: 'Colecciones de obras | TEATRIX',
    description: 'Disfrutá de tus actores, directores y escritores favoritos en la más completa colección de obras de teatro online.',
    keywords: '{param_nolowercase_0}',
    robots: 'index,follow',
  },

  PlayCollectionDetailPage: {
    title: '{param_nolowercase_0} | TEATRIX',
    description: '{param_nolowercase_1}',
    keywords: '{param_nolowercase_0}',
    robots: 'index,follow',
  },

  //
};
