import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { ChangeVersionModalComponent } from './change-version-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ChangeVersionModalService {

  constructor(
    private _modalS: ModalService,
  ) { }

  /* -------------------- */

  open(): void {
    this._modalS.beforeOpen('ChangeVersionModal');

    const modalRef = this._modalS.ngbModal.open(ChangeVersionModalComponent, {
      windowClass: 'modal-window-light fullscreen-sm',
      centered: true,
      size: '',
    });
  }
}
