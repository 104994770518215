<div class="input-group d-flex" [class.show]="mustShow">
    <div class="input-overflow">
        <div class="input-content" (transitionend)="mustShow ? searchElem.focus() : null">
            <input #searchElem type="text" class="form-control" [placeholder]="placeholder"
                [ngModel]="query | async"
                (ngModelChange)="query.next($event)"
                (blur)="!playL.get()?.data.length ? close() : null"
                (keyup.escape)="close()"
                tabindex="-1"
            >

            <a *ngIf="query.value?.length && !isLoading" class="close" (click)="close()">
                <i class="fas fa-times"></i>
            </a>

            <span *ngIf="isLoading" class="loading">
                <div class="spinner-border spinner-border-sm text-app-red" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </span>
        </div>
    </div>

    <ng-container *ngIf="!static">
        <div *ngIf="mustShow && playL.get()?.data.length" class="input-results" data-simplebar>
            <ng-container *ngFor="let play of playL.get()?.data; last as last">
                <app-play-box type="result" [play]="play" (click)="close()"></app-play-box>
                <hr *ngIf="!last" class="px-3 my-3">
            </ng-container>
        </div>
    </ng-container>

    <button class="btn btn-app-red d-flex align-items-center justify-content-center" type="button" (click)="open()" (keyup.enter)="open()" aria-label="Buscar" tabindex="0">
        <app-icon name="search-white" [size]="1.25"></app-icon>
    </button>
</div>
