import { Component, Input, OnInit } from '@angular/core';
import { AuthSubscribeModalContentComponent } from '../auth-subscribe-modal-content/auth-subscribe-modal-content.component';

@Component({
  selector: 'app-auth-subscribe-modal-step-counter',
  templateUrl: './auth-subscribe-modal-step-counter.component.html',
  styleUrls: ['./auth-subscribe-modal-step-counter.component.scss']
})
export class AuthSubscribeModalStepCounterComponent implements OnInit {

  @Input() step: number = 1;
  @Input() innerClass: string;

  constructor(
    public parent: AuthSubscribeModalContentComponent,
  ) { }

  ngOnInit(): void {
  }

}
