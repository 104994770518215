import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { LiveModalComponent } from './live-modal.component';
import { IconModule } from '../../commons/icon/icon.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    LiveModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IconModule,
    RouterModule,
  ]
})
export class LiveModalModule { }
