import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appMinutes'
})
export class MinutesPipe implements PipeTransform {

  transform(value: number, format?: 'hours'): number|string {

    if (format === 'hours') {
      const hours = Math.floor(value / 60 / 60);
      const minutes = Math.floor(value / 60) - (hours * 60);
      const seconds = Math.floor(value % 60);

      return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    }

    return Math.floor(value / 60);
  }
}
