import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { AuthLoginModalComponent } from './auth-login-modal.component';
import { FormsModule } from 'src/app/packages/forms/forms.module';
import { RouterModule } from '@angular/router';
import { ButtonFormModule } from 'src/app/components/buttons/button-form/button-form.module';



@NgModule({
  declarations: [
    AuthLoginModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ButtonFormModule,
  ]
})
export class AuthLoginModalModule { }
