import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormDropzoneComponent } from '../form-dropzone.component';
import { FileHttpService } from 'src/app/services/http/file-http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { Request } from 'src/app/commons/request';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-form-dropzone-upload',
  templateUrl: './form-dropzone-upload.component.html',
})
export class FormDropzoneUploadComponent implements OnInit {

  @ViewChild('formElem', { static: true }) formElem: ElementRef;

  request: Request = new Request;

  private _sm: SubscriptionManager = new SubscriptionManager;

  constructor(
    public parent: FormDropzoneComponent,
    private _fileHttpS: FileHttpService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.makeDroppable(this.formElem.nativeElement);
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  /* -------------------- */

  makeDroppable(elem: any) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.style.display = 'none';

    if (this.parent.isMultiple()) {
      input.setAttribute('multiple', 'true');
    }

    input.addEventListener('change', (event: Event) => {
      if (this.parent.isDisabled()) return;
      triggerCallback(event);
    });

    elem.appendChild(input);

    elem.addEventListener('dragover', (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      if (this.parent.isDisabled()) return;

      elem.classList.add('dragging');
    });

    elem.addEventListener('dragleave', (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      if (this.parent.isDisabled()) return;

      elem.classList.remove('dragging');
    });

    elem.addEventListener('drop', (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      if (this.parent.isDisabled()) return;

      this.parent.control.markAsDirty();
      this.parent.control.markAsTouched();

      elem.classList.remove('dragging');
      triggerCallback(event);
    });

    elem.addEventListener('click', () => {
      if (this.parent.isDisabled()) return;

      this.parent.control.markAsDirty();
      this.parent.control.markAsTouched();

      input.value = null;
      input.click();
    });

    const triggerCallback = (event: any) => {
      if (this.parent.isDisabled()) return;

      let files: any[] = [];

      if (event.dataTransfer) {
        files = event.dataTransfer.files;
      } else if (event.target) {
        files = event.target.files;
      }

      this.upload(files);
    };
  }

  upload(files: any[]) {
    if (!this.request.isLoading() && files.length) {
      this.parent.removeError();

      const formData = new FormData();

      if (this.parent.isMultiple()) {
        Array.from(files).forEach((file: any) => {
          formData.append(this.parent.controlName + '[]', file);
        });
      } else {
        formData.append(this.parent.controlName, files[0]);
      }

      this._sm.add(this.request.send(this._fileHttpS.upload(this.parent.concept, formData)).pipe(
        finalize(() => {
          this.parent.control.markAsPristine();
          this.parent.control.markAsUntouched();
        })
      ).subscribe((res: any) => {
        if (this.parent.isMultiple()) {
          let value: any[] = [
            ...this.parent.control.value,
            ...res.body.files
          ];

          this.parent.control.setValue(value);
        } else {
          this.parent.control.setValue(res.body.file);
        }
      }, (err: HttpErrorResponse) => {
        if (this.parent.isMultiple()) {
          if (this.request.error.but && this.request.error.but.files) {
            let value: any[] = [
              ...this.parent.control.value,
              ...this.request.error.but.files
            ];

            this.parent.control.setValue(value);
          }
        } else {
          if (this.request.error.but && this.request.error.but.file) {
            this.parent.control.setValue(this.request.error.but.file);
          }
        }

        this.parent.request.error = _.merge(this.parent.request.error, this.request.error);
      }), 'upload');
    }
  }
}
