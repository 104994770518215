<div *ngIf="request.error && request.error.status !== 422">
<!-- <div *ngIf="request.error"> -->
    <div [class]="class">
        <ngb-alert class="_f-0-8 m-0" type="danger" [dismissible]="false">
            <ng-container [ngSwitch]="request.error.status">
                <ng-container *ngSwitchCase="422">
                    <p><strong>Ha ocurrido un error.</strong></p>
                    <p>&raquo; {{ request.error.message }} (<a (click)="showMore = !showMore">Ver {{ showMore ? 'menos' : 'más' }}</a>)</p>

                    <ul *ngIf="showMore" class="mb-0 mt-2">
                        <li *ngFor="let key of _.keys(request.error.errors);">
                            [{{ key }}]:
                            <ul>
                                <li *ngFor="let error of request.error.errors[key];">{{ error }}</li>
                            </ul>
                        </li>
                    </ul>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <p><strong>Ha ocurrido un error.</strong></p>
                    <p>&raquo; {{ request.error.message }}</p>
                </ng-container>
            </ng-container>
        </ngb-alert>
    </div>
</div>

