<div class="form-group">
    <div #formFieldElem class="form-field" [ngClass]="getFormFieldClass()">
        <label *ngIf="!placeholder" class="form-label" (click)="formElem.focus()">
            <!-- <app-form-disabled [formFieldC]="this"></app-form-disabled> -->
            <!-- <app-form-error [formFieldC]="this"></app-form-error> -->
            {{ label || '&nbsp;' }}
        </label>

        <i *ngIf="icon" class="form-icon fa-fw" [ngClass]="icon"></i>

        <input #formElem class="form-control"
            [class.is-invalid]="isInvalid()"
            [attr.type]="type === 'password' && show ? 'text' : type"
            [attr.placeholder]="placeholder"
            [attr.name]="controlName"
            [attr.readonly]="readonly ? true : null"
            [formControl]="controlAsFormControl"
            [value]="!mask ? control.value : ''"
            (blur)="formFieldElem.classList.remove('focus')"
            (focus)="formFieldElem.classList.add('focus')"
            [minlength]="minlength"
            [maxlength]="maxlength"
            [mask]="mask"
            [prefix]="maskPrefix"
            [thousandSeparator]="thousandSeparator"
            [validation]="maskValidation"
            [attr.aria-label]="label"
        >
    </div>

    <small *ngIf="help" class="form-text text-muted">{{ help }}</small>

    <!-- <pre class="d-block small m-0 form-text text-muted">Mask:   {{ mask }}</pre>
    <pre class="d-block small m-0 form-text text-muted">Value:  {{ control.value }}</pre>
    <pre class="d-block small m-0 form-text text-muted">Masked: {{ control['valueMasked'] }}</pre> -->

    <ng-container *ngIf="!placeholder">
        <app-form-error [formFieldC]="this"></app-form-error>
    </ng-container>

    <ng-container *ngIf="placeholder && hasError()">
        <ng-container *ngFor="let error of getError()">
            <small class="form-text text-danger">{{ error }}</small>
        </ng-container>
    </ng-container>
</div>
