import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { List } from 'src/app/commons/list';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { Play } from 'src/app/core/play/play.model';
import { PlayHttpService } from 'src/app/services/http/play-http.service';

@Component({
  selector: 'app-play-searchbar',
  templateUrl: './play-searchbar.component.html',
  styleUrls: ['./play-searchbar.component.scss']
})
export class PlaySearchbarComponent implements OnInit, OnDestroy {

  @Input() static: boolean = false;
  @Input() playL: List<Play> = new List<Play>();
  @Input() placeholder: string = 'Buscar';

  query: BehaviorSubject<string> = new BehaviorSubject('');
  mustShow: boolean = false;

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    private _playHttpS: PlayHttpService
  ) { }

  ngOnInit(): void {
    this.static ? this.mustShow = true : null;

    this._sm.add(
      this.query.pipe(
        skip(1),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(value => this.getBySearch(value))
    );
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  get isLoading(): boolean {
    if (this.static) {
      return false;
    }

    return this.playL.request.isLoading();
  }

  /* -------------------- */

  open(): void {
    this.mustShow = true;
  }

  close(): void {
    this.query.next('');

    if (!this.static) {
      this.mustShow = false;
    }
  }

  /* -------------------- */

  getBySearch(query: string) {
    this._sm.clean('getBySearch');
    this.playL.reset();

    if (query.length > 2) {
      this._sm.add(
        this.playL.request.send(this._playHttpS.getBySearch(query)).subscribe(res => {
          this.playL.add(res, Play);
        })
      , 'getBySearch');
    }
  }
}
