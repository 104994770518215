<button type="button" class="btn-close btn-close-dark" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>

<div class="grid modal-body text-center align-items-center">
    <div class="py-4 px-sm-5">
        <h3 class="ff-gilroy-b mt-5 mb-3">¿Cómo fue tu experiencia?</h3>

        <p class="my-4">No olvides enviarnos tus comentarios acerca de tu experiencia en la nueva versión de TEATRIX a <a href="mailto:{{ info.mailtoInfo }}" target="_blank">info@teatrix.com</a></p>

        <a  (click)="GoBack()">
            <button class="btn btn-app-red rounded-3 px-4 mt-3">
               <strong>Ir a la versión anterior</strong>
            </button>
        </a>
    </div>
</div>
