import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface ICreditCardInput {
  number: any,
  cvc: any,
  exp_month: any,
  exp_year: any,
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  constructor(
    //
  ) { }

  /* -------------------- */

  async createToken(creditCard: ICreditCardInput): Promise<string> {
    const stripe = window['Stripe'];

    if (!stripe) {
      return Promise.reject();
    }

    stripe.setPublishableKey(environment.stripeKey);

    return new Promise<string>((resolve, reject) => {
      stripe.createToken(creditCard, (status: number, res: any) => {
        if (status === 200 && res.id) {
          return resolve(res.id);
        }

        return reject();
      });
    });
  }
}
