<ng-template #cover let-img="img" let-ratio="ratio">
    <div class="play-box-cover rounded-3 shadow ratio" [ngClass]="ratio" [class.loading]="loading">
        <ng-container *ngIf="!loading && play">
            <img [src]="img" [alt]="play.title">
        </ng-container>
    </div>
</ng-template>

<ng-container *ngIf="type === 'default'">
    <div class="d-lg-none">
        <ng-container [ngTemplateOutlet]="cover" [ngTemplateOutletContext]="{ img: play?.img_box_vertical_url, ratio: 'ratio-3x4' }"></ng-container>
    </div>

    <div class="d-none d-lg-block">
        <ng-container [ngTemplateOutlet]="cover" [ngTemplateOutletContext]="{ img: play?.img_box_url, ratio: 'ratio-16x9' }"></ng-container>
    </div>
</ng-container>

<ng-container *ngIf="type === 'vertical'">
    <ng-container [ngTemplateOutlet]="cover" [ngTemplateOutletContext]="{ img: play?.img_box_vertical_url, ratio: 'ratio-3x4' }"></ng-container>
</ng-container>
