import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Play } from 'src/app/core/play/play.model';



@Component({
  selector: 'app-play-detail-modal',
  templateUrl: './play-detail-modal.component.html',
  styleUrls: ['./play-detail-modal.component.scss']
})
export class PlayDetailModalComponent implements OnInit {

  @Input() play: Play;
  @Input() playId: number;

  constructor(
    public modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    //
  }
}
