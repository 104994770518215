import { delay } from "rxjs/operators";
import { UserStatusEnum } from "./enums/user-status.enum";

export interface IUser {
  id: number;
  username: string;
  fullname: string;
  email: string;
  email_verified_at: string;
  phone: string;
  gender: string;
  country: string;
  password: string;
  status: {
    is_active: boolean,
    is_active_unsubscribed: boolean,
    is_admin: boolean,
    is_cancel: boolean,
    is_courtesy: boolean,
    is_free: boolean,
    is_no_plan: boolean,
    is_pending: boolean,
    is_processing: boolean,
  };
  play_list: number[];
  play_favorite_list: number[];
  permissions: any;
  payment_coupon_url: string;
  offline_payment: number;
  _subscription_cancel_at: Date;
  invoices?: IUserInvoices[];
  next_invoice?: string;
}

export interface IUserInvoices {
  id: number;
  date: any;
  voucher: string;
  number: string;
  name: string;
  detail: string;
  payment_id: string;
  payment_method: string;
  status: number;
  file_pdf: string;
  credit_card_entity?: string;
  credit_card_number?: string;
}

export class User implements IUser {

  id: number;
  username: string;
  fullname: string;
  email: string;
  email_verified_at: string;
  phone: string;
  gender: string;
  country: string;
  password: string;
  status: {
    is_active: boolean,
    is_active_unsubscribed: boolean,
    is_admin: boolean,
    is_cancel: boolean,
    is_courtesy: boolean,
    is_free: boolean,
    is_no_plan: boolean,
    is_pending: boolean,
    is_pending_credit_card : boolean,
    is_processing: boolean,
    is_active_external: boolean,
  };
  play_list: number[];
  play_favorite_list: number[];
  permissions: any;
  payment_coupon_url: string;
  external_cancel_subscription_url: string;
  offline_payment: number;
  _subscription_cancel_at: Date;
  invoices?: IUserInvoices[];
  next_invoice?: string;

  /* -------------------- */

  constructor(value: any) {
    this.set(value);
  }

  get subscription_cancel_at(): Date {
    return this._subscription_cancel_at;
  }

  set subscription_cancel_at(value: any) {
    this._subscription_cancel_at = new Date(value);
  }

  /* -------------------- */

  set(value: any): void {
    Object.assign(this, value);

    let status: UserStatusEnum = null;

    value?.user_id ? this.id = value?.user_id : null;
    value?.user_name ? this.fullname = value?.user_name : null;
    value?.name ? this.fullname = value?.name : null;
    value?.user_email ? this.email = value?.user_email : null;
    value?.email_verified_at ? this.email_verified_at = value?.email_verified_at : null;
    value?.data?.email_verified_at ? this.email_verified_at = value?.data?.email_verified_at : null;
    value?.cancel_date ? this.subscription_cancel_at = value?.cancel_date : null;
    value?.date_cancel ? this.subscription_cancel_at = value?.date_cancel : null;
    value?.statusClient ? status = value?.statusClient : null;
    value?.status_name ? status = value?.status_name : null;
    value?.subscription_status ? status = value?.subscription_status : null;
    value?.linkDownload ? this.payment_coupon_url = value?.linkDownload : null;
    value?.offline_payment ? this.offline_payment = value?.offline_payment : null;
    value?.url_cancel_subscription ? this.external_cancel_subscription_url = value?.url_cancel_subscription : null;

    if (!value?.cancel_date && !value?.date_cancel && (!value?.Subscriptions || !value?.Transactional)) {
      delete this._subscription_cancel_at;
    }

    if (status) {
      this.status = {
        is_active: status === UserStatusEnum.Active,
        is_active_unsubscribed: status === UserStatusEnum.Active && !!this.subscription_cancel_at,
        is_active_external: status === UserStatusEnum.Active && !!value.is_external,
        is_admin: status === UserStatusEnum.Admin,
        is_cancel: status === UserStatusEnum.Cancel,
        is_courtesy: status === UserStatusEnum.Courtesy,
        is_free: status === UserStatusEnum.Free,
        is_no_plan: status === UserStatusEnum.NoPlan,
        is_pending: status === UserStatusEnum.Pending,
        is_pending_credit_card: status === UserStatusEnum.Pending && this.offline_payment === 0,
        is_processing: status === UserStatusEnum.Processing,
      }
    }

    this.permissions = {
      play: {
        list: this.status.is_active || this.status.is_active_unsubscribed || this.status.is_admin || this.status.is_courtesy || this.status.is_processing,
        view: this.status.is_active || this.status.is_active_unsubscribed || this.status.is_admin || this.status.is_courtesy || this.status.is_processing,
      },
      play_ppv: {
        buy_with_discounts: this.status.is_active || this.status.is_active_unsubscribed || this.status.is_processing,
        view_without_buy: this.status.is_admin || this.status.is_courtesy,
      },
      subscribe: !this.hasSubscription() || !!this.subscription_cancel_at,
      subscribe_from_account: this.status.is_cancel || this.status.is_free || this.status.is_no_plan,
      subscribe_from_bar: !this.status.is_active_unsubscribed && !this.status.is_pending && !this.status.is_pending_credit_card,
      cancel_unsubscription: this.status.is_active_unsubscribed,
      cancel_subscription_from_account: (this.status.is_active || this.status.is_processing) && !this.subscription_cancel_at,
      access_cancel_subscription_form: (this.status.is_active || this.status.is_processing) && !this.subscription_cancel_at && !!this.email_verified_at,
      send_email_verification: !this.email_verified_at,
    }

    if (value?.Subscriptions || value?.Transactional) {
      let invoices = [];
      invoices = value?.Subscriptions ? value?.Subscriptions : value?.Transactional;

      if (value?.Subscriptions && value?.Transactional) {
        invoices = invoices.concat(value?.Transactional);
      }

      this.invoices = [];

      for (const invoice of invoices) {
        const invoiceNew: any = [];

        invoice.cardname ? invoiceNew.credit_card_entity = invoice.cardname : null;
        invoice.cardnumber ? invoiceNew.credit_card_number = invoice.cardnumber : null;
        invoice.concept ? invoiceNew.detail = invoice.concept : null;
        invoice.date ? invoiceNew.date = invoice.date : null;
        invoice.documentid ? invoiceNew.number = invoice.documentid : null;
        invoice.id ? invoiceNew.id = invoice.id : null;
        invoice.method ? invoiceNew.payment_method = invoice.method : null;
        invoice.payment_id ? invoiceNew.payment_id = invoice.payment_id : null;
        invoice.name ? invoiceNew.name = invoice.name : null;
        invoice.status ? invoiceNew.status = invoice.status : null;
        invoice.type ? invoiceNew.voucher = invoice.type : null;
        invoice.uri ? invoiceNew.file_pdf = invoice.uri : null;

        this.invoices.push(invoiceNew);
      }

      this.invoices.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
    }
  }

  /* -------------------- */

  hasSubscription(): boolean {
    return this.status.is_active
      || this.status.is_active_unsubscribed
      || this.status.is_admin
      || this.status.is_courtesy
      || this.status.is_processing
    ;
  }
}
