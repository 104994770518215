import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAppendHtml]'
})
export class AppendHtmlDirective implements OnInit {

  @Input('appAppendHtml') elemToAppend: HTMLElement;

  constructor(
    private _elem: ElementRef,
    private _renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this._renderer.appendChild(this._elem.nativeElement, this.elemToAppend);
  }
}
