import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input() name: string;
  @Input() size = 1;
  @Input() width;
  @Input() height;
  @Input() unit = 'rem';
  @Input() hasLoadingBtn: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  get path(): string {
    if (!this.name.startsWith('http')) {
      return 'assets/icons/' + this.name + '.svg';
    }

    return this.name;
  }
}
