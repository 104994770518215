import { Form } from './form';
import * as _ from 'lodash';

export class FormMapping {

  constructor(private _form: Form) { }

  /* -------------------- */

  from(source: any) {
    let target = _.cloneDeep(source);

    _.forEach(this._form.getExtras(), (extra: any, name: string) => {
      if (!!extra.mapping && !!extra.mapping.from) {
        target = extra.mapping.from(target, source);
      }
    });

    return target;
  }

  to() {
    let target = _.cloneDeep(this._form.group.value);
    let source = _.cloneDeep(target);

    _.forEach(this._form.getExtras(), (extra: any, name: string) => {
      if (!!extra.mapping && !!extra.mapping.to) {
        source = extra.mapping.to(target, source);
      }
    });

    return source;
  }
}
