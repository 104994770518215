import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-play',
  templateUrl: './button-play.component.html',
  styleUrls: ['./button-play.component.scss']
})
export class ButtonPlayComponent implements OnInit {

  @Input() size: 'md' | 'lg' = 'md';
  @Input() disabled: boolean = false;
  @Input() tabIndex: string = '0';

  constructor() { }

  ngOnInit(): void {
  }

}
