import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { NumberPipe } from './number.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeStylePipe } from './safe-style.pipe';
import { SearchPipe } from './search.pipe';
import { JsonStringPipe } from './json-string.pipe';
import { HtmlStripPipe } from './html-strip.pipe';
import { MinutesPipe } from './minutes.pipe';
import { Nl2BrPipe } from './nl2br.pipe';
import { MaskPipe } from 'ngx-mask';
import { DaysPipe } from './days.pipe';
import { NumberFloorPipe } from './number-floor.pipe';
import { DecimalsPipe } from './decimals.pipe';



@NgModule({
  declarations: [
    SearchPipe,
    FilterPipe,
    NumberPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    JsonStringPipe,
    HtmlStripPipe,
    MinutesPipe,
    DaysPipe,
    Nl2BrPipe,
    NumberFloorPipe,
    DecimalsPipe,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    MaskPipe,
    /* -------------------- */
    SearchPipe,
    FilterPipe,
    NumberPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    JsonStringPipe,
    HtmlStripPipe,
    MinutesPipe,
    DaysPipe,
    Nl2BrPipe,
    NumberFloorPipe,
    DecimalsPipe,
  ],
  exports: [
    SearchPipe,
    FilterPipe,
    NumberPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    JsonStringPipe,
    HtmlStripPipe,
    MinutesPipe,
    DaysPipe,
    Nl2BrPipe,
    NumberFloorPipe,
    DecimalsPipe,
  ]
})
export class PipesModule { }
