<div class="container-fluid">
    <app-separator></app-separator>

    <footer class="text-center text-marginless py-5">
        <app-social-links></app-social-links>

        <div class="links my-3">
            <p>
                <a *ngIf="!storeS.authUser.value?.user.hasSubscription()" class="link-gray-400 actionable" [routerLink]="navS.pages.RegisterPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Suscribite</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.AboutUsPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">¿Qué es Teatrix?</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.HelpCenterPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Centro de ayuda</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.BenefitsPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Beneficios de partners</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.SubscriptionPlansPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Planes</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.ContactPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Contacto</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.GiftCardsPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Regalá Teatrix</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.CorporativePage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Regalos corporativos</app-icon></a>
                <a class="link-gray-400 actionable" href="https://proveedores.teatrix.com/" target="_blank"><app-icon onleft name="caret-right-red" [size]="0.5">Acceso a proveedores</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.TermsPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Términos y condiciones</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.PrivacyPolicyPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Política de privacidad</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.CookiesPolicyPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Política de cookies</app-icon></a>
                <a class="link-gray-400 actionable" [routerLink]="navS.pages.ConsumerProtectionPage.link()"><app-icon onleft name="caret-right-red" [size]="0.5">Defensa al consumidor</app-icon></a>
            </p>
        </div>

        <div class="copyright">
            <p class="text-white _fs-18">&copy; Teatrix {{ now.getFullYear() }}. Todos los derechos reservados.</p>
        </div>
    </footer>
</div>
