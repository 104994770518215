<h2 class="lh-1 mb-0"
    [class._fs-22]="size === 'md'"
    [class._fs-28]="size === 'lg'"
>
    <strong>{{ play.title }}</strong>
</h2>

<ng-container *ngIf="withSubtitle">
    <p class="subtitle mb-0 _mt-0-3 _lh-1-3"
        [class.mt-1]="size === 'lg'"
        [class._fs-14]="size === 'md'"
        [class._fs-15]="size === 'lg'"
    >
        <strong class=""><span class="text-gray-600">de </span>{{ play.author }}</strong><span> • {{ play.year }}</span><ng-container *ngIf="play.duration"><span> • {{ play.duration | appMinutes }} min</span></ng-container>
    </p>
</ng-container>

<ng-container *ngIf="withFeatures">
    <p class="fw-bold text-uppercase text-gray-600 mb-0 _mt-0-7 _fs-13">
        <ng-container *ngIf="play.is_premiere">
            <span class="separator mx-2">|</span>
            <app-icon onleft name="start-red" [size]="1"><span class="text-app-red">Estreno</span></app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_fullhd">
            <span class="separator mx-2">|</span>
            <app-icon class="text-lowercase" onleft name="fullhd" [size]="1.2">1080p</app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_atp">
            <span class="mx-2 text-app-red">•</span>
            <app-icon name="atp" [size]="1.2"></app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_plus_13">
            <span class="mx-2 text-app-red">•</span>
            <app-icon name="plus-13" [size]="1.3"></app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_plus_16">
            <span class="mx-2 text-app-red">•</span>
            <app-icon name="plus-16" [size]="1.3"></app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_plus_18">
            <span class="mx-2 text-app-red">•</span>
            <app-icon name="plus-18" [size]="1.3"></app-icon>
        </ng-container>

        <ng-container *ngIf="play.has_subtitles || play.is_subtitled">
            <span class="separator mx-2">|</span>
            <app-icon onleft name="cc" [size]="1.2">Obra subtitulada</app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_original">
            <span class="separator mx-2">|</span>
            <app-icon onleft name="teatrix" [size]="1">Original</app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_from_broadway">
            <span class="separator mx-2">|</span>
            <app-icon onleft name="countries/estados-unidos" [size]="1">Broadway</app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_from_mexico">
            <span class="separator mx-2">|</span>
            <app-icon onleft name="countries/mexico" [size]="1">México</app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_from_espana">
            <span class="separator mx-2">|</span>
            <app-icon onleft name="countries/espana" [size]="1">España</app-icon>
        </ng-container>

        <ng-container *ngIf="play.is_recovered_play">
            <span class="separator mx-2">|</span>
            <app-icon onleft name="recovered-play" [size]="1">Obra recuperada</app-icon>
        </ng-container>
    </p>
</ng-container>
