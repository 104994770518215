import { Injectable } from '@angular/core';
import supportsWebP from 'supports-webp';

@Injectable({
  providedIn: 'root'
})
export class SupportsWebpService {

  supportsWebp: boolean = true;

  constructor( ) {
    supportsWebP.then(supported => {
      if (supported) {
        this.supportsWebp = true;
      } else {
        this.supportsWebp = false;
      }
    });
  }
}
