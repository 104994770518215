import { common } from './common';

export const environment = {
  ...common,
  production: false,
  debug: true,
  url: 'https://www.teatrix.com',
  apiUrl: 'https://v2beta.teatrix.com',
  playerUrl: 'https://playerbeta.teatrix.com',
  apiUrlCache: 'https://beta.teatrix.com',
  stripeKey:'pk_test_51H8AjQIp0WAeFMHipuAYME7fApWTTNfTajLQoCnjQgk3Qhdsr22tV0kqeMdrEEsXRQoG0sG7ysab7A4M99AhPYxP00LJZaT4Ky',
  lang_default: 'es-AR',
  lang_src_prefix: 'https://www.teatrix.com/public/',
};
