<div class="form-group">
    <div class="form-field" [ngClass]="getFormFieldClass(index)"
        [class.has-label]="(parent.isMultiple() && !!label) || (!parent.isMultiple() && !!parent.label)"
    >
        <label *ngIf="parent.isMultiple()">
            <app-form-disabled [formFieldC]="parent"></app-form-disabled>
            <app-form-error [formFieldC]="parent" [index]="index"></app-form-error>
            {{ label || '&nbsp;' }}
        </label>

        <label *ngIf="!parent.isMultiple()">
            <app-form-disabled [formFieldC]="parent"></app-form-disabled>
            <app-form-error [formFieldC]="parent"></app-form-error>
            {{ parent.label || '&nbsp;' }}
        </label>

        <div class="form-control"
            [class.is-invalid]="parent.isInvalid(index)"
        >
            <div class="form-control-actions">
                <a class="text-gray-500" [href]="file.url" target="_blank">
                    <i class="fa-fw fas fa-external-link-alt"></i>
                </a>

                <a *ngIf="!parent.isDisabled()" class="text-gray-500 ml-1" href="javascript:;" (click)="parent.unselect(file)">
                    <i class="fa-fw far fa-trash-alt"></i>
                </a>

                <a *ngIf="!parent.isDisabled() && parent.control.value.length > 1" class="text-gray-500 ml-auto" href="javascript:;">
                    <i class="fa-fw fas fa-arrows-alt"></i>
                </a>
            </div>

            <div class="form-control-value">
                <ng-container [ngSwitch]="getMimeType()">
                    <ng-container *ngSwitchCase="'image'">
                        <img class="form-control-value-multimedia" [src]="file.url">
                    </ng-container>

                    <ng-container *ngSwitchCase="'video'">
                        <video class="form-control-value-multimedia" controls>
                            <source [src]="file.url" [attr.type]="file.mime">
                            Your browser does not support the video tag.
                        </video>
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <div class="form-control-value-default">
                            <i class="fas fa-file-alt _f-3"></i>
                            <span class="d-block text-truncate mt-1 _w-100">{{ file.name }}</span>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <small *ngIf="!parent.isMultiple() && parent.help" class="form-text text-muted">{{ parent.help }}</small>
</div>
