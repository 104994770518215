import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArrayButtonsComponent } from './form-array-buttons.component';



@NgModule({
  declarations: [FormArrayButtonsComponent],
  imports: [
    CommonModule
  ],
  exports: [FormArrayButtonsComponent]
})
export class FormArrayButtonsModule { }
