import { plays } from "../plays";

export const redirect = (path: string, queryParams?: string): string | false => {

  const paths = path.split('/');

  if (
    [
      '/bienvenidos',
      '/como-funciona-teatrix',
      '/compra',
      '/clubnacion',
      '/promo',
      '/promo-inter',
    ].includes(path)
    || path.startsWith('/canjea/')
    || path.startsWith('/registro-free/')
  ) {
    return `/suscribite`;
  }

  if (path === '/mi-perfil') {
    return `/mi-cuenta`;
  }

  if (path.startsWith('/trailer/')) {
    const playSlug = paths[2];
    const playId = plays[playSlug] || null;

    return playId
      ? `/preview/${playSlug}/${playId}`
      : `/`;
  }

  if (path.startsWith('/video/') || path.startsWith('/obra-de-teatro/')) {
    const playSlug = paths[2];
    const playId = plays[playSlug] || null;

    return playId
      ? `/obras/${playSlug}/${playId}`
      : `/`;
  }

  if (path.startsWith('/ver/')) {
    const playSlug = paths[2];
    const playId = plays[playSlug] || null;

    return playId
      ? `/obras/${playSlug}/${playId}/videos/obra`
      : `/`;
  }

  if (path === '/registro') {
    return queryParams
      ? '/suscribite-hoy' + queryParams
      : '/suscribite-hoy';
  }

  if (path === '/contactos') {
    return `/contacto`;
  }

  return false;
};
