<div *ngIf="isInvalid()" [class]="innerClass">
    <ng-container *ngIf="hasError()">
        <ul class="ps-3 m-0 text-danger">
            <ng-container *ngFor="let error of getError()">
                <li class="_f-0-75">{{ error }}</li>
            </ng-container>
        </ul>
    </ng-container>
</div>

