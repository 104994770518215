import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDisabledComponent } from './form-disabled.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [FormDisabledComponent],
  imports: [
    CommonModule,
    NgbPopoverModule
  ],
  exports: [FormDisabledComponent]
})
export class FormDisabledModule { }
