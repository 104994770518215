import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CreditCard } from 'src/app/commons/credit-card/credit-card';
import { CreditCardTypeEnum } from 'src/app/commons/credit-card/enums/credit-card-type.enum';
import { creditCardValidator } from 'src/app/commons/credit-card/validators/credit-card.validator';
import { List } from 'src/app/commons/list';
import { Request } from 'src/app/commons/request';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { Play } from 'src/app/core/play/play.model';
import { info } from 'src/app/info';
import { Form } from 'src/app/packages/forms/tools/form';
import { HtmlStripPipe } from 'src/app/pipes/html-strip.pipe';
import { AuthUserBenefitHttpService } from 'src/app/services/http/auth-user-benefit.http.service';
import { AuthUserHttpService } from 'src/app/services/http/auth-user-http.service';
import { CombosHttpService } from 'src/app/services/http/combos-http.service';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';


@Component({
  selector: 'app-auth-subscribe-modal-content',
  templateUrl: './auth-subscribe-modal-content.component.html',
  styleUrls: ['./auth-subscribe-modal-content.component.scss']
})
export class AuthSubscribeModalContentComponent implements OnInit {

  @Input() play: Play;
  @Input() ppvCode: boolean;
  @Input() formClass: string = '';

  step: number = 1;
  skipStep2: boolean = false;
  useMocks: boolean = false;
  isFreeRegister: boolean = false;

  formRegister: Form;
  formSubscribe: Form;
  formCheckout: Form;

  planL: List<any> = new List<any>();
  planSelectedPrev: any = null;

  discount: number = 0;
  discountName: string = null;
  discountDescription: string = null;
  discountDuration: string = null;
  discountPlan: any = null;
  discountRequest: Request = new Request();

  paymentMethodOfflineDownloadLink: string;

  private _sm: SubscriptionManager = new SubscriptionManager();

  constructor(
    public modal: NgbActiveModal,
    public navS: NavService,
    public storeS: StoreService,
    public authUserS: AuthUserHttpService,
    private _authUserBenefitHttpS: AuthUserBenefitHttpService,
    private _combosHttpS: CombosHttpService,
    private _fb: UntypedFormBuilder,
    private _elem: ElementRef,
    private _route: ActivatedRoute,
    private _htmlStripPipe: HtmlStripPipe,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this._elem.nativeElement.querySelector('.content').focus();
    });

    if (this._route.snapshot.queryParamMap.get('step2') === "true") {
      this.skipStep2 = true;
    }

    if (this._route.snapshot.queryParamMap.get('free') === "true") {
      this.isFreeRegister = true;
    }

    if (this.storeS.authUser.value?.isLoggedIn()) {
      this.step = 2;

      if (this.isPpv) {
        if (this.play.ppv.bought) {
          this.step = 4;
        }
      } else {
        if (!this.canSubscribe) {
          this.step = 4;
        }

        if (this.storeS.authUser.value?.user.status.is_pending && this.storeS.authUser.value?.user.payment_coupon_url) {
          this.step = 4;
          this.paymentMethodOfflineDownloadLink = this.storeS.authUser.value?.user.payment_coupon_url;
        }
      }
    } else {
      if (this.play?.is_free_content) {
        this.isFreeRegister = true;
      }
    }

    this.formRegisterInit();
    this.formSubscribeInit();
    this.formCheckoutInit();
  }

  get info() {
    return info;
  }

  get isPpv(): boolean {
    return this.play?.isPpv();
  }

  get isPaymentMethodOnline(): boolean {
    return this.formSubscribe.group.get('payment_method').value?.id === 1;
  }

  get isPaymentMethodOffline(): boolean {
    return this.formSubscribe.group.get('payment_method').value?.id === 2;
  }

  get hasSubscription(): boolean {
    return this.storeS.authUser.value?.user.hasSubscription();
  }

  get canSubscribe(): boolean {
    return this.storeS.authUser.value?.user.permissions.subscribe;
  }

  get ppvAllowDiscount(): boolean {
    if (!this.isPpv) {
      return false;
    }

    return this.hasSubscription && this.play.ppv.discount > 0 ? false : true;
  }

  get canPlayPpv(): boolean {
    const now = new Date();
    return now >= this.play.ppv.starts_at;
  }

  /* -------------------- */

  formRegisterInit(): void {
    this.formRegister = new Form(this._fb.group({
      name: [''],
      email: [''],
      password: [''],
      password_confirmation: [''],
      tyc: [true],
    }));

    this._sm.add(
      this.formRegister.group.get('name').valueChanges.subscribe(value => {
        this.formRegister.group.get('name').setValue(_.toLower(value).split(' ').map(_.capitalize).join(' '), { emitEvent: false });
      })
    );

    this._sm.add(
      this.formRegister.group.get('email').valueChanges.subscribe(value => {
        this.formRegister.group.get('email').setValue(_.toLower(value), { emitEvent: false });
      })
    );

    this.formRegister.ready = true;
  }

  formSubscribeInit(): void {
    let paymentMethodOptions: any;
    let benefitOptions: any;

    if (!this.isPpv) {
      paymentMethodOptions = [
        { id: 1, name: 'Tarjeta de crédito o débito' },
        { id: 2, name: 'Efectivo' },
      ];

      benefitOptions = [
        { id: 3, name: 'Cupón de descuento' },
        { id: 1, name: 'Tarjeta 365' },
        { id: 2, name: 'Club La Nación' },
        { id: 4, name: 'Club El País (Uruguay)' },
        { id: 5, name: 'Club El Comercio (Ecuador)' },
      ];
    } else {
      paymentMethodOptions = [
        { id: 1, name: 'Tarjeta de crédito' },
      ];

      benefitOptions = [
        { id: 3, name: 'Cupón de descuento' },
      ];
    }

    this.formSubscribe = new Form(this._fb.group({
      plan: [''],
      payment_method: [{ id: 1 }],
      benefit: [''],
      benefit_365: this._fb.group({
        card_number: ['']
      }),
      benefit_LaNacion: this._fb.group({
        card_number: [''],
        dni: ['']
      }),
      benefit_ElPais: this._fb.group({
        card_number: ['']
      }),
      benefit_ElComercio: this._fb.group({
        card_number: ['']
      }),
      benefit_Code: this._fb.group({
        code: [''],
      }),
      clarin: [false],
      nacion: [false],
      pais: [false],
      comercio: [false],
    }), {
      extras: {
        plan: {
          options: {
            items: [],
            indexBy: 'plan_id'
          }
        },
        payment_method: {
          options: {
            items: paymentMethodOptions,
          }
        },
        benefit: {
          options: {
            items: benefitOptions,
          }
        }
      }
    });

    this._sm.add(
      this.formSubscribe.group.get('payment_method').valueChanges.subscribe(value => {
        if (this.isPaymentMethodOffline && !this.formSubscribe.group.get('plan').value?.offline) {
          this.formSubscribe.group.get('plan').setValue(this.formSubscribe.options.get('plan').find(plan => plan.offline));
        }

        if (this.isPaymentMethodOnline && !this.formSubscribe.group.get('plan').value?.online) {
          this.formSubscribe.group.get('plan').setValue(this.formSubscribe.options.get('plan').find(plan => plan.online));
        }
      })
    );

    this._sm.add(
      this.formSubscribe.group.get('benefit').valueChanges.subscribe(value => {
        this.formSubscribe.group.get('benefit_365').setValue(this.formSubscribe.state.init.benefit_365);
        this.formSubscribe.group.get('benefit_LaNacion').setValue(this.formSubscribe.state.init.benefit_LaNacion);
        this.formSubscribe.group.get('benefit_ElPais').setValue(this.formSubscribe.state.init.benefit_ElPais);
        this.formSubscribe.group.get('benefit_ElComercio').setValue(this.formSubscribe.state.init.benefit_ElComercio);
        this.formSubscribe.group.get('benefit_Code').setValue(this.formSubscribe.state.init.benefit_Code);
      })
    );

    this._sm.add(
      this.formSubscribe.group.get('benefit_365').valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(value => this.getDiscountFor365(value))
    );

    this._sm.add(
      this.formSubscribe.group.get('benefit_LaNacion').valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(value => this.getDiscountForLaNacion(value))
    );

    this._sm.add(
      this.formSubscribe.group.get('benefit_ElPais').valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(value => this.getDiscountForELPais(value))
    );

    this._sm.add(
      this.formSubscribe.group.get('benefit_ElComercio').valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(value => this.getDiscountForELComercio(value))
    );

    this._sm.add(
      this.formSubscribe.group.get('benefit_Code').valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(value => this.getDiscountForCode(value))
    );

    this.isPpv
      ? this.getPlayList()
      : this.getPlanList();

    this.getDiscountFor365(this.formSubscribe.group.get('benefit_365').value);
    this.getDiscountForLaNacion(this.formSubscribe.group.get('benefit_LaNacion').value);
    this.getDiscountForELPais(this.formSubscribe.group.get('benefit_ElPais').value);
    this.getDiscountForELComercio(this.formSubscribe.group.get('benefit_ElComercio').value);
    this.getDiscountForCode(this.formSubscribe.group.get('benefit_Code').value);
  }

  formCheckoutInit(): void {
    const cardTypeOptions = this.storeS.combos.value?.creditCardTypes;

    this.formCheckout = new Form(this._fb.group({
      card_number: ['', [creditCardValidator(cardTypeOptions.map(options => options.type))]],
      card_type: [''],
      card_expiration_month: [''],
      card_expiration_year: [''],
      card_security_code: [''],
      card_titular_fullname: [''],
      card_titular_dni: [''],
      payment_method_offline: [{ id: 1 }],
    }), {
      extras: {
        card_type: {
          options: {
            items: cardTypeOptions,
          }
        },
        payment_method_offline: {
          options: {
            items: [
              { id: 1, name: 'Pago Fácil', value: 'PAGOFACIL' },
              { id: 2, name: 'Rapi Pago', value: 'RAPIPAGO' },
              { id: 3, name: 'Cobro Express', value: 'COBRO_EXPRESS' },
              // { id: 4, name: 'Ripsa', value: 'RIPSA' },
              // { id: 5, name: 'Bapro', value: 'BAPRO' },
            ],
          }
        },
        card_expiration_month: {
          options: {
            items: [
              { id: 1, name: '01', value: '01' },
              { id: 2, name: '02', value: '02' },
              { id: 3, name: '03', value: '03' },
              { id: 4, name: '04', value: '04' },
              { id: 5, name: '05', value: '05' },
              { id: 6, name: '06', value: '06' },
              { id: 7, name: '07', value: '07' },
              { id: 8, name: '08', value: '08' },
              { id: 9, name: '09', value: '09' },
              { id: 10, name: '10', value: '10' },
              { id: 11, name: '11', value: '11' },
              { id: 12, name: '12', value: '12' },
            ],
          }
        },
        card_expiration_year: {
          options: {
            items: [
              { id: 1, name: '2022', value: '2022' },
              { id: 2, name: '2023', value: '2023' },
              { id: 3, name: '2024', value: '2024' },
              { id: 4, name: '2025', value: '2025' },
              { id: 5, name: '2026', value: '2026' },
              { id: 6, name: '2027', value: '2027' },
              { id: 7, name: '2028', value: '2028' },
              { id: 8, name: '2029', value: '2029' },
              { id: 9, name: '2030', value: '2030' },
              { id: 10, name: '2031', value: '2031' },
              { id: 11, name: '2032', value: '2032' },
              { id: 12, name: '2033', value: '2033' },
              { id: 13, name: '2034', value: '2034' },
              { id: 14, name: '2035', value: '2035' },
              { id: 15, name: '2036', value: '2036' },
              { id: 16, name: '2037', value: '2037' },
              { id: 17, name: '2038', value: '2038' },
              { id: 18, name: '2039', value: '2039' },
              { id: 19, name: '2040', value: '2040' },
            ],
          }
        },
      },
    });

    this._sm.add(
      this.formCheckout.group.get('card_number').valueChanges.subscribe(value => {
        const cardType = CreditCard.getType(value, this.formCheckout.options.get('card_type').map(option => option.type));
        const option = this.formCheckout.options.get('card_type').find(option => option.type === cardType);

        this.formCheckout.group.get('card_type').setValue(option || '');

        if (!value) {
          this.formCheckout.group.get('card_expiration_month').setValue('');
          this.formCheckout.group.get('card_expiration_year').setValue('');
          this.formCheckout.group.get('card_security_code').setValue('');
          this.formCheckout.group.get('card_titular_fullname').setValue('');
        }
      })
    );

    this._sm.add(
      this.formCheckout.group.get('card_titular_fullname').valueChanges.subscribe(value => {
        this.formCheckout.group.get('card_titular_fullname').setValue(_.toLower(value).split(' ').map(_.capitalize).join(' '), { emitEvent: false });
      })
    );

    this.formCheckout.ready = true;
  }

  /* -------------------- */

  getPlanAmountWithDiscount(plan: any, discount?: number): number {
    if (!discount) {
      discount = this.discount;

      if (discount === 0 && plan.discount_trial) {
        discount = plan.discount_trial;
      }
    }

    if (discount && plan.allow_discount) {
      return parseFloat((plan.amount * (1 - (discount / 100)) as any).toFixed(2));
    }

    return parseFloat(plan.amount.toFixed(2));
  }

  /* -------------------- */

  getPlayList() {
    this.formSubscribe.ready = false;

    this.formSubscribe.options.set('plan', [
      {
        allow_discount: this.ppvAllowDiscount,
        amount: this.play.ppv.price,
        currency_symbol: '$',
        discount: this.play.ppv.discount,
        name: `Obra PPV: <strong class="fw-bolder">${this.play.title}</strong>`,
        offline: 0,
        online: 1,
        plan_id: this.play.id,
        plans_countries_id: null,
        prepagos: 0,
      }
    ]);

    if (this.formSubscribe.options.areLoaded()) {
      this.formSubscribe.ready = true;
    }

    if (!this.formSubscribe.group.get('plan').value) {
      this.formSubscribe.group.get('plan').setValue(this.formSubscribe.options.get('plan')[0]);
      this.formSubscribe.persistControl('plan', true);
    }
  }

  getPlanList() {
    if (this.planL.request.isLoading()) return;
    this.formSubscribe.ready = false;

    this._sm.add(
      this.planL.request.send(this._combosHttpS.getSubscriptionPlans()).subscribe(res => {
        this.planL.set(res.data.map((item: any) => {
          return {
            ...item,
            discount_trial: item.defaultTrial,
            allow_discount_default: item.allow_discount,
            not_allow_discount_description: 'No permite descuentos adicionales',
            not_allow_discount_description_default: 'No permite descuentos adicionales',
          }
        }));

        this.formSubscribe.options.set('plan', this.planL.data.filter((plan: any) => plan.prepagos === 0));

        if (this.formSubscribe.options.areLoaded()) {
          this.formSubscribe.ready = true;
        }

        if (!this.formSubscribe.group.get('plan').value) {
          this.formSubscribe.group.get('plan').setValue(this.formSubscribe.options.get('plan').find(plan => {
            return (this.isPaymentMethodOffline && plan.offline || this.isPaymentMethodOnline && plan.online);
          }));

          this.formSubscribe.persistControl('plan', true);
        }
      })
    );
  }

  /* -------------------- */

  resetDiscount(): void {
    this._sm.clean('getDiscountFor365');
    this._sm.clean('getDiscountForLaNacion');
    this._sm.clean('getDiscountForElPais');
    this._sm.clean('getDiscountForCode');

    if (this.discountPlan) {
      this.formSubscribe.group.get('plan').setValue(this.planSelectedPrev || this.formSubscribe.state.init.plan);
      this.planSelectedPrev = null;
    }

    this.discount = 0;
    this.discountName = null;
    this.discountDuration = null;
    this.discountDescription = null;
    this.discountPlan = null;

    this.formSubscribe.group.get('clarin').setValue(false);
    this.formSubscribe.group.get('nacion').setValue(false);
    this.formSubscribe.group.get('pais').setValue(false);
    this.formSubscribe.group.get('comercio').setValue(false);
  }

  getDiscountFor365(value: any): void {
    this.resetDiscount();

    const input = {
      card: value.card_number
    };

    if (input.card.length === 19) {
      this._sm.add(
        this.discountRequest.send(this._authUserBenefitHttpS.getDiscountFor365(input)).subscribe(res => {
          if (res.success) {
            this.discount = res.data.discount;
            this.discountName = `Tarjeta 365 - ${res.data.discount}%`;
            this.discountDescription = this._htmlStripPipe.transform(res.data.data.description);
            this.discountDuration = res.data.data.duration;
            this.formSubscribe.group.get('clarin').setValue(true);

            this.resolveAllowDiscountPlans();

            if (res.data.data.plan) {
              this.resolveDiscountPlan(res.data.data.plan);
            }
          }
        })
      , 'getDiscountFor365');
    }
  }

  getDiscountForLaNacion(value: any): void {
    this.resetDiscount();

    const input = {
      card: value.card_number,
      dni: value.dni,
    };

    if (input.card.length === 16) {
      this._sm.add(
        this.discountRequest.send(this._authUserBenefitHttpS.getDiscountForLaNacion(input)).subscribe(res => {
          if (res.success) {
            this.discount = res.data.discount;
            this.discountName = `Club La Nación - ${res.data.discount}%`;
            this.discountDescription = this._htmlStripPipe.transform(res.data.data.description);
            this.discountDuration = res.data.data.duration;
            this.formSubscribe.group.get('nacion').setValue(true);

            this.resolveAllowDiscountPlans();

            if (res.data.data.plan) {
              this.resolveDiscountPlan(res.data.data.plan);
            }
          }
        })
        , 'getDiscountForLaNacion');
      }
    }

    getDiscountForELPais(value: any): void {
      this.resetDiscount();

      const params = {
        card: value.card_number
      };

      if (params.card.length === 16) {
        this._sm.add(
          this.discountRequest.send(this._authUserBenefitHttpS.getDiscountForELPais(params)).subscribe(res => {
            if (res.success) {
              this.discount = res.data.discount;
              this.discountName = `El País - ${res.data.discount}%`;
              this.discountDescription = this._htmlStripPipe.transform(res.data.data.description);
              this.discountDuration = res.data.data.duration;
              this.formSubscribe.group.get('pais').setValue(true);

              this.resolveAllowDiscountPlans();

              if (res.data.data.plan) {
                this.resolveDiscountPlan(res.data.data.plan);
              }
            }
          })
        , 'getDiscountForELPais');
      }
    }

    getDiscountForELComercio(value: any): void {
      this.resetDiscount();

      const params = {
        document: value.card_number
      };

      if (params.document.length === 10 || params.document.length === 13) {
        this._sm.add(
          this.discountRequest.send(this._authUserBenefitHttpS.getDiscountForELComercio(params)).subscribe(res => {
            if (res.success) {
              this.discount = res.data.discount;
              this.discountName = `El Comercio - ${res.data.discount}%`;
              this.discountDescription = this._htmlStripPipe.transform(res.data.data.description);
              this.discountDuration = res.data.data.duration;
              this.formSubscribe.group.get('comercio').setValue(true);

              this.resolveAllowDiscountPlans();

              if (res.data.data.plan) {
                this.resolveDiscountPlan(res.data.data.plan);
              }
            }
          })
        , 'getDiscountForELComercio');
      }
    }

  getDiscountForCode(value: any): void {
    this.resetDiscount();
    this.resolveAllowDiscountPlans(true);

    let method: string;
    let input: any;

    if (!this.isPpv) {
      method = 'getDiscountForCode';

      input = {
        campaignCode: value.code,
      };
    }
    else {
      method = this.ppvCode ? 'getDiscountForPpvExchangeCode' : 'getDiscountForPpvCode';

      input = {
        code: value.code,
        play_id: this.play.id,
      };
    }

    if (value.code.length > 2) {
      this._sm.add(
        this.discountRequest.send<any>(this._authUserBenefitHttpS[method](input)).subscribe(res => {
          if (res.success) {
            if (!this.isPpv) {
              this.discount = res.data.discount;
              this.discountName = res.data.name;
              this.discountDescription = this._htmlStripPipe.transform(res.data.description);
              this.discountDuration = res.data.duration;

              this.resolveAllowDiscountPlans();

              if (res.data.plan) {
                this.resolveDiscountPlan(res.data.plan);
              }
            }
            else {
              this.discount = res.data.transactional ? 100 : res.data.discount;
              this.discountName = res.data.partner_name;
            }
          }
        })
      , 'getDiscountForCode');
    }
  }

  resolveAllowDiscountPlans(reset = false, plan?: any): void {
    let plans: any[];
    plan ? plans = [plan] : plans = this.planL.data;

    for (let plan of plans) {
      if (reset) {
        plan.allow_discount = plan.allow_discount_default;
        plan.not_allow_discount_description = plan.not_allow_discount_description_default;
      }
      else if (plan.name.toLowerCase().includes(' trimestral') && parseInt(this.discountDuration) < 90) {
        plan.allow_discount = 0;
        plan.not_allow_discount_description = 'Descuento no válido para este plan';
      }
    }
  }

  resolveDiscountPlan(plan: any): void {
    plan.discount = this.discount;
    this.discountPlan = plan;
    this.planSelectedPrev = this.formSubscribe.group.get('plan').value;
    this.formSubscribe.group.get('plan').setValue(this.discountPlan);
  }

}
