import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import * as _ from 'lodash';
import { AbstractFormFieldComponent } from '../../form-fields/abstract-form-field.component';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {

  @Input() formFieldC: AbstractFormFieldComponent;
  @Input() index: string | number = '';
  @Input() startsWith: boolean = true;
  @Input() usePopover: boolean = false;

  constructor() { }

  ngOnInit(): void {
    //
  }

  get customErrorIndexes(): string[] {
    if (this.formFieldC.control.errors) {
      return _.keys(this.formFieldC.control.errors).filter(validatorName => validatorName.startsWith('customs.'));
    }

    return [];
  }

}
