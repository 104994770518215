import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { NavService } from 'src/app/services/nav.service';
@Component({
  selector: 'app-live-modal',
  templateUrl: './live-modal.component.html',
  styleUrls: ['./live-modal.component.scss']
})
export class LiveModalComponent implements OnInit, OnDestroy {
  @Input() url: string;

  private _sm = new SubscriptionManager();

  constructor(
    public modal: NgbActiveModal,
    public navS: NavService,
  ) { }

  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }
}
