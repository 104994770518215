import { Component, Input, OnInit } from '@angular/core';
import { Play } from 'src/app/core/play/play.model';
import { info } from 'src/app/info';
import { NavService } from 'src/app/services/nav.service';
import { StoreService } from 'src/app/services/store.service';
import { AuthLoginModalService } from '../../core/auth/auth-login-modal/auth-login-modal.service';

@Component({
  selector: 'app-button-subscribe',
  templateUrl: './button-subscribe.component.html',
  styleUrls: ['./button-subscribe.component.scss']
})
export class ButtonSubscribeComponent implements OnInit {

  @Input() type: 'hero' | 'heroDetail' | 'modal' | 'allInOne' | 'onlyButton' | 'modalMoreInfo' | 'registerHero' | 'registerHeroSmall' | 'registerOnlyButtonNow' | 'outline' | 'onlyButtonNow' | 'promoHero' | 'promoInternationalHero' = 'hero';
  @Input() heroText: string;
  @Input() heroSubtitle: string;
  @Input() heroSubtitleColor: string;
  @Input() heroHref: string = null;
  @Input() registerHref: string = null;
  @Input() registerParams: string[] = null;
  @Input() promoPriceOff: string;
  @Input() isPromoInternational: boolean = false;
  @Input() color: string = 'red';
  @Input() play: Play;
  @Input() isInternationalPage: boolean = false;

  constructor(
    public authLoginModalS: AuthLoginModalService,
    public storeS: StoreService,
    public navS: NavService,
  ) { }

  ngOnInit(): void {
  }

  get info() {
    return info;
  }
}
