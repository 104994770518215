import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { NavService } from './services/nav.service';
import { StoreService } from './services/store.service';
import { ToastService } from './services/toast.service';
import { SubscriptionManager } from './commons/subscription-manager';
import { RouteService } from './services/route.service';
import { TaxonomyService } from './taxonomies/taxonomy.service';
import { SsrService } from './services/ssr.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _sm: SubscriptionManager = new SubscriptionManager;

  constructor(
    public toastS: ToastService,
    public ssrS: SsrService,
    private _storeS: StoreService,
    private _navS: NavService,
    private _router: Router,
    private _routeS: RouteService,
    /** TaxonomyService debe ser importado aunque no se use. */
    private _taxonomyS: TaxonomyService,
    private gtmService: GoogleTagManagerService
  ) { }

  ngOnInit(): void {
    /*
    this._router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.urlAfterRedirects
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
    */
    /**
     * Redirecciones en login y logout según corresponda.
     */
    this._sm.add(this._storeS.authUser.pipe().subscribe(authUser => {
      /**
       * En logout...
       */
      if (!authUser?.isLoggedIn()) {
        if (this._routeS.currentPage.value.authRequired === true) {
          this._router.navigate([this._navS.pages.HomePage.link()]);
        }

      /**
       * En login...
       */
      } else {
        //
      }
    }));
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }
}
