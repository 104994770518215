<div class="form-group">
    <div class="form-field filled" [ngClass]="parent.getFormFieldClass()">
        <label>
            <app-form-disabled [formFieldC]="parent"></app-form-disabled>
            <app-form-error [formFieldC]="parent"></app-form-error>
            {{ parent.label || '&nbsp;' }}
        </label>

        <div #formElem class="form-control"
            [class.is-invalid]="parent.isInvalid()"
        >
            <div class="form-control-placeholder">
                <div class="form-control-placeholder-content">
                    <ng-container *ngIf="!request.isLoading()">
                        Drop {{ parent.isMultiple() ? 'files': 'file' }}<br>
                        <small>(or click to choose)</small>
                    </ng-container>

                    <ng-container *ngIf="request.isLoading()">
                        Uploading...

                        <div class="progress">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="parent.isMultiple() && parent.isFilled() && !parent.isDisabled()" class="form-field-actions app-dropdown no-arrow" ngbDropdown placement="bottom-right">
            <a href="javascript:;" class="text-gray-500" ngbDropdownToggle>
                <i class="fas fa-ellipsis-v fa-fw"></i>
            </a>

            <div ngbDropdownMenu>
                <a href="javascript:;" ngbDropdownItem (click)="parent.unselectAll()">
                    <i class="fas fa-trash-alt fa-fw mr-2"></i>
                    <span>Delete all</span>
                </a>
            </div>
        </div>
    </div>

    <small *ngIf="parent.help" class="form-text text-muted">{{ parent.help }}</small>
</div>
