<button type="button" class="btn-close btn-close-dark" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>

<div class="grid modal-body text-center p-0">
    <div class="row g-0 h-100 p-3">
        <div class="content col-60 py-4 px-sm-5">
            <div class="img-tv">
                <img class="d-none d-lg-inline img-fluid pb-4" src="assets/img/tv-live-modal.webp" alt="Televisor" >
                <img class="d-lg-none img-fluid" src="assets/img/tv-live-modal-mobile.webp" alt="Televisor" >
            </div>

            <p class="_fs-18 fw-bolder text-gray-700 mb-0">Nuevo lanzamiento</p>

            <p class="_fs-50 fw-bolder">Tele<span class="text-app-red">Teatrix</span></p>

            <p class="d-none d-sm-block my-4">En Teatrix festejamos nuestro 8vo aniversario y los regalos son para vos. Accedé a nuestro nuevo canal y disfrutá <span class="fw-bolder text-gray-800">el mejor teatro online las 24hs gratis.</span></p>
            <p class="d-sm-none my-4">Accedé a nuestro nuevo canal y disfrutá <span class="fw-bolder text-gray-800">el mejor como nunca lo viste.</span></p>

            <p class="_fs-18 fw-bolder text-app-yellow-2 mt-4 mb-0">Transmitiendo en vivo 24/7</p>

            <a [href]="url" target="_blank">
                <button class="btn btn-app-red rounded-3 px-4 py-2 mt-2 mb-3">
                    <div class="d-flex align-items-center">
                        <strong class="text-uppercase me-2">Sintonizar canal <span class="text-app-yellow-1">gratis</span></strong>
                        <app-icon class="mb-1" name="caret-right-white" [size]="1"></app-icon>
                    </div>
                </button>
            </a>
        </div>

        <div class="subscribe col-60 bg-app-red py-4 px-sm-5 mt-auto">
            <p class="fw-bolder text-white">O suscribite y accedé al catálogo completo con más de 300 obras sin límites.</p>

            <div class="mt-auto px-3 mb-2">
                <button class="d-block btn btn-white w-100 rounded-2 py-2 _fs-16" [routerLink]="navS.pages.SubscribePage.link()">
                    <strong class="text-uppercase">Registrate</strong>
                </button>
            </div>
        </div>
    </div>
</div>
