import { creditCardType } from "./credit-card-type";
import { CreditCardTypeEnum } from "./enums/credit-card-type.enum";

export class CreditCard {

  static getType(number: string, types?: CreditCardTypeEnum[]): CreditCardTypeEnum {
    if (!types) {
      types = Object.values(CreditCardTypeEnum);
    }

    for (const type of types) {
      if (creditCardType.hasOwnProperty(`is${type}`)) {
        if (creditCardType[`is${type}`](number)) {
          return type;
        }
      }
    }

    return null;
  }
}
