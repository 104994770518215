import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-strip',
  templateUrl: './title-strip.component.html',
  styleUrls: ['./title-strip.component.scss']
})
export class TitleStripComponent implements OnInit {

  @Input() size: 'xs' | 'sm' | 'md' = 'sm';

  constructor() { }

  ngOnInit(): void {
  }

}
