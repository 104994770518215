import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HoverPreviewService {

  scale: number;
  minWidth: number;
  maxWidth: number;
  templateRef: TemplateRef<any>;
  hoverPreviewOpacity: ElementRef<any>;
  elem: BehaviorSubject<HTMLElement> = new BehaviorSubject(null);

  timeout: any;

  constructor() { }

  /* -------------------- */

  elemNext(value: any): void {
    if (this.elem.value) {
      this.elem.value.style.visibility = 'visible';
    }

    this.elem.next(value);
  }
}
