import { Component, OnInit, Optional, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AbstractFormFieldComponent } from '../abstract-form-field.component';
import { FormGroupDirective } from '@angular/forms';
import { Options as SortablejsOptions, SortableEvent } from 'sortablejs';
import { FormDropzoneUploadComponent } from './form-dropzone-upload/form-dropzone-upload.component';
import { FormComponent } from '../../form/form.component';

@Component({
  selector: 'app-form-dropzone',
  templateUrl: './form-dropzone.component.html',
})
export class FormDropzoneComponent extends AbstractFormFieldComponent implements OnInit {

  @ViewChild('upload', { static: true }) upload: FormDropzoneUploadComponent;

  @Input() concept: string;
  @Input() labels: any = {};
  @Input() max: number = 20;

  @Output() sorted$: EventEmitter<any> = new EventEmitter;

  sortableOptions: SortablejsOptions = {
    handle: '.fa-arrows-alt',
    onSort: (event: SortableEvent) => {
      this.sorted$.emit(event);
    }
  };

  constructor(
    @Optional() public formC: FormComponent,
    @Optional() protected _formGroupParent: FormGroupDirective,
  ) {
    super();

    this._formFieldClassName = 'FormDropzoneComponent';
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
