<div class="grid grid-page">
    <div header>
        <app-navbar [onlyLogo]="true" [withRegister]="true"></app-navbar>
    </div>

    <div body>
        <router-outlet></router-outlet>
    </div>

    <div footer>
        <app-footer></app-footer>
    </div>
</div>

