import { Directive, ElementRef, Input, OnInit, AfterViewInit, HostBinding } from '@angular/core';
import { SsrService } from '../services/ssr.service';
import { SupportsWebpService } from '../services/supports-webp.service';

@Directive({
  selector: '[appUseWebp-old]'
})
export class UseWebpDirective implements AfterViewInit {

  @HostBinding('attr.src') srcAttr: string = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  @Input() src: string;
  @Input() isBg: boolean = false;
  @Input() newFormat: 'png' | 'jpg' = 'jpg';

  constructor(
    private _supportsWebpS: SupportsWebpService,
    private _ssrS: SsrService,
    private _elem: ElementRef,
  ) {
    //
  }

  ngAfterViewInit() {
    if (this._ssrS.isServer()) {
      return;
    }

    if (this._supportsWebpS.supportsWebp) {
      return;
    }

    const source = this.isBg ? this._elem.nativeElement.style.backgroundImage : this.src;

    if (source.includes('.webp')) {
      const newSource = source.replace('.webp', '.' + this.newFormat);

      if (this.isBg) {
        this._elem.nativeElement.style.backgroundImage = newSource;
      } else {
        const observer = new MutationObserver(entries => {
          entries.forEach((mutation) => {
            if (mutation && this._elem.nativeElement.getAttribute('data-lazy-load') === 'true') {
              this._load();
              observer.disconnect();
            }
          });
        });

        observer.observe(this._elem.nativeElement, {
          attributeFilter: ['data-lazy-load']
        });
      }
    }
  }

  /* -------------------- */

  private _load(): void {
    setTimeout(() => this.srcAttr = this.src);
  }
}
