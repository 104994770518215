import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { info } from 'src/app/info';
import { Form } from 'src/app/packages/forms/tools/form';
import { StoreService } from 'src/app/services/store.service';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { AuthLoginModalService } from '../auth-login-modal/auth-login-modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { ApiErrorResponse } from 'src/app/services/http/core/http.service';
import { AuthUserResetPasswordHttpService } from 'src/app/services/http/auth-user-reset-password-http.service';
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-auth-reset-password-modal',
  templateUrl: './auth-reset-password-modal.component.html',
  styleUrls: ['./auth-reset-password-modal.component.scss']
})
export class AuthResetPasswordModalComponent implements OnInit, OnDestroy {

  form: Form;
  hash: string;
  email: string;

  private _sm = new SubscriptionManager();

  constructor(
    public modal: NgbActiveModal,
    public storeS: StoreService,
    public authLoginModalS: AuthLoginModalService,
    public navS: NavService,
    private _fb: UntypedFormBuilder,
    private _authUserResetPasswordHttpS: AuthUserResetPasswordHttpService,
    private _toastS: ToastService,
    private _elem: ElementRef,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this._elem.nativeElement.querySelector('input').focus();
    });

    if (!this.mustShowFormUpdatePassword) {
      this.form = new Form(this._fb.group({
        email: [''],
      }));
    } else {
      this.form = new Form(this._fb.group({
        password: [''],
        password_confirmation: [''],
      }));
    }

    this.form.ready = true;
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  get mustShowFormUpdatePassword(): boolean {
    return !!this.hash && !!this.email;
  }

  get info() {
    return info;
  }

  /* -------------------- */

  sendEmail(): void {
    if (this.form.request.isLoading()) return;

    this.form.resetMarks();

    const input = this.form.group.value;

    this._sm.add(
      this.form.request.send(this._authUserResetPasswordHttpS.sendEmail(input)).subscribe(res => {
        const err: ApiErrorResponse = this.form.request.error;

        if (err) {
          if (err.code !== 422) {
            this._toastS.showError(err.data.scalar);
          }

          if (err.status !== 422) {
            this._toastS.showHttpError(err);
          }
        } else {
          this.form.reset();
          this.modal.close();

          this._toastS.show('Success', { body: 'Se te ha enviado un email a tu dirección de correo para recuperar tu contraseña.', delay: 5000 });
        }
      }, (err: ApiErrorResponse) => {
        if (err.code !== 422) {
          this._toastS.showError(err.data.scalar);
        }

        if (err.status !== 422) {
          this._toastS.showHttpError(err);
        }
      })
    );
  }

  updatePassword(): void {
    if (this.form.request.isLoading()) return;

    this.form.resetMarks();

    const input = {
      ...this.form.group.value,
      email: this.email,
    };

    this._sm.add(
      this.form.request.send(this._authUserResetPasswordHttpS.updatePassword(this.hash, input)).subscribe(res => {
        const err: ApiErrorResponse = this.form.request.error;

        if (err) {
          if (err.status !== 422) {
            this._toastS.showHttpError(err);
          }
        } else {
          this.form.reset();
          this.authLoginModalS.open();

          this._toastS.show('Success', { body: 'Su nueva contraseña ha sido guardada exitosamente.' });
        }
      }, (err: ApiErrorResponse) => {
        if (err.status !== 422) {
          this._toastS.showHttpError(err);
        }
      })
    );
  }
}
