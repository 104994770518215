import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { info } from 'src/app/info';
import { Form } from 'src/app/packages/forms/tools/form';
import { AuthUserHttpService } from 'src/app/services/http/auth-user-http.service';
import { StoreService } from 'src/app/services/store.service';
import { SubscriptionManager } from 'src/app/commons/subscription-manager';
import { AuthResetPasswordModalService } from '../auth-reset-password-modal/auth-reset-password-modal.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ApiErrorResponse } from 'src/app/services/http/core/http.service';
import { ToastService } from 'src/app/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from 'src/app/services/nav.service';
import { PlayCategoryHttpService } from 'src/app/services/http/play-category-http.service';
import { CacheService } from 'src/app/services/cache.service';
@Component({
  selector: 'app-auth-login-modal',
  templateUrl: './auth-login-modal.component.html',
  styleUrls: ['./auth-login-modal.component.scss']
})
export class AuthLoginModalComponent implements OnInit, OnDestroy {

  form: Form;

  redirectTo?: string;

  loginType: 'member' | 'provider' = 'member';

  private _sm = new SubscriptionManager();

  constructor(
    public modal: NgbActiveModal,
    public storeS: StoreService,
    public authResetPasswordModalS: AuthResetPasswordModalService,
    public navS: NavService,
    private _fb: UntypedFormBuilder,
    private _authUserHttpS: AuthUserHttpService,
    private _analytic : AnalyticsService,
    private _toastS: ToastService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _playCategoryHttpS: PlayCategoryHttpService,
    private _cacheS: CacheService,
  ) { }

  ngOnInit(): void {
    if (this._route.snapshot.queryParamMap.get('redirectTo')) {
      this.redirectTo = this._route.snapshot.queryParamMap.get('redirectTo');
    }

    this.form = new Form(this._fb.group({
      email: [''],
      phone_1: [''],
      password: [''],
      remember_me: [true],
    }));

    this.form.ready = true;
  }

  ngOnDestroy(): void {
    this._sm.clean();
  }

  get info() {
    return info;
  }

  /* -------------------- */

  login(): void {
    if (this.form.request.isLoading()) return;

    const input = {
      password: this.form.group.get('password').value,
      remember_me: this.form.group.get('remember_me').value,
    }

    if (this.loginType === 'member') {
      input['email'] = this.form.group.get('email').value;
    }

    if (this.loginType === 'provider') {
      input['phone_1'] = '+' + this.form.group.get('phone_1').value;
    }

    this._sm.add(
      this.form.request.send(this._authUserHttpS.login(input)).subscribe(res => {
        const err: ApiErrorResponse = this.form.request.error;

        if (err) {
          if (err.status === 422) {
            this._toastS.showError(this.loginType === 'member'
            ? 'El email y/o la contraseña ingresados no son válidos.'
            : 'El teléfono y/o la contraseña ingresados no son válidos.'
            );
          }
        } else {
          this._analytic.Login(res)

          this.form.persist();
          this.modal.close();

          if (this.redirectTo) {
            this._router.navigateByUrl(this.redirectTo);
          }
        }
      }, (err: ApiErrorResponse) => {
        if (err.status === 422) {
          this._toastS.showError(this.loginType === 'member'
            ? 'El email y/o la contraseña ingresados no son válidos.'
            : 'El teléfono y/o la contraseña ingresados no son válidos.'
          );
        }
      })
    , 'login');
  }

  logout(): void {
    this.storeS.authUser.value?.logout(this.storeS, this._cacheS);

    this._playCategoryHttpS.get().toPromise();
  }

  /* -------------------- */

  changeLoginType() {
    this.form.reset();
    this.loginType = this.loginType === 'member' ? 'provider' : 'member';
  }
}
