import { Component, OnInit, Input } from '@angular/core';
import { Request } from 'src/app/commons/request';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-loading',
  templateUrl: './request-loading.component.html',
  styleUrls: ['./request-loading.component.scss']
})
export class RequestLoadingComponent implements OnInit {

  @Input() request: Request;
  @Input() requests: Request[] = [];
  @Input() message: string;
  @Input() strict: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  get e(): any {
    return environment;
  }

}
