import { AbstractControl, ValidatorFn } from "@angular/forms";

export function luhnValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const isValid = luhnValidatorFn(control.value);
    return isValid ? null : { 'customs.luhn': 'Este campo no tiene un número válido.' };
  };
}

export const luhnValidatorFn = (number: string): boolean => {
  if (!number.length) {
    return false;
  }

  // Remove all whitespaces from card number.
  number = number.replace(/\s/g, '');

  // 1. Remove last digit;
  const lastDigit = Number(number[number.length - 1]);

  // 2. Reverse number
  const numberReversed = number
    .slice(0, number.length - 1)
    .split('')
    .reverse()
    .map(x => Number(x));

  let sum = 0;

  // 3. Multiply by 2 every digit on odd position.
  for (let i = 0; i <= numberReversed.length - 1; i += 2) {
    numberReversed[i] = numberReversed[i] * 2;

    // 4. Subtract 9 if digit > 9
    if (numberReversed[i] > 9) {
      numberReversed[i] = numberReversed[i] - 9;
    }
  }

  // 5. Make the sum of obtained values from step 4.
  sum = numberReversed.reduce((previousValue, currentValue) => (previousValue + currentValue), 0);

  // 6. Calculate modulo 10 of the sum from step 5 and the last digit. If it's 0, you have a valid number.
  return ((sum + lastDigit) % 10 === 0);
};
