<div class="form-group">
    <div class="form-check custom-{{ type }}" [class.form-switch]="asSwitch" [ngClass]="getFormFieldClass()" tabindex="0" (keyup.enter)="isBoolean() ? control.setValue(!control.value) : select(value)">
        <input class="form-check-input"
            [attr.id]="id"
            [attr.type]="type"
            [attr.name]="type === 'radio' ? controlName : null"
            (change)="isBoolean() ? control.setValue(!control.value) : select(value)"
            [checked]="isBoolean() ? control.value === true : isSelected(value)"
            [disabled]="isDisabled()"
        >
        <label class="form-check-label" [attr.for]="id">
            <!-- <app-form-disabled [formFieldC]="this"></app-form-disabled> -->
            <!-- <app-form-error [formFieldC]="this"></app-form-error> -->
            <ng-content></ng-content>
            {{ label || '' }}
        </label>
    </div>

    <app-form-error [formFieldC]="this"></app-form-error>
</div>
