import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SubscriptionManager } from '../commons/subscription-manager';
import { helper } from '../helper';
import { SsrService } from './ssr.service';

@Injectable({
  providedIn: 'root'
})
export class ScrollService implements OnDestroy {

  event: Subject<any> = new Subject;

  constructor(
    private _ssrS: SsrService,
  ) {
    this.useDefaultScrollEvent();
  }

  ngOnDestroy(): void {
    if (this._ssrS.isBrowser()) {
      window.removeEventListener('scroll', null);
    }
  }

  /* -------------------- */

  useDefaultScrollEvent(): void {
    if (this._ssrS.isBrowser()) {
      window.addEventListener('scroll', (event: any) => {
        this.event.next(event);
      });
    }
  }
}
