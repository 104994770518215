import { AbstractControl, ValidatorFn } from "@angular/forms";
import { CreditCard } from "../credit-card";
import { CreditCardTypeEnum } from "../enums/credit-card-type.enum";
import { luhnValidatorFn } from "./luhn.validator";

export function creditCardValidator(types?: CreditCardTypeEnum[]): ValidatorFn {
  return (control: AbstractControl) => {
    const isValid = creditCardValidatorFn(control.value, types);
    return isValid ? null : { 'customs.creditCard': 'Este campo no tiene un número de tarjeta válido.' };
  };
}

export const creditCardValidatorFn = (number: string, types?: CreditCardTypeEnum[]): boolean => {
  if (!number.length) {
    return true;
  }

  let res = true;

  if (types) {
    res = !!CreditCard.getType(number, types);
  }

  return res && luhnValidatorFn(number);
};
