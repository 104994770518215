import { Component, Input, OnInit } from '@angular/core';
import { Request } from 'src/app/commons/request';

@Component({
  selector: 'app-request-handler',
  templateUrl: './request-handler.component.html',
  styleUrls: ['./request-handler.component.scss']
})
export class RequestHandlerComponent implements OnInit {

  @Input() request: Request;
  @Input() isUnfiredToo: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
