import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appNumberFloor'
})
export class NumberFloorPipe implements PipeTransform {

  transform(value: number|string): any {
    if (typeof value === 'string') {
      value = parseInt(value);
    }

    if (!value) {
      return '0';
    }

    value = Math.floor(value);

    return value;
  }

}
