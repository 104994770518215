import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService, TokenType } from './core/http.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationHttpService {

  constructor(
    private _httpS: HttpService,
    private _httpClient: HttpClient,
  ) { }

  /* -------------------- */

  get(params?: any) {
    const tokenType: TokenType = 'authGuestToken';
    const paramsParsed = this._httpS.parseParams(params);

    return this._httpS.send(this._httpClient.get(this._httpS.getEndpoint(`/users/geo?${paramsParsed}`), {
      headers: this._httpS.getHeaders({ token: this._httpS.getToken(tokenType) }),
    }).pipe(
      map((res: any) => {
        if (['AR', 'MX'].includes(res.data.country.iso_code)) {
          res.data.country.iso_code = 'es-' + res.data.country.iso_code;
        }

        return res;
      })
    ), tokenType);
  }
}
